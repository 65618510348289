import React from 'react';
import Modal from 'react-modal';
import { Container, HeaderModal, ModalContent, Title, Wrapper, ErrorMessage, ContainerErrors} from './styles';
import ButtonActions from '../../../Button/ButtonActions';
import { IoCloseSharp } from "react-icons/io5";
import intl from "react-intl-universal";

export default function ModalErrorImportGit({ isOpen, onRequestClose, msgErrorSync, titleErrorSync, errors= {} }) {

  const importErrors = errors;
  const syncErrors = msgErrorSync;

  function listProject(item, file_origin) {
    if (!Array.isArray(item['list'])) item['list'] = [item['list']]
    return (
      <>
        {item['list'].map((errorMsg) => <p style={{marginBottom: 10}}><span>{file_origin}</span> | {errorMsg}</p>)}
      </>
    )
  }

  function mapProjectError(error) {
    return (
      <>
        <p style={{marginBottom: 20, fontWeight: 600}}>PROJETO: {error['project']}</p>
        {error['errors'].map((item) => listProject(item, item.file_origin))}
      </>
    )
  };

  function mapRepoError(error) {
    if (localStorage.getItem("lang") === "en-US"){
      error['file_type'] = "Repository not imported. File(s) not found in repository."
    }
    return (
      <>
        <p style={{marginBottom: 20, fontWeight: 600}}>{error['file_type']}</p>
        {error.errors.map((item) => <p>{item}</p>)}
      </>
    )
  };


  const mapErrors = (error) => {
    console.log(error)
    return (
      <div>
        {
          error['project'] != null
            ?
            mapProjectError(error)
            :
            mapRepoError(error)
        }
      </div>
    )
  }


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeTimeoutMS={115}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      shouldFocusAfterRender={false}
      className="react-modal-content">
      <Container>
        <HeaderModal>
          <Title>{titleErrorSync}</Title>
          <IoCloseSharp
            style={{ cursor: 'pointer', marginRight: 7 }}
            size={18}
            onClick={onRequestClose} />
        </HeaderModal>
        <ModalContent>
          {
            importErrors.detail ?
              
                <ErrorMessage>{intl.get("import-local-directory.file-formatted-incorrectly")}</ErrorMessage>
              : ""
              
          }
          <ContainerErrors>            
          {
              importErrors.detail ?
                importErrors.detail.map((projects) => {
                  if (projects === importErrors.detail[importErrors.detail.length - 1]) {
                    return (
                      mapErrors(projects)
                    );
                  } 
                  return (
                   <>
                    {mapErrors(projects)}
                    <hr style={{marginTop: "20px", marginBottom: "20px",  border: "0", borderTop: "2px solid #eee"}}/>
                   </>
                  )
                })
              : `[${syncErrors}]`
            }
          </ContainerErrors>
          <div style={{display: "flex", justifyContent: "end", marginTop: 20}}>
            <ButtonActions
              bgColor={'#104068'}
              text={intl.get("import-local-directory.cancel")}
              onClick={onRequestClose}/>
          </div>
        </ModalContent>
      </Container>
    </Modal>
  )
}
