import styled from 'styled-components';
import loginImage from '../../assets/loginImage.svg';

export const Wrapper = styled.div`
  background-image: url(${loginImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
  @media screen and (max-width: 1300px) {
    background: none;
  }
`

export const Container = styled.div`
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0% 10% 0;
 
  @media screen and (max-width: 2560px) {
    height: 93vh;
  }

  @media screen and (max-width: 1400px) {
    height: 90vh;
  }

  @media screen and (max-width: 1300px) {
    justify-content: center;
  }
`;

export const LoginSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  width: 65vh;
  height: 75vh;
  padding: 10% 5% 15% 5%;

  background-color: #C8E6F0;
`;

export const WelcomeText = styled.span`
  display: flex;

  width: 100%;

  font-size: 2.5vh;
  font-weight: 600;
  color: #103F68;
  text-align: left;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: auto;
`;

export const InputTitle = styled.span`
  display: flex;

  width: 100%;
  margin-bottom: 1vh;

  color: #103F68;
  text-align: left;
`;

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  width: 100%;
  height: 4vh;

  border-radius: 5px;

  padding-left: 10px;
  padding-right: 10px;

  background-color: white;

  border: 1px solid #BBBBBB;
`;

export const StyledInput = styled.input`
  display: flex;

  width: 100%;

  border-radius: 5px;

  padding-right: 10px;

  outline: none;

  :focus StyledInputContainer {
    border: 1px solid #103F68;
  }
`;

export const ShowPassword = styled.img`
  display: flex;
  height: 2vh;
  border: 0px;
  background-color: white;
  margin-left: 5px;
  cursor: pointer;

  @media screen and (max-width: 1600px) {
    left: 38.5%;
  }

  @media screen and (max-width: 1024px) {
    left: 43.5%;
  }
`;

export const AccessButton = styled.input`
  width: 100%;
  height: 4vh;

  border: 0px solid transparent;
  border-radius: 5px;

  color: white;
  background-color: #5096BB;
  cursor: pointer;
`;