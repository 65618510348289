import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import {
  Container, InputContainer, Table, HeaderContainer, ContainerInput, ButtonContainer,
  Wrapper, ActionContainer, PlayIcon, DownloadIcon, Spinner
} from './styles';
import { Link } from 'react-router-dom';
import SelectRepo from '../../Input/SelectRepo';
import TextInput from './../../Input/TextInput';
import ButtonActions from './../../Button/ButtonActions';
import SearchInput from './../../Input/SearchInput';
import { api } from './../../../api';
import { AiOutlineLeftSquare, AiOutlineRightSquare } from 'react-icons/ai';
import ErrorLog from '../../Modal/ModalSync/ErrorLog';
import EmptyProjects from './../EmptyTable/Projects';
import { HiSortDescending } from 'react-icons/hi';
import intl from "react-intl-universal";
import fileDownload from 'js-file-download';
import { ToolTip } from '../../ToolTip/Icon';
import { useMediaQuery } from 'react-haiku';

export default function TableProjects() {

  const [repos, setRepos] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState('');
  const [repositories, setRepositories] = useState([]);
  const [selected, setSelected] = useState([]);
  const [projectsInput, setProjectsInput] = useState('');
  const [openErrorLog, setErrorLog] = useState(false);
  const [pending, setPending] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');

  const isEdging = useMediaQuery('(max-width: 1400px)');

  const downloadFile = async (id) => {
    const response = api.get(`/projects/${id}/download`, {
      responseType: 'blob'
    }).then(res => {
      fileDownload(res.data, 'download.zip')
    }).catch(err => {
      setErrorMsg(err.message);
      setErrorLog(true);
    })
    return response;
  };

  const searchFilter = useCallback((e) => {

    e.preventDefault();

    const result = repos.filter((item) => {
      return item.origin.toLowerCase().match(search.toLowerCase()) ||
        item.repository.toLowerCase().match(search.toLowerCase()) ||
        item.project.toLowerCase().match(search.toLowerCase()) ||
        item.description.toLowerCase().match(search.toLowerCase()) ||
        moment(item.updated_at).format('DD/MM/YYYY - HH:mm:ss').match(search.toString())
    });

    if (search != '') {
      setPending(true)
      const filtering = setTimeout(() => {
        setFiltered(result)
        setPending(false)
      }, 300)
      return () => clearTimeout(filtering)
    } else {
      setFiltered(result)
    };

    return filtered

  }, [search, repos]);

  function closeErrorModal(row) {
    setErrorLog(false);
    setSelectedItem(row);
  };

  const fetchProjects = async () => {
    try {
      const response = await api.get('/projects');
      setRepos(response.data.items)
      setFiltered(response.data.items)
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchRepositories();
  }, [])

  const fetchRepositories = async () => {
    try {
      const response = await api.get('/repository')
        .catch((err) => {
          if (err.response.status >= 500) {
            setErrorMsg(err.response.data.detail);
            setErrorLog(true);
          }
        });
      const loadedRepos = response.data.items.map((repo) => {
        return {
          'value': repo.id
          , 'label': repo.id + ' - ' + repo.name
        }
      })
      setRepositories(loadedRepos)
    } catch (error) {
      console.log(error)
    }
  };

  const fetchData = async () => {
    setPending(true)
    try {
      let params = new URLSearchParams();
      if (projectsInput.length > 0) {
        params.append('name', projectsInput);
      }
      if (selected.length > 0) {
        selected.forEach(item => {
          params.append('ids', item.value)
        })
      }
      const response = await api.get('/projects', {
        params: params
      }).catch((err) => {
        if (err.response.status === 404) {
          setRepos([])
          setFiltered([])
          setSearch('')
        } if (err.response.status >= 500) {
          setErrorMsg(err.response.data.detail);
          setErrorLog(true);
        }
      });
      setSearch('')
      setProjectsInput('');
      setTimeout(() => {
        setPending(false)
        setRepos(response.data.items)
        setFiltered(response.data.items)
      }, 300)
      setSearch('')
    } catch (error) {
      console.log(error)
    }
  };

  const resetInputField = useCallback(() => {
    setSelected([]);
    setSearch('');
    setProjectsInput('');
    fetchData()
  }, []);

  const columns = [
    {
      name: intl.get("projects.type"),
      selector: (row) => row.origin,
      sortable: true,
    },
    {
      name: intl.get("projects.repository"),
      selector: (row) => row.repository,
      sortable: true
    },
    {
      name: intl.get("projects.project"),
      selector: (row) => row.project,
      sortable: true,
      width: '30vw'
    },
    {
      name: intl.get("projects.description"),
      selector: (row) => row.description,
      sortable: true
    },
    {
      name: intl.get("projects.update-date"),
      selector: (row) => moment(row.updated_at).format('DD/MM/YYYY - HH:mm:ss'),
      sortable: true,
      id: 'updateDate'
    },
    {
      name: intl.get("projects.actions"),
      selector: (row) => (
        <ActionContainer>
          <Link to={`/projects/execute-project/${row.ID}/exec`} disabled>
            <ToolTip text={intl.get('home.roll-test')} children={
              <PlayIcon />
            } />
          </Link>
          <ToolTip text={intl.get('projects.download-zip')} children={
            <DownloadIcon
              onClick={() => downloadFile(row.ID)} />
          } />
        </ActionContainer>
      ),
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: '#eaf1f7',
        fontSize: isEdging ? 14 : 17,
      },
    },
    cells: {
      style: {
        fontSize: isEdging ? 11 : 12.5,
      },
    },
  };

  const dropdownValueRenderer = (selected, _options) => {
    return selected.length === 0 ? intl.get("projects.select-repositories") : selected.length + intl.get("projects.selected")
  };

  const paginationChanges = {
    rowsPerPageText: '',
    rangeSeparatorText: intl.get("projects.range-separator"),
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  const CustomLoader = () => (
    <div style={{ padding: '24px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Spinner />
      <div>{intl.get('loader.loading')}</div>
    </div>
  );

  return (
    <Container>
      <Table
        highlightOnHover
        columns={columns}
        customStyles={customStyles}
        noDataComponent={<EmptyProjects />}
        data={filtered}
        selectableRowsHighlight
        progressPending={pending}
        progressComponent={<CustomLoader />}
        onSort={(a, b) => a - b}
        defaultSortFieldId="historyDate"
        defaultSortAsc={false}
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10, 15, 20, 30]}
        paginationComponentOptions={paginationChanges}
        paginationIconFirstPage={<AiOutlineLeftSquare size={23} />}
        paginationIconLastPage={<AiOutlineRightSquare size={23} />}
        subHeader
        subHeaderComponent={
          <HeaderContainer>
            <Wrapper>
              <ContainerInput>
                <SelectRepo
                  title={intl.get("projects.repository")}
                  options={repositories}
                  value={selected}
                  onChange={setSelected}
                  customValueRenderer={dropdownValueRenderer}
                  overrideStrings={{ "selectAll": intl.get("projects.all") }} />
                <TextInput children={intl.get("projects.project")}
                  value={projectsInput}
                  onChange={(e) => setProjectsInput(e.target.value)}
                // onChange fetch API
                />
              </ContainerInput>
              <ButtonContainer>
                <ButtonActions
                  bgColor={'#01294d'}
                  text={intl.get("projects.submit")}
                  onClick={fetchData}
                // onClick fetch API
                />
                <ButtonActions
                  bgColor={'#ed6663'}
                  text={intl.get("projects.reset")}
                  onClick={resetInputField}
                />
              </ButtonContainer>
            </Wrapper>
            <InputContainer>
              <SearchInput
                type="text"
                placeholder={intl.get("projects.search")}
                style={{ borderRadius: 5, position: 'relative' }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onSubmit={searchFilter}
              // local filter
              />
              <></>
            </InputContainer>
          </HeaderContainer>
        }
        sortIcon={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 5,
              marginTop: 5
            }}>
            <HiSortDescending />
          </div>}
      />
      <ErrorLog
        isOpen={openErrorLog}
        onRequestClose={closeErrorModal}
        message={errorMsg}
        title={intl.get("projects.msg2")} />
    </Container>
  )
}

