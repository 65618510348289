import styled from 'styled-components';

export const Container = styled.form`
    width: 100%;
    min-width: 450px;
    max-width: 940px;
    height: 500px;
    background-color: #fff;
    
    border-radius: 2px;
`

export const Wrapper = styled.div`
    display: flex;
    justify-content: end;

    margin-top: 50px;
    gap: 20px;

`

export const HeaderModal = styled.div`

 display: flex;
 flex-direction: row;
 
 align-items: center;
 justify-content: space-between;
 padding: 15px 20px;

 box-shadow: 1px 1px 1px #00000029;

`

export const ModalContent = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 40px;    

    margin-top: 20px;
    
`

export const ErrorMessage = styled.p`
    color: red;
`

export const ContainerErrors = styled.div`
    width: 100%;
    height: 65%;
    margin-top: 20px;
    background-color: #fafafa;
    box-shadow: 1.4px 1.4px 1.4px #e2e2e2;
    overflow-x: hidden;
    padding: 20px;
    
`

export const Title = styled.p`

`