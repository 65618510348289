import styled from 'styled-components';

export const Container = styled.div`

 width: 100%;
 display: flex;
 flex-direction: column;
 background: ${(props) => {
	switch(props.status) {
		case 'PASSOU':
			return '#4fd572';
		case 'FALHA':
			return '#f26a5a';
		case 'NÃO CONCLUÍDO':
			return '#f1c073';
		case 'NÃO EXECUTADO':
			return '#b8b8b8';
		case 'NÃO SELECIONADO':
		default:
			return '#e1e1e1';
	}
 }};
 color: white;
 width: 58px;
 align-items: center;
 justify-content: center;
 padding: 3px 3px 2px; 
 border-radius: 7px;
 box-shadow: 0 1px 5px #0003;
 margin-right: 5px;
 max-height: 55px;

 @media screen and (max-width: 2500px) {
	width: 5.3vw;
	padding: .6vh 0; 
 }
`

export const Title = styled.p`
 
 font-size: 7px;
 text-align: center;
 margin: 3px 0;
 display:inline-block;
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
 max-width: 16ch;

 @media screen and (max-width: 2500px) {
	font-size: .7vw;
 }
`