import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineLeftSquare, AiOutlineRightSquare } from "react-icons/ai";
import {
  Container,
  InputContainer,
  PlayIcon,
  Spinner,
  Table,
  Title,
  TitleContainer,
  CloseIcon,
  UpdIcon,
  ActionContainer
} from './styles';
import { api } from '../../../api';
import { HiSortDescending } from "react-icons/hi";
import intl from "react-intl-universal";
import SearchInput from './../../Input/SearchInput';
import EmptyHome from './../EmptyTable/Home/index';
import ButtonActions from '../../Button/ButtonActions';
import ModalUserRegister from '../../Modal/ModalUserRegister';
import ModalConfirm from '../../Modal/ModalGit/ModalConfirm';
import { ToolTip } from '../../ToolTip/Icon';
import { useMediaQuery } from 'react-haiku';
import { useAuth } from '../../../hooks/useAuth';


export default function TableUsers() {

  const [repos, setRepos] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState('');
  const [pending, setPending] = useState(true);
  const [openUserRegisterModal, setOpenUserRegisterModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [type, setType] = useState(null);
  const { getUserData } = useAuth();

  const isEdging = useMediaQuery('(max-width: 1400px)');

  const fetchData = async () => {
    try {
      const response = await api.get(`/user`);
      setRepos(response.data.items)
      setFiltered(response.data.items)
    } catch (error) {
      console.log(error)
    }
  };

  const handleUserRegister = () => {
    setType("add");
    setSelectedItem(null)
    setOpenUserRegisterModal(true);
  }

  const closeUserRegisterModal = () => {
    setOpenUserRegisterModal(false);
    fetchData();
  };

  const closeOffGit = () => {
    setOpenDeleteModal(false);
    fetchData();
  }

  const openDelete = (id) => {
    setSelectedItem(id);
    setOpenDeleteModal(true);
  }

  const openUpdModal = (id) => {
    setType("upd");
    setSelectedItem(id);
    setOpenUserRegisterModal(true);
    getUserData(id);
  }

  const handleDelete = async () => {
    try {
      await api.delete(`/user/${selectedItem}`);
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const searchFilter = useCallback((e) => {

    e.preventDefault()

    const result = repos.filter((item) => {
      return item?.name.toLowerCase().match(search.toLowerCase()) ||
        item?.email.toLowerCase().match(search.toLowerCase()) ||
        item?.company.toLowerCase().match(search.toLowerCase()) ||
        item?.job.toLowerCase().match(search.toString()) ||
        item?.job.toLowerCase().match(search.toString())
    });

    if (search != '') {
      setPending(true)
      const filtering = setTimeout(() => {
        setFiltered(result)
        setPending(false)
      }, 300)
      return () => clearTimeout(filtering)
    } else {
      setFiltered(result)
    };

    return filtered

  }, [search, repos]);

  const columns = useMemo(() => [
    {
      name: intl.get("users.name"),
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'E-mail',
      selector: (row) => row.email,
      sortable: true
    },
    {
      name: intl.get("users.company"),
      selector: (row) => row.company,
      sortable: true
    },
    {
      name: intl.get("users.charge"),
      selector: (row) => row.job,
      sortable: true
    },
    {
      name: intl.get("users.type"),
      selector: (row) => row.type,
      sortable: true
    },
    {
      name: "Status",
      selector: (row) => row.active === true ? intl.get("users.active") : intl.get("users.inactive"),
      sortable: true
    },
    {
      name: intl.get("users.actions"),
      selector: (row) =>
        <ActionContainer>
          <ToolTip text={intl.get('users.updateuser')} children={
            <UpdIcon color={'#64c6c4'} onClick={() => openUpdModal(row.id)} />
          } />
          <ToolTip text={intl.get('users.deleteuser')} children={
            <CloseIcon size={23} color={'#3b9ccf'} onClick={() => openDelete(row.id)} />
          } />
        </ActionContainer>,
      width: "100px"
    },
  ], []);

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: '#eaf1f7',
        fontSize: isEdging ? 14 : 17,
      },
    },
    cells: {
      style: {
        fontSize: isEdging ? 11 : 12.5,
      },
    },
    subHeader: {
      style: {
        backgroundColor: 'transparent'
      }
    }
  };

  const paginationChanges = {
    rowsPerPageText: '',
    rangeSeparatorText: intl.get("users.of"),
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  const CustomLoader = () => (
    <div style={{ padding: '24px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Spinner />
      <div>{intl.get('loader.loading')}</div>
    </div>
  );

  return (
    <Container>
      <Table
        highlightOnHover={true}
        columns={columns}
        customStyles={customStyles}
        noDataComponent={<EmptyHome />}
        data={filtered}
        progressPending={pending}
        progressComponent={<CustomLoader />}
        selectableRowsHighlight
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10, 15, 20, 30]}
        paginationComponentOptions={paginationChanges}
        paginationIconFirstPage={<AiOutlineLeftSquare size={23} />}
        paginationIconLastPage={<AiOutlineRightSquare size={23} />}
        subHeader
        subHeaderComponent={
          <TitleContainer>
            <InputContainer>
              <SearchInput
                type="text"
                placeholder={intl.get('home.search')}
                style={{ borderRadius: 5, position: 'relative' }}
                value={search}
                onSubmit={searchFilter}
                onChange={(e) => setSearch(e.target.value)}
              />
              <ButtonActions
                bgColor={"#408bb4"}
                onClick={handleUserRegister}
              >
                {intl.get("users.add-user")}
              </ButtonActions>
            </InputContainer>
          </TitleContainer>
        }
        sortIcon={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 5,
              marginTop: 5
            }}>
            <HiSortDescending />
          </div>}
      />

      <ModalUserRegister
        userId={selectedItem}
        modalType={type}
        isOpen={openUserRegisterModal}
        onRequestClose={closeUserRegisterModal}
      />
      <ModalConfirm
        isOpen={openDeleteModal}
        onRequestClose={closeOffGit}
        title={intl.get("users.user")}
        subtitle={intl.get("users.do-you-want-to-delete-this-user-?")}
        onConfirmClick={handleDelete}
      />
    </Container>
  )
}