import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6.5px;
  
  width: 100%;
  max-width: 100px;
  height: 30px;
  border-radius: 12px;
  cursor: pointer;
  
  box-shadow: 1px 1px 3px #0003;
  background-color: ${(props) => props.color};
`

export const BalloonTitle = styled.p`
  color: #fff;
  font-size: 12px;
`

export const BalloonCounter = styled.p`
  color: #fff; 
  font-size: 16px;
`