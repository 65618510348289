import styled from 'styled-components';
import { IoSearch } from "react-icons/io5";

export const Container = styled.form`

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 32px;
  width: 100%;
  max-width: 245px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #408bb4;
  line-height: normal;
  padding-left: 10px;
`

export const Input = styled.input`
 
  outline: none;
  border-color: transparent;
  align-content: center;
  width: 100%;
`

export const Icon = styled(IoSearch)`

 color: #408bb4;
 font-size: 12px;
 

`

export const IconView = styled.button`
 
 background-color: #eff4f8;
 height: 28px;
 width: 32px;
 display: flex;
 align-items: center;
 justify-content: center;
 cursor: pointer;
 border: none;
`