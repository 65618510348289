import styled from 'styled-components';
import { IoSearch } from "react-icons/io5";

export const Container = styled.div`
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
  width: 100%;

  margin-top: 10px;
  
`

export const Input = styled.input`
 
  outline: none;
  border-color: transparent;
  align-content: center;
  font-size: 11.2px;
  width: 100%;
  background-color: #dddddd;
`

export const InputContainer = styled.div`


  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 37px;
  width: 100%;
  min-width: 220px;
  background-color: #dddddd;
  border: none;
  line-height: normal;
  padding-left: 10px;
  box-shadow: 0px 1.4px #e2e2e2;

  @media screen and (max-width: 1300px) {
    width: 100%;
  }

`

export const Icon = styled(IoSearch)`

 color: #8ca6b3;
 font-size: 12px;
 

`

export const IconView = styled.div`
 
 background-color: #eff4f8;
 height: 28px;
 width: 25px;
 display: flex;
 align-items: center;
 justify-content: center;
 cursor: pointer;
`

export const Title = styled.h4`


 font-size: 12px;
 color: black;

`