import React, { useEffect, useState } from 'react';
import { ContainerStatus, ContainerFile, SubTitle, InputContainer, Status, Wrapper, ButtonContainer } from './styles'
import { IconContext } from "react-icons";
import * as AiIcons from "react-icons/ai";
import { api, apiConfig } from '../../../api';
import moment from 'moment';
import Loader from '../../Loader';
import intl from "react-intl-universal"
import ButtonActions from './../../Button/ButtonActions';
import { useMediaQuery } from 'react-haiku';

let timeoutId = null;
let intervalId = null;
export default function DeviceCard({ deviceConnected, onConnectOk, onDisconnectOk, onDeviceNotFound, onErrorConnect, onData, onInputChange, reconnect }) {

    const [idDevice, setIdDevice] = useState('');
    const [wsConnection, setWsConnection] = useState(null);
    const [connected, setConnected] = useState(false);
    const [connecting, setConnecting] = useState(false);
    const [connectHour, setConnectHour] = useState(null);
    const [connectDate, setConnectDate] = useState(null);
    const [isShowLoader, setIsShowLoader] = useState(false);

    const isEdging = useMediaQuery('(max-width: 1600px)');

    const BG_COLOR_DISABLED = '#7e7e7e'
    const BG_COLOR_DISABLED_INPUT = 'rgba(0,0,0,0.075)'
    const BG_COLOR_CONNECT = '#103f68'
    const BG_COLOR_DISCONNECT = '#ed6663'

    function getConnectStatusBg() {
        if (idDevice === '')
            return BG_COLOR_DISABLED
        if (wsConnection)
            return BG_COLOR_DISABLED
        return BG_COLOR_CONNECT
    }

    function getDisconnectStatusBg() {
        if (idDevice === '')
            return BG_COLOR_DISABLED
        if (!wsConnection)
            return BG_COLOR_DISABLED
        return BG_COLOR_DISCONNECT
    }

    function getIconStatusColor() {
        if (connected)
            return "#65c6c4";
        return "#7e7e7e";
    }

    useEffect(() => {
        const date = new Date();
        setConnectHour(date.toLocaleTimeString())
        setConnectDate(date)
    }, [connected])

    useEffect(() => {
        if (reconnect && !deviceConnected) {
            intervalId = setInterval(() => {
                if(!connecting){
                    onConnectClick()
                }
            }, 5000)
        }
    }, [reconnect])

    useEffect(() => {
        if (!deviceConnected) {
            if (wsConnection) {
                setConnected(false)
                setWsConnection(null)
            } else {
                setIsShowLoader(false)
            }
        }
    }, [deviceConnected])

    function onWebSocketOpen(event) {
        console.log('Open')
        if(!reconnect){
            setIsShowLoader(true);
        }
        setConnecting(true)
        api.get('projects/connect/' + idDevice)
            .then((response) => {
                clearInterval(intervalId)
                const timeoutMs = response.data.timeout
                timeoutId = setTimeout(() => { createTimeout() }, timeoutMs)
                setConnecting(false)
            }).catch((error) => {
                setIsShowLoader(false);
                console.log(error)
                clearTimeout(timeoutId);
                onErrorConnect();
                setWsConnection(null);
                setConnecting(false)
            })
    }

    function onWebSocketMessage(event) {
        clearTimeout(timeoutId);
        onData(event)
        let jsonData = JSON.parse(event.data)
        if (jsonData['type'] === 'test') {
            onConnectOk();
            setConnected(true);
            setIsShowLoader(false)
        }
    }

    function onWebSocketClose(event) {
        console.log('Close')
        setWsConnection(null);
        setConnected(false);
        setIsShowLoader(false)
        clearTimeout(timeoutId)
    }


    function onWebSockerError(event) {
        console.log('Error')
        setConnected(false);
        setIsShowLoader(false)
        setWsConnection(null);
        clearTimeout(timeoutId)
    }

    function createTimeout() {
        console.log('Timeout!')
        if (wsConnection) {
            wsConnection.close()
        } else {
            onDeviceNotFound();
            setIsShowLoader(false)
        }
        setWsConnection(null);
    }

    function onConnectClick() {
        const apiUrl = apiConfig.apiUrl
        const apiPort = apiConfig.apiPort
        const apiWsProtocol = apiConfig.apiWsProtocol

        let baseURL = `${apiWsProtocol}//${apiUrl}:${apiPort}/api/v1`

        const socketUrl = `${baseURL}/device/${idDevice}/ws`;
        setWsConnection(new WebSocket(socketUrl));
    };

    function onDisconnectClick() {
        wsConnection.close();
        onDisconnectOk();
    }

    useEffect(() => {
        if (wsConnection) {
            wsConnection.onopen = onWebSocketOpen;
            wsConnection.onmessage = onWebSocketMessage;
            wsConnection.onclose = onWebSocketClose;
            wsConnection.onerror = onWebSockerError;
        }
    }, [wsConnection]);

    const handleMediaQuery = () => {
        if (isEdging) return 92
        else return 103
    };

    return (
        <>
            <ContainerFile>
                <Loader isShow={isShowLoader} />
                <SubTitle>{intl.get("test-execution.mqtt-devices")}</SubTitle>
                <Wrapper>
                    <InputContainer
                        value={idDevice}
                        maxLength={6}
                        onChange={(e) => {
                            setIdDevice(e.target.value)
                            onInputChange(e.target.value)
                        }}
                        style={
                            {
                                backgroundColor: getDisconnectStatusBg() === BG_COLOR_DISCONNECT ? BG_COLOR_DISABLED_INPUT : "#ffffff"
                            }
                        }
                        disabled={
                            getDisconnectStatusBg() === BG_COLOR_DISCONNECT
                        }
                    />
                    <ButtonContainer>
                        <ButtonActions
                            execButton
                            style={{ marginLeft: 10, maxWidth: handleMediaQuery(), minWidth: handleMediaQuery() }}
                            bgColor={getConnectStatusBg()}
                            text={intl.get("test-execution.to-connect")}
                            onClick={() => getConnectStatusBg() !== BG_COLOR_DISABLED ? onConnectClick() : null}
                        />
                        <ButtonActions
                            execButton
                            style={{ marginLeft: 10, maxWidth: handleMediaQuery(), minWidth: handleMediaQuery() }}
                            bgColor={getDisconnectStatusBg()}
                            text={intl.get("test-execution.disconnect")}
                            onClick={() => getDisconnectStatusBg() !== BG_COLOR_DISABLED ? onDisconnectClick() : null}
                        />
                    </ButtonContainer>
                </Wrapper>

                <ContainerStatus>
                    <IconContext.Provider
                        value={{ color: getIconStatusColor() }}>
                        <AiIcons.AiOutlineDesktop style={{ cursor: 'pointer', fontSize: '1.8vh' }} />
                    </IconContext.Provider>
                    {connected ?
                        <Status>{intl.get("test-execution.connected-to-device")} #{idDevice} | {moment(connectDate).format('DD/MM/YYYY')} | {connectHour}</Status>
                        : <Status>{intl.get("test-execution.no-device-connected")}</Status>
                    }
                </ContainerStatus>
            </ContainerFile>
        </>
    );
}