import React from 'react'
import { Container, BalloonTitle, BalloonCounter } from "./styles.js"

function Balloon(props) {
    return (
        <Container type={props.type} color={props.color}>
            <BalloonTitle>{props.title}</BalloonTitle>
            <BalloonCounter>{props.children}</BalloonCounter>
        </Container>
    )
}

export default Balloon