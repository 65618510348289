import React from 'react'
import DataTable from 'react-data-table-component'
import { AiOutlineLeftSquare, AiOutlineRightSquare } from 'react-icons/ai';
import { Container, STable, STH, STHead, STHeadTR } from './styles'
import intl from "react-intl-universal"

export default function EmptyRepos() {  

const columns = [
  {
    name: "",
    selector: "",
  },
  {
    name: "",
    selector: "",
  },
  {
    name: "",
    selector: "last_name",
  },
  {
    name: "",
    selector: "title",
    width: "300px",
  },
  {
    name: "",
    selector: "",
  },
  {
    name: "",
    selector: "",
  },
  {
    name: "",
    selector: "last_name",
  }
];

const data = [
  {
    "id" : "1",
    "title": intl.get("projects.msg1")
  },
 
];

const styles = {
  cells: {
    style: {
      paddingLeft: 55,
      marginBottom: 113,
      marginTop: 113
    },
  },
  pagination: {
		style: {
			minWidth: 1100
		},
	},
};

const paginationChanges = {
  rowsPerPageText: '',
  rangeSeparatorText: intl.get("projects.range-separator"),
};




  return (
    <Container>
         <STable>
            <STHead>
                <STHeadTR>
                <STH style={{width: 100}}>{intl.get("repository.type")}</STH>
                <STH style={{width: 325}}>{intl.get("repository.name")}</STH>
                <STH style={{width: 325}}>URL</STH>
                <STH style={{width: 325}}>Status</STH>
                <STH style={{width: 325}}>{intl.get("repository.update-date")}</STH>
                <STH>{intl.get("repository.actions")}</STH>
                <STH>{intl.get("repository.errors")}</STH>
                </STHeadTR>
            </STHead>
        </STable> 
      <DataTable 
      paginationPerPage={5}
      paginationRowsPerPageOptions={[5, 10, 15, 20, 30]}
      paginationComponentOptions={paginationChanges}
      paginationIconFirstPage={<AiOutlineLeftSquare size={23} />}
      paginationIconLastPage={<AiOutlineRightSquare size={23} />}
      customStyles={styles}
      columns={columns} 
      data={data} 
      noTableHead
      pagination 
      responsive
      />
    </Container>
  );
}
