import React from "react";
import { AiOutlineLeftSquare, AiOutlineRightSquare } from "react-icons/ai";
import { Container, STable, STH, STHead, STHeadTR, Table } from "./styles";
import intl from "react-intl-universal";

export default function EmptyHome() {
  const columns = [
    {
      name: "",
      selector: "space",
    },
    {
      name: "",
      selector: "space",
    },
    {
      name: "",
      selector: "space",
    },
    {
      name: "",
      selector: "title",
      width: "300px",
    },
    {
      name: "",
      selector: "space",
    },
    {
      name: "",
      selector: "space",
    },
    {
      name: "",
      selector: "space",
    },
  ];

  const data = [
    {
      id: "1",
      title: intl.get("projects.msg1"),
      space: "",
    },
  ];

  const styles = {
    cells: {
      style: {
        paddingLeft: 65,
        marginBottom: 113,
        marginTop: 113,
      },
    },
    pagination: {
      style: {
        width: "1100px",
      },
    },
  };

  const paginationChanges = {
    rowsPerPageText: "",
    rangeSeparatorText: intl.get("projects.range-separator"),
  };

  return (
    <Container>
      <STable>
        <STHead>
          <STHeadTR>
            <STH style={{ width: 300 }}>{intl.get("home.name")}</STH>
            <STH style={{ width: 300 }}>{intl.get("home.description")}</STH>
            <STH style={{ width: 300 }}>{intl.get("home.repository")}</STH>
            <STH style={{ width: 300 }}>{intl.get("home.last-execute")}</STH>
            <STH style={{ width: 300 }}>{intl.get("home.total-execution")}</STH>
            <STH>{intl.get("home.actions")}</STH>
          </STHeadTR>
        </STHead>
      </STable>
      <Table
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10, 15, 20, 30]}
        paginationComponentOptions={paginationChanges}
        paginationIconFirstPage={<AiOutlineLeftSquare size={23} />}
        paginationIconLastPage={<AiOutlineRightSquare size={23} />}
        customStyles={styles}
        columns={columns}
        data={data}
        noTableHead
        pagination
        responsive
      />
    </Container>
  );
}
