import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { api } from '../../../api/index'
import {
  Container,
  Title,
  Wrapper,
  WrapperContent
} from './styles'
import { HeroContainer, Print } from '../Overview/styles'
import Charts from '../../../components/Charts'
import ButtonActions from '../../../components/Button/ButtonActions'
import { useReactToPrint } from 'react-to-print'
import intl from 'react-intl-universal';

export default function Compare() {

  const { id1, id2 } = useParams();
  const [reportTitleToPDF, setReportTitleToPDF] = useState(null);
  const [leftReportData, setLeftReportData] = useState([]);
  const [rightReportData, setRightReportData] = useState([]);

  const componentRef = useRef(null);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await api.get(`/report/comparison/reports/${id1}/${id2}`);
        setLeftReportData([response.data.body.left_report]);
        setRightReportData([response.data.body.right_report])
        setReportTitleToPDF(`${response.data.body.left_report.description} - ${response.data.body.right_report.description}`);
      } catch (err) {
        console.log(`/report/comparison/reports/${id1}/${id2} ERROR::`, err.message);
      }
    }

    fetchReports();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: reportTitleToPDF
  });

  return (
    <Container>
      <Wrapper>
        <HeroContainer>
          <Title>Comparar Relatórios</Title>
          <ButtonActions
          bgColor={'#0f3c65'}
          onClick={handlePrint}
          children={intl.get('report.export_pdf')} />
        </HeroContainer>
        <Print ref={componentRef}>
          <WrapperContent>
            {leftReportData !== [] && rightReportData !== [] ?
              <Fragment>
                <Charts id={id1} reportInfo={leftReportData} type={'left'} collapsible={false} column row />
                <Charts id={id2} reportInfo={rightReportData} type={'right'} collapsible={false} column row />
              </Fragment> : <Fragment />
            }
          </WrapperContent>
        </Print>
      </Wrapper>
    </Container>
  );
}
