import React from 'react'
import Modal from 'react-modal';
import { Container, HeaderModal, InfoContainer, NextOrder, Title, Wrapper } from './styles'
import ButtonActions from '../../../Button/ButtonActions';
import { IoCloseSharp, IoCheckmarkCircleOutline } from 'react-icons/io5';
import intl from "react-intl-universal"

export default function ModalAlert({isOpen, onClick, onRequestClose, message, title}) {

  return ( 
    <Modal
    isOpen={isOpen}
    onClick={onClick}
    onRequestClose={onRequestClose}
    shouldFocusAfterRender={false}
    overlayClassName="react-modal-overlay"
    className="react-modal-content">
    <Container>
      <HeaderModal>
        <Title>{intl.get("sync-projects.title")}</Title>
        <IoCloseSharp
          style={{ cursor: 'pointer', marginRight: 7 }}
          size={18}
          onClick={onRequestClose} />
      </HeaderModal>
      <NextOrder style={{textAlign: 'center'}}>
    <IoCheckmarkCircleOutline 
      style={{ marginRight: 7, marginBottom: 10 }} 
      size={40} 
      color={'#73e995'}/>
    <Title style={{fontWeight: 600}}>{intl.get("sync-projects.msg1")}</Title>
    <InfoContainer>
      {intl.get("sync-projects.msg2")}
    </InfoContainer>
    </NextOrder>
    <Wrapper>
      <></>
      <ButtonActions
      bgColor={'#104068'}
      text={'Ok'} 
      onClick={onRequestClose}/>
    </Wrapper>
    </Container>
    </Modal>
  )
}

