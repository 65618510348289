import styled, { keyframes } from 'styled-components';
import DataTable from 'react-data-table-component';
import { IoPlayCircleOutline } from 'react-icons/io5';
import { HiDownload } from 'react-icons/hi';

export const Container = styled.div`

 margin-bottom: 50px;
 padding: 12px;
 background-color: white;
  
`
export const Table = styled(DataTable)`

 

`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
	margin: 16px;
	animation: ${rotate360} 1s linear infinite;
	transform: translateZ(0);
	border-top: 2px solid grey;
	border-right: 2px solid grey;
	border-bottom: 2px solid grey;
	border-left: 4px solid black;
	background: transparent;
	width: 80px;
	height: 80px;
	border-radius: 50%;
`;

export const Wrapper = styled.div`

 display: flex;
 flex-direction: row;
 align-items: end;
 justify-content: space-between;
  
`

export const ButtonContainer = styled.div`

  display: flex;
  width: 100%;
  font-size: 10px;
  margin-top: 3vh;
  margin-left: 40px;
  gap: 20px;

  @media (max-width: 1600px) {
   margin-top: 5vh;
  }
`

export const ActionContainer = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 68px;
`

export const HeaderContainer = styled.div`

  display: flex;
  flex-direction: column;
  width: 100%;  
  position: relative;
  right: 20px;
`

export const ContainerInput = styled.div`

  display: flex;
  width: 70%;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 800px) {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    gap: 20px;
  }

`


export const Input = styled.input`

  height: 35px;
  width: 100%;
  max-width: 220px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #408bb3;
  padding: 0 8px;
  line-height: normal;
  font-size: 12px;

`


export const Title = styled.h2`


 color: black;
 font-size: 28px;
 letter-spacing: .5px;
 margin-bottom: 10px;
 display: flex;
 position: relative;
 right: 23px;

 
`

export const TitleContainer = styled.div`

 width: 100%;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 margin: 20px 0px;
 align-items: center;
`


export const InputContainer = styled.div`

 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 margin: 25px 0 18px;

 @media (max-width: 800px) {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 14px 0;
 }

`

export const PlayIcon =  styled(IoPlayCircleOutline)`

 color:#3b9ccf;
 font-size: 26px;
 cursor: pointer;

 &:hover { 
 background-color: white;
 padding: 1px;
 transition: 0.4s ease-in-out; 
 border-radius: 5px;
 box-shadow: 0 1px 2.8px #0003;
}

        
`

export const DownloadIcon =  styled(HiDownload)`

 color:#64c6c4;
 font-size: 25px;
 cursor: pointer;

 &:hover { 
 background-color: white;
 padding: 1.5px;
 transition: 0.4s ease-in-out; 
 border-radius: 5px;
 box-shadow: 0 1px 2.8px #0003;
}

        
`