import styled from 'styled-components';

export const Container = styled.div``

export const Tooltip = styled.div` 
  color: #103f68;
  text-align: center;
  line-height: 44px;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 5px;
`;
export const TooltipBox = styled.div`
  position: absolute;
  bottom:10px;
  right: 5px;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  width: 150px;
  padding: 5px 5px;
  border-radius: 4px;
  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s ease-in-out;
 
`;
export const TooltipCard = styled.div`
  position: relative;
  & ${Tooltip}:hover + ${TooltipBox} {
    visibility: visible;
    z-index: 999;
    color: #e5e5e5;
    background-color: #111;
    width: 300px;
    height: auto;
    font-weight: 500;
    padding: 8px 6px;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: justify;
    position: absolute;
    right: 70px;
    bottom: -16px;
    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }
`;