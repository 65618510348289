import React, {
  useEffect,
  useState,
  useCallback,
  Fragment,
  useMemo,
  useRef,
} from "react";
import moment from "moment";
import {
  Container,
  InputContainer,
  HeaderContainer,
  ContainerInput,
  ButtonContainer,
  Wrapper,
  ActionContainer,
  CloseIcon,
  ChartIcon,
  DSIcon,
  PDFIcon,
  Spinner,
  Title,
  Period,
  GeneralStateLabel,
  GeneralStatePopover,
} from "./styles";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { api } from "./../../../api";
import { AiOutlineLeftSquare, AiOutlineRightSquare } from "react-icons/ai";
import { HiSortDescending } from "react-icons/hi";
import SelectRepo from "../../Input/SelectRepo";
import ButtonActions from "./../../Button/ButtonActions";
import SearchInput from "./../../Input/SearchInput";
import ErrorLog from "../../Modal/ModalSync/ErrorLog";
import EmptyProjects from "./../EmptyTable/Projects";
import intl from "react-intl-universal";
import ModalOffReport from "../../Modal/ModalReport/ModalOffReport";
import ModalUpdDSReport from "../../Modal/ModalDataSheet/ModalUpdDSReport";
import { Print } from "../../../pages/Report/Overview/styles";
import { ToolTip } from "../../ToolTip/Icon";
import { useMediaQuery } from "react-haiku";
import ReactDatePicker, { getDefaultLocale } from "react-datepicker";
import { ArrowContainer, Popover } from "react-tiny-popover";
import { useReactToPrint } from "react-to-print";
import Loader from "../../Loader";
import Charts from "../../Charts";

export default function TableReport() {
  const [repos, setRepos] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState("");
  const [repositories, setRepositories] = useState([]);
  const [selectedRepositories, setSelectedRepositories] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [checked, setChecked] = useState(false);
  const [openErrorLog, setErrorLog] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [projects, setProjects] = useState([]);
  const [startDate, setStartDate] = useState(getDefaultDate(1));
  const [endDate, setEndDate] = useState(getDefaultDate(0));
  const [openModalOffReport, setOpenModalOffReport] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openModalDSReport, setOpenModalDSReport] = useState(false);
  const [idDs, setIdDs] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [pending, setPending] = useState(true);
  const [pdfDate, setPDFDate] = useState("");
  const [pdfTitle, setPDFTitle] = useState("");
  const [idPrint, setIdPrint] = useState(null);
  const [selectedReportInfo, setSelectedReportInfo] = useState(null);
  const [selectedReportFields, setSelectedReportFields] = useState(null);
  const [isShowLoader, setIsShowLoader] = useState(false);

  const isEdging = useMediaQuery("(max-width: 1280px)");
  const today = getDefaultDate();

  const fetchData = async () => {
    setPending(true);
    try {
      let params = new URLSearchParams();
      if (selectedRepositories.length > 0) {
        selectedRepositories.forEach((item) => {
          params.append("repos", item.value);
        });
      }
      if (selectedProjects.length > 0) {
        selectedProjects.forEach((item) => {
          params.append("projects", item.value);
        });
      }

      if (startDate)
        params.append(
          "date_start",
          moment(new Date(startDate)).format("DD/MM/YYYY 00:00:00")
        );

      if (endDate)
        params.append(
          "date_end",
          moment(new Date(endDate)).format("DD/MM/YYYY 23:59:59")
        );

      const response = await api
        .get("/report", {
          params: params,
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setRepos([]);
            setFiltered([]);
            setSearch("");
          }
          if (err.response.status >= 500) {
            setErrorMsg(err.response.data.detail);
            setErrorLog(true);
          }
        });
      setSearch("");
      setTimeout(() => {
        setPending(false);
        setRepos(response.data);
        setFiltered(response.data);
        setPDFDate(
          response.data.map((date) =>
            moment(date.executed_date).format("DD/MM/YYYY - HH:mm")
          )
        );
      }, 300);
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();

  const handleCompare = async () => {
    try {
      await api
        .post(`/report/comparison/${selectedIds[0]}/${selectedIds[1]}`)
        .then((response) => {
          if (response.data.body.isValid === true) {
            navigate(`/reports/comparing/${selectedIds[0]}/${selectedIds[1]}`);
          }
          if (response.data.body.isValid === false) {
            setErrorLog(true);
            setErrorMsg("Não é possível comparar os relatórios");
          }
        });
    } catch (error) {
      if (error.response.status >= 300) {
        console.log(error.response.data.detail);
      }
    }
  };

  const componentRef = useRef();

  const closePrintView = () => {
    setIsShowLoader(false);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: pdfTitle,
    onAfterPrint: closePrintView,
  });

  const printPDF = (id) => {
    const fetchReportInfo = async () => {
      try {
        const result = await api.get(`/report/${id}/info`);
        setSelectedReportInfo([result.data]);
      } catch (err) {
        console.log(`/report/${id}/info ERROR::`, err.message);
      }
    };

    const fetchReportFields = async () => {
      try {
        const result = await api.get(`/report/${id}/fields`);
        setSelectedReportFields(result.data);
      } catch (err) {
        console.log(`/report/${id}/fields ERROR::`, err.message);
      }
    };

    setIsShowLoader(true);
    fetchReportInfo();
    fetchReportFields();
    setIdPrint(id);

    setTimeout(() => {
      handlePrint();
      setIsShowLoader(false);
      setIdPrint(null);
    }, 3000);
  };

  useEffect(() => {
    var ids = [];
    if (selectedRows.length) {
      for (var i = 0; i < selectedRows.length; i++) {
        ids.push(selectedRows[i].id);
      }
    }
    setSelectedIds(ids);
  }, [selectedRows]);

  const searchFilter = useCallback(
    (e) => {
      e.preventDefault();
      const result = repos.filter((item) => {
        item.duration = item.duration == null ? "" : item.duration;
        item.software_release =
          item.software_release == null ? "" : item.software_release;
        return (
          item.duration.toLowerCase().match(search.toLowerCase()) ||
          item.executed_date.toLowerCase().match(search.toLowerCase()) ||
          item.projects.toLowerCase().match(search.toLowerCase()) ||
          item.success.toString().toLowerCase().match(search.toLowerCase()) ||
          item.fail.toString().toLowerCase().match(search.toLowerCase()) ||
          item.not_executed
            .toString()
            .toLowerCase()
            .match(search.toLowerCase()) ||
          item.not_conclusive
            .toString()
            .toLowerCase()
            .match(search.toLowerCase()) ||
          item.repository.toLowerCase().match(search.toLowerCase()) ||
          item.software_release.toLowerCase().match(search.toLowerCase()) ||
          item.type.toLowerCase().match(search.toLowerCase()) ||
          item.user.toLowerCase().match(search.toLowerCase())
        );
      });

      if (search != "") {
        setPending(true);
        const filtering = setTimeout(() => {
          setPending(false);
          setFiltered(result);
        }, 300);
        return () => clearTimeout(filtering);
      } else {
        setFiltered(result);
      }

      return filtered;
    },
    [search, repos]
  );

  function getDefaultDate(dayPlus = 0) {
    return moment().subtract(dayPlus, "days").toDate();
  }

  function closeUpdDS(flag) {
    if (flag) {
      setOpenModalDSReport(false);
    } else {
      setOpenModalDSReport(false);
      fetchProjects();
    }
  }

  function closeOffReport(e) {
    e.preventDefault();
    setOpenModalOffReport(false);
  }

  function openOffReport(row) {
    setOpenModalOffReport(true);
    setSelectedItem(row);
  }

  function closeErrorModal(e, row) {
    e.preventDefault();
    setErrorLog(false);
    setSelectedItem(row);
  }

  const dispatchCloseOffReport = () => {
    setSelectedRows([]);
    fetchData();
  }

  useEffect(() => {
    fetchProjects();
    fetchRepositories();
    fetchData();
  }, []);

  const displayOptionsProjects = (item) => {
    return {
      value: item.ID,
      label: item.project,
    };
  };

  const displayOptionsRepos = (item) => {
    return {
      value: item.id,
      label: item.name,
    };
  };

  const fetchProjects = async () => {
    try {
      const response = await api.get("/projects");
      setProjects(response.data.items.map(displayOptionsProjects));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRepositories = async () => {
    try {
      const response = await api.get("/repository");
      setRepositories(response.data.items.map(displayOptionsRepos));
    } catch (error) {
      console.log(error);
    }
  };

  const resetInputField = () => {
    setStartDate(getDefaultDate(1));
    setEndDate(getDefaultDate());
    setSelectedRepositories([]);
    setSelectedProjects([]);
    setSearch("");
    fetchData();
  };

  const handleCheckAll = useCallback(() => {
    setSelectAll((prevState) => !prevState);
    setSelectedRows(
      filtered.map((item) => ({
        ...item,
        selectedRows,
      }))
    );
    if (selectAll) {
      setSelectedRows([]);
    }
  }, [selectAll, selectedRows, filtered]);

  const columns = [
    {
      name: (
        <div
          style={{
            marginLeft: 10,
            gap: 12,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <input
            type={"checkbox"}
            checked={selectedRows.length === filtered.length}
            onChange={handleCheckAll}
          />
          {selectedRows.length === 0 ? null : (
            <span
              children={
                selectedRows.length < 10
                  ? `0${selectedRows.length}`
                  : selectedRows.length
              }
            />
          )}
        </div>
      ),
      selector: (row) => (
        <input
          style={{ marginLeft: "8px" }}
          type="checkbox"
          checked={selectedRows.find((sRow) => sRow.id === row.id) || false}
          onChange={() => handleRowClicked(row)}
        />
      ),
      width: "60px",
      compact: true,
    },
    {
      name: intl.get("report.execution_date"),
      selector: (row) =>
        moment(row.executed_date).format("DD/MM/YYYY HH:mm:ss"),
      sortable: true,
      width: "180px",
    },
    {
      name: intl.get("report.execution_time"),
      selector: (row) => row.duration,
      sortable: true,
      width: "110px",
    },
    {
      name: intl.get("report.repository"),
      selector: (row) => row.repository,
      sortable: true,
      width: "220px",
    },
    {
      name: intl.get("report.projects"),
      selector: (row) => row.projects,
      sortable: true,
      width: "220px",
    },
    {
      name: intl.get("report.geral_status"),
      selector: (row) => row.status,
      cell: (row) => <PopOverStatus {...row} />,
      sortable: true,
      width: "160px",
    },
    {
      name: "Software-Release",
      selector: (row) => {
        return row.software_release;
      },
      width: "170px",
    },
    {
      name: intl.get("report.user"),
      selector: (row) => row.user,
      sortable: true,
      width: "160px",
    },
    {
      name: intl.get("report.type"),
      selector: (row) =>
        row.type === "Execução"
          ? intl.get("report.execution")
          : row.type === "Reexecução"
          ? intl.get("report.reexecution")
          : row.type,
      sortable: true,
      width: "160px",
    },
    {
      name: intl.get("projects.actions"),
      selector: (row) => (
        <ActionContainer>
          <Link to={`/reports/data/${row.id}`}>
            <ToolTip
              text={intl.get("report.report-page")}
              children={<ChartIcon />}
            />
          </Link>
          {row["created_user"] ? (
            <ToolTip
              text={intl.get("report.title-updDS")}
              children={
                <DSIcon
                  onClick={() => {
                    setIdDs(row.id);
                    setOpenModalDSReport(true);
                  }}
                />
              }
            />
          ) : (
            <></>
          )}
          <ToolTip text={intl.get("report.export_pdf")}>
            <PDFIcon
              onClick={() => (printPDF(row.id), setPDFTitle(row.projects))}
            />
          </ToolTip>
        </ActionContainer>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#eaf1f7",
        fontSize: isEdging ? 14 : 16,
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        fontSize: isEdging ? 12 : 14,
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  const dropdownValueRenderer = (selected, _options) => {
    return selected.length === 0
      ? intl.get("projects.select-repositories")
      : selected.length + intl.get("projects.selected");
  };

  const paginationChanges = {
    rowsPerPageText: "",
    rangeSeparatorText: intl.get("projects.range-separator"),
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  const CustomLoader = () => (
    <div
      style={{
        padding: "24px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Spinner />
      <div>{intl.get("loader.reports")}</div>
    </div>
  );

  const handleRowClicked = (row) => {
    const find = selectedRows.find((sRow) => sRow.id === row.id);
    if (find) {
      setSelectedRows(selectedRows.filter((sRow) => sRow.id !== find.id));
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };

  useEffect(() => {
    selectedRows.length === 2 ? setChecked(true) : setChecked(false);
  }, [selectedRows]);

  const conditionalRowStyles = [
    {
      when: () => true,
      style: (row) => ({
        backgroundColor:
          selectedRows.find((sRow) => sRow.id === row.id) && "#e3f2fd",
      }),
    },
  ];

  const handleDatePickerChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useMemo(() => {
    setSelectedRows([]);
    setSelectAll(false);
  }, [filtered]);

  return (
    <Container>
      <DataTable
        highlightOnHover
        columns={columns}
        customStyles={customStyles}
        noDataComponent={<EmptyProjects />}
        data={filtered}
        onRowClicked={handleRowClicked}
        conditionalRowStyles={conditionalRowStyles}
        progressPending={pending}
        progressComponent={<CustomLoader />}
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10, 15, 20, 30]}
        paginationComponentOptions={paginationChanges}
        paginationIconFirstPage={<AiOutlineLeftSquare size={23} />}
        paginationIconLastPage={<AiOutlineRightSquare size={23} />}
        subHeader
        striped={true}
        subHeaderComponent={
          <HeaderContainer>
            <Wrapper>
              <ContainerInput>
                <Period>
                  <Title children={intl.get("report.period")} />
                  <ReactDatePicker
                    className="custom-datepicker"
                    dateFormat="P"
                    locale={getDefaultLocale()}
                    selectsRange
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={today}
                    onChange={handleDatePickerChange}
                  />
                </Period>
                <SelectRepo
                  title={intl.get("report.repositories")}
                  options={repositories}
                  value={selectedRepositories}
                  onChange={setSelectedRepositories}
                  customValueRenderer={dropdownValueRenderer}
                  overrideStrings={{ selectAll: intl.get("report.all") }}
                />
                <SelectRepo
                  title={intl.get("report.projects")}
                  options={projects}
                  value={selectedProjects}
                  onChange={setSelectedProjects}
                  customValueRenderer={dropdownValueRenderer}
                  overrideStrings={{ selectAll: intl.get("report.all") }}
                />
                <ButtonContainer>
                  <ButtonActions
                    bgColor={"#01294d"}
                    text={intl.get("projects.submit")}
                    onClick={fetchData}
                    style={{ width: 120 }}
                  />
                  <ButtonActions
                    bgColor={"#ed6663"}
                    text={intl.get("projects.reset")}
                    onClick={resetInputField}
                    style={{ width: 120 }}
                  />
                </ButtonContainer>
              </ContainerInput>
            </Wrapper>
            <InputContainer>
              <SearchInput
                type="text"
                placeholder={intl.get("report.search")}
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
                onSubmit={searchFilter}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                // local filter
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "24.5%",
                }}
              >
                <ButtonActions
                  disabled={selectedRows.length > 0 ? false : true}
                  bgColor={selectedRows.length > 0 ? "#d89023" : "#676a6d"}
                  text={intl.get("report.delete-report")}
                  onClick={() =>
                    openOffReport(selectedRows.find((sRow) => sRow.id))
                  }
                />
                <ButtonActions
                  text={intl.get("report.compare")}
                  bgColor={checked ? "#408bb4" : "#676a6d"}
                  onClick={() => handleCompare()}
                  disabled={checked ? false : true}
                />
              </div>
            </InputContainer>
          </HeaderContainer>
        }
        sortIcon={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: 5,
              marginTop: 5,
            }}
          >
            <HiSortDescending />
          </div>
        }
      />
      <ErrorLog
        isOpen={openErrorLog}
        onRequestClose={closeErrorModal}
        message={errorMsg}
      />

      <ModalOffReport
        isOpen={openModalOffReport}
        onRequestClose={closeOffReport}
        id={selectedItem ? selectedItem.id : null}
        selectedRows={selectedRows}
        action={dispatchCloseOffReport}
      />

      <ModalUpdDSReport
        isOpen={openModalDSReport}
        onRequestClose={closeUpdDS}
        closeModal={() => setOpenModalAddGit(false)}
        idDs={idDs}
      />
      <div style={{ display: "none" }}>
        <Print id="print" ref={componentRef}>
          {idPrint ? (
            <Charts
              id={idPrint}
              reportInfo={selectedReportInfo}
              reportFields={selectedReportFields}
              title={pdfTitle}
            />
          ) : (
            <Fragment />
          )}
        </Print>
      </div>
      <Loader isShow={isShowLoader} />
    </Container>
  );
}

const PopOverStatus = ({
  status,
  success,
  fail,
  not_conclusive,
  not_executed,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const translateStatus = () => {
    switch (status) {
      case "PASSOU":
        return intl.get("report.success");
      case "FALHA":
        return intl.get("report.fail");
      case "NÃO EXECUTADO":
        return intl.get("report.not_executed");
      case "NÃO CONCLUÍDO":
        return intl.get("report.not_conclusive");
      default:
        return "";
    }
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={["right"]}
      onClickOutside={() => setIsPopoverOpen(false)}
      padding={6}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowSize={10}
          arrowStyle={{
            left: 1,
            borderRight: "10px solid rgba(0,0,0,.2)",
            zIndex: 1,
          }}
        >
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowSize={10}
            style={{
              paddingLeft: 0,
              paddingTop: 0,
              paddingBottom: 0,
              paddingRight: 0,
            }}
            arrowStyle={{
              left: 2,
              borderRight: "10px solid #fff",
              zIndex: 2,
            }}
          >
            <GeneralStatePopover>
              <ul>
                <li>
                  <span style={{ color: "#16b444" }}>
                    {intl.get("report.success")}
                  </span>{" "}
                  {success}
                </li>
                <li>
                  <span style={{ color: "#f30500" }}>
                    {intl.get("report.fail")}
                  </span>
                  {fail}
                </li>
                <li>
                  <span style={{ color: "#979797" }}>
                    {intl.get("report.not_executed")}
                  </span>{" "}
                  {not_executed}
                </li>
                <li>
                  <span style={{ color: "#dfa24c" }}>
                    {intl.get("report.not_conclusive")}
                  </span>
                  {not_conclusive}
                </li>
              </ul>
            </GeneralStatePopover>
          </ArrowContainer>
        </ArrowContainer>
      )}
    >
      <GeneralStateLabel
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        status={status}
      >
        {translateStatus("status")}
      </GeneralStateLabel>
    </Popover>
  );
};
