import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  width: 100%;
  height: 100vh;

  background-color: white;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 65vh;
  height: 75vh;

  padding: 10% 5% 15% 5%;

  background-color: #C8E6F0;
`;

export const Title = styled.span`
  display: flex;

  width: 100%;

  font-size: 2.5vh;
  font-weight: 600;
  color: #103F68;
  text-align: left;
`;

export const EmailMessage = styled.span`
  display: flex;

  width: 100%;

  font-size: 1.3vh;
  font-weight: 500;
  text-align: left;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  gap: 5px;
`;

export const StyledInput = styled.input`
  display: flex;

  width: 100%;
  height: 4vh;

  border-radius: 5px;

  padding-left: 10px;
  padding-right: 10px;

  outline: solid;
  outline-color: #BBBBBB;
  outline-width: 1px;

  &:focus {
    outline-color: #103F68
  }
`;

export const InputTitle = styled.span`
  display: flex;

  width: 100%;
  margin-bottom: 1vh;

  font-size: 1.3vh;
  color: #103F68;
  text-align: left;
`;

export const AccessButton = styled.input`
  width: 100%;
  height: 4vh;

  border: 0px solid transparent;
  border-radius: 5px;

  color: white;
  background-color: #5096BB;
  cursor: pointer;
`;