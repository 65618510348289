import React from 'react'
import { Container } from './styles'
import intl from 'react-intl-universal';

export default function NoParams() {
    return (
        <Container>
            {intl.get("params.no_params")}
        </Container>
    )
}
