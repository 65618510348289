import React, { useContext, useState, useEffect } from 'react'
import Modal from 'react-modal';
import { Container, HeaderModal, Title, Wrapper, ModalContent } from './styles';
import TextInput from '../../../Input/TextInput';
import ButtonActions from '../../../Button/ButtonActions';
import { IoCloseSharp } from "react-icons/io5";
import { api } from '../../../../api';
import SuccessModal from '../Success';
import ErrorLog from '../../ModalSync/ErrorLog';
import intl from "react-intl-universal"

export default function ModalUpdGit({ isOpen, onRequestClose, onClick, id, item }) {

  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openDouble, setOpenDouble] = useState(false);
  const [openRuleModal, setOpenRuleModal] = useState(false);

  useEffect(() => {
    if (item) {
      setName(item.name);
      setUrl(item.url);
    }
  }, [item])

  function openSuccessModal() {
    setOpenSuccess(true);
  };

  function closeSuccessModal() {
    setOpenSuccess(false);
  };

  const handleSuccess = () => {
    openSuccessModal();
  };

  function openDoubleModal() {
    setOpenDouble(true);
  };

  function closeDoubleModal() {
    setOpenDouble(false);
  };

  function handleWarn() {
    openDoubleModal()
  };

  function openModalRule() {
    setOpenRuleModal(true);
  };

  function closeModalRule() {
    setOpenRuleModal(false);
  };

  const handleUpdGit = async (e) => {
    e.preventDefault();

    const editRepo = async (data) => {
      await api.put(`/repository/${item.id}`,
        { name: name, url: url })
    }

    try {
      if (!name || !url) {
        openModalRule();
      }
      else {
        editRepo();
        handleSuccess();
      }
    }
    catch (error) {
      handleWarn();
    }

  };

  return (
    <Modal
      isOpen={isOpen}
      onClick={onClick}
      onRequestClose={onRequestClose}
      shouldFocusAfterRender={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content">
      <Container>
        <HeaderModal>
          <Title>{intl.get("repository.git-edit")}</Title>
          <IoCloseSharp
            style={{ cursor: 'pointer', marginRight: 7 }}
            size={18}
            onClick={onRequestClose} />
        </HeaderModal>
        <ModalContent>
          <TextInput
            value={name}
            defaultValue={item ? item.name : ''}
            onChange={(e) => {
              setName(e.target.value);
            }
            }
          >
            <Title>{intl.get("repository.name")}</Title>
          </TextInput>
          <TextInput
            value={url}
            defaultValue={item ? item.url : ''}
            onChange={(e) => setUrl(e.target.value)}
          >
            <Title>URL</Title>
          </TextInput>
          <Wrapper>
            <ButtonActions
              bgColor={'#104068'}
              text={intl.get("repository.save")}
              onClick={handleUpdGit} />
            <ButtonActions
              bgColor={'#ed6663'}
              text={intl.get("repository.cancel")}
              onClick={onRequestClose} />
          </Wrapper>
        </ModalContent>
      </Container>
      <SuccessModal
        isOpen={openSuccess}
        onRequestClose={closeSuccessModal}
        title={intl.get("projects.repository")}
        message={intl.get("repository.msg-sucess")} />
      <ErrorLog
        isOpen={openRuleModal}
        onRequestClose={closeModalRule}
        title={intl.get("repository.operation-not-performed")}
        message={'repository.msg-empty-fields'}
      />
    </Modal>
  )
}
