import styled from 'styled-components';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { SiJirasoftware } from "react-icons/si";

export const ContainerTitle = styled.div`

 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: space-around;
`

export const TableContainer = styled.div`

 display: flex;
 flex-direction: column;
 margin: 0px 0 25px;
 width: 100%;

 p {
  font-size: 10px;
  margin: 10px 0;
 }
`

export const HeaderContainer = styled.div`

 display: flex;
 flex-direction: row;
 justify-content: space-between;
 padding: 0 4px;
 margin: 8.8px 0;
`

export const Title = styled.div`

 color: ${(props) => props.color};
 font-weight: 500;
 font-size: 10px;

 @media screen and (max-width: 2500px) {
  font-size: 1.8vh;
 }
`

export const Badge = styled.div`

 width: 90px;
 display: flex;
 justify-content: center;
 align-items: center;
 border-radius: 7px;
 color: white;
 margin: 0 5px;
 font-size: 13px;
 cursor: pointer;
`

export const FolderContainer = styled.div`
   
  display: ${props => props.display};
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 1px 1px 5px #0003;
  background-color: #fbfbfb;
  border-radius: 5px;
  width: 100%;
  visibility: ${props => props.visibility};
  
`

export const Wrapper = styled.div`

  width: 100%;
  border-radius: 5px;
  min-height: 100px;
  box-shadow: 0.5px 0px 4px ${(props) => props.bgColor};
  background-repeat: no-repeat;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

export const StyledFolder = styled.div`
  padding-left: 30px;
  margin: 10px 0px;
  visibility: ${(props) => props.skip ? "hidden" : "visible"};
  .folder--label {
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }
`;
export const Collapsible = styled.div`
  height: ${p => (p.isOpen ? "auto" : "0")};
  transition: 0.3ms ease-in-out;
  overflow: ${p => (p.isOpen ? "visible" : "hidden")};
`;


export const DownArrow = styled(IoIosArrowDown)`

 color: #fff;
 color: ${(props) => props.color};
 cursor: pointer;
 background-color: white;
 margin: 3px;
 border-radius: 40px;
 padding: 5px;

 transition: 0.3s ease-out;
  border-radius: 50%;
  &:hover {
  background-color: #f5f5f5;
  transition: 0.3s ease-in;
 }
 
 ${props => props.hidden ? 'display:none' : ''}
`
export const UpArrow = styled(IoIosArrowUp)`

  color: ${(props) => props.color};
  cursor: pointer;
  background-color: white;
  margin: 3px;
  border-radius: 40px;
  padding: 5px;
  transition: 0.3s ease-out;
  border-radius: 50%;
  &:hover {
  background-color: #f5f5f5;
  transition: 0.3s ease-in;
 }
 
 ${props => props.hidden ? 'display:none' : ''}
`

export const JiraIcon = styled(SiJirasoftware)`

 transition: .1s ease-out;

 visibility: ${props => props.hidden ? "hidden" : "visible"};

 &:hover {
  transition: .1s ease-in;
  filter: brightness(1.2);
 }
 
`

export const Description = styled.p`

`;

export const Subtitle = styled.p`
max-width: ${props => props.column ? '100px' : ''};
word-break: break-all;
@media screen and (max-width: 2500px) {
  font-size: 1.6vh;
}
`
export const Cursive = styled.div`
 display: flex;
 flex-direction: row;
 width: 100%;
 justify-content: space-between;
 gap: 1em;
`
export const Row = styled.div`
 display: flex;
 flex-direction: column;
 font-size: 10.5px;
 margin: 0 5px;
 
 gap: 5px;
`
export const JiraReport = styled.div`
 margin: 0 40px;
`

export const IconContainer = styled.div`

 width: 100%;
 background-color: ${(props) => props.bgColor};
 height: 30px;
 border-top-left-radius: 5px;
 border-top-right-radius: 5px;
`
export const JiraLink = styled.a`
	background: linear-gradient(to right, #0051c6, #227df2);
	width: 120px;
    height: 20px;
    background: linear-gradient(to right,#0051c6,#227df2);
    border-radius: 6px;
    color: #fff;
	padding: 4px;
	font-size: 12px;
`;
