import styled from 'styled-components';

export const Container = styled.div`


  margin: 0;
  padding: 0;
  background-color: #fafafa;
`

export const Wrapper = styled.div`
 padding: 1% 7%;
`

export const RecentFiles = styled.div`
  
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  @media screen and (max-width: 1282px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    ;
  }
`
export const ContainerFile = styled.div`


  gap: 15px;
  display:flex;
  flex-direction: row;
  width: 100%;
  box-shadow: 0 1px 2.8px #0003;
  background-color: #fff;
  min-width: 320px;
  padding: 0px;
  border-radius: 5px;

  @media screen and (max-width:  1024px) {
    margin: 10px 0px;
    max-width: 720px
    ;
  }

  @media screen and (max-width:  414px) {
    max-width: 270px
    ;
  }
`

export const Title = styled.h2`


 color: black;
 font-size: 3.5vh;
 letter-spacing: .5px;
 margin-bottom: 10px;

 @media screen and (max-width:  768px) {
    font-size: 20px;
    ;
  }
`

export const TitleContainer = styled.div`

 display: flex;
 flex-direction: row;
 justify-content: space-between;
 margin: 20px 0px;
 align-items: center;
`

export const HeaderTitle = styled.h2`


 margin: 2% 3%;
 padding: 0 2%;
 display: flex;
 font-size: 14px;

 @media screen and (max-width:  768px) {
    font-size: 12px;
    ;
  }
`

export const InputContainer = styled.div`

 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;

`