import React from 'react'
import TableRepos from '../../components/Tables/TableRepos'
import { Container, Title, Wrapper } from './styles'
import intl from "react-intl-universal";

export default function Repo() {
  return (
      <Container>
       <Wrapper>
        <Title>{intl.get("repository.repositories")}</Title>
        <TableRepos/>
       </Wrapper>
      </Container>
  )
}
