import axios from "axios"

let apiUrl = window.location.hostname
if(apiUrl.startsWith('www.')){
  apiUrl = window.location.hostname.substring('www.'.length)
}

let apiPort = window.location.port

if(window.location.port === ''){
  apiPort = '80'
}

if(apiUrl === 'localhost') { 
  apiPort = '8099'
}else{
  apiUrl = 'api.' + apiUrl
}

let apiWsProtocol = 'ws'

if (window.location.protocol === 'https:'){
  apiWsProtocol = 'wss'
}


let baseURL = `//${apiUrl}:${apiPort}/api/v1`

export const api = axios.create({
  baseURL: baseURL
});

export function getUserType(){
  let userType = localStorage.getItem("@production-dagobah-web-user-type")
  return userType != null ? JSON.parse(userType) : '';
}

export const apiConfig = {
  apiUrl, apiPort, apiWsProtocol
}