import React from 'react'
import { InfoCard, InfoText, Square } from './styles'

export default function Indicator({bgColor, children}) {
    return (
        <InfoCard>
            <Square bgColor={bgColor} />
            <InfoText>{children}</InfoText>
        </InfoCard>
    )
}
