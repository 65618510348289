import React, { useEffect, useState, useRef, memo } from 'react';

import {
  Container,
  TitleContainer,
  TimeExecContainer,
  LogResumeContainer,
  ResumeItems,
  ResumeItemsContainer,
  LogVisible,
  Title,
  Description,
  CardContainer,
  Content
} from './styles';

import {
  AiOutlineCheckCircle,
  AiFillCloseCircle,
  AiFillWarning
} from "react-icons/ai";

import { EXECUTION_STATUS } from "../../pages/Projects/Exec/executionStatus"

import StepInformation from '../StepInformation';
import autoAnimate from '@formkit/auto-animate';
import { useSingleEffect } from 'react-haiku';
import intl from 'react-intl-universal';

export const LogCards = memo(({ project, endExecution, socketData, executionStatus }) => {

  const [logVisible, setLogVisible] = useState(false);

  let startingDate, startingTime, finishedDate, finishedTime;

  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());
  const [startProject, setStartProject] = useState();
  const [finishProject, setFinishProject] = useState();

  const ref = useRef(null);

  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current)
  }, [parent]);

  useSingleEffect(() => {
    const date = new Date();
    setStartProject(date.toLocaleTimeString())
  })

  useEffect(() => {
    const date = new Date();
    setFinishProject(date.toLocaleTimeString())
  }, [endExecution])

  const getStartingExecutionDate = (startDate) => {

    let dateInBrazil = startDate, day, month, year, hours, minutes, seconds, timeInBrazil;
    day = dateInBrazil.getDate();
    month = parseInt(dateInBrazil.getMonth()) + 1;
    year = dateInBrazil.getFullYear();

    hours = dateInBrazil.getHours();
    minutes = dateInBrazil.getMinutes();
    seconds = dateInBrazil.getSeconds();

    dateInBrazil = day + '/' + month + '/' + year;
    timeInBrazil = hours + ':' + minutes + ':' + seconds;

    startingDate = dateInBrazil;
    startingTime = timeInBrazil;
  }

  useEffect(() => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }, [socketData, endExecution, executionStatus]);

  useEffect(() => {
    if (executionStatus === EXECUTION_STATUS.STS_MAIN_END_EXECUTION) {
      setFinishDate(new Date());
    }
    if (executionStatus === EXECUTION_STATUS.STS_MAIN_CREATE_EXECUTION) {
      setStartDate(new Date());
    }
  }, [executionStatus]);

  const getFinishedExecutionDate = () => {

    let dateInBrazil = finishDate, day, month, year, hours, minutes, seconds, timeInBrazil;
    day = dateInBrazil.getDate();
    month = parseInt(dateInBrazil.getMonth()) + 1;
    year = dateInBrazil.getFullYear();

    hours = dateInBrazil.getHours();
    minutes = dateInBrazil.getMinutes();
    seconds = dateInBrazil.getSeconds();

    dateInBrazil = day + '/' + month + '/' + year;
    timeInBrazil = hours + ':' + minutes + ':' + seconds;

    finishedDate = dateInBrazil;
    finishedTime = timeInBrazil;
  }

  useEffect(() => {
    const changeLogVisibility = (status) => {
      if (status === 'EXECUTANDO') {
        setLogVisible(true);
      }
    };

    changeLogVisibility(project['executionStatus']);
  }, [project['executionStatus']]);

  const iconByStatus = (status) => {
    if (status === 'PASSOU') {
      return <AiOutlineCheckCircle style={{ color: 'green' }} />
    }
    if (status === 'FALHA' || 'NÃO EXECUTADO') {
      return <AiFillCloseCircle style={{ color: 'red' }} />
    }
    if (status === 'NÃO CONCLUSIVO') {
      return <AiFillWarning style={{ color: 'yellow' }} />
    }
    else {
      return null
    }
  }

  return (
    <Container>
      <CardContainer bgColor={'rgb(101, 161, 194)'} borderColor={'rgb(101, 161, 194)'}>
        <Content ref={parent}>
          <TitleContainer style={{ backgroundColor: 'white', alignItems: 'center', paddingLeft: 10, borderTopRightRadius: 5, width: '100%' }}>
            <Title><strong children={intl.get('test-execution.project-name')} /><span children={project.name} /></Title>
          </TitleContainer>
          <div style={{ backgroundColor: '#e6e6e6' }}>
            {
              getStartingExecutionDate(startDate)
            }
            <TimeExecContainer>
              {socketData && <h1 style={{ fontSize: 10 }}>{intl.get('test-execution.starting_execution')} {startingDate} - {startProject}</h1>}
            </TimeExecContainer>
          </div>
          {
            project['scripts'] ?
              project['scripts'].map((script, scriptIndex) => {
                if (script.executionStatus === 'EXECUTANDO' || script.executionStatus === 'PASSOU' ||
                  script.executionStatus === 'FALHA' || script.executionStatus === 'NÃO EXECUTADO' ||
                  script.executionStatus === 'NÃO CONCLUSIVO') {
                  return (
                    <CardContainer bgColor={'#64C6C4'} borderColor={'#64C6C4'} key={scriptIndex}>
                      <Content>
                        <TitleContainer style={{ backgroundColor: 'white', alignItems: 'center', paddingLeft: 10, borderTopRightRadius: 5, width: '100%' }}>
                          <Title><strong children={intl.get('test-execution.script-name')} /><span children={script.name} /></Title>
                        </TitleContainer>
                        <div style={{ backgroundColor: 'white', alignItems: 'center', borderBottomRightRadius: 5 }}>
                          {script['features'].map((feature, featIndex) => {
                            if (feature.executionStatus === 'EXECUTANDO' || feature.executionStatus === 'PASSOU' ||
                              feature.executionStatus === 'FALHA' || feature.executionStatus === 'NÃO EXECUTADO' ||
                              feature.executionStatus === 'NÃO CONCLUSIVO') {
                              return (
                                <CardContainer bgColor={'#a58bd5'} borderColor={'#a58bd5'} key={featIndex}>
                                  <Content>
                                    <TitleContainer style={{ backgroundColor: 'white', alignItems: 'center', paddingLeft: 10, borderTopRightRadius: 5 }}>
                                      <Title><strong children={intl.get('test-execution.feature-name')} /><span children={feature.name} /></Title>
                                    </TitleContainer>
                                    <div style={{ backgroundColor: 'white', alignItems: 'center', borderBottomRightRadius: 5 }}>
                                      {feature['scenarios'].map((scenario, scenarioIndex) => {
                                        if (scenario.executionStatus === 'EXECUTANDO' || scenario.executionStatus === 'PASSOU' ||
                                          scenario.executionStatus === 'FALHA' || scenario.executionStatus === 'NÃO EXECUTADO' ||
                                          scenario.executionStatus === 'NÃO CONCLUSIVO') {
                                          return (
                                            <CardContainer bgColor={'#daad6a'} borderColor={'#daad6a'} key={scenarioIndex}>
                                              <Content style={{ height: '100%' }}>
                                                <TitleContainer style={{ flexDirection: 'column', backgroundColor: 'white', justifyContent: 'center', paddingLeft: 10, borderTopRightRadius: 5, borderBottomRightRadius: 5 }}>
                                                  <Title><strong children={intl.get('test-execution.scenario-name')} /><span children={scenario.name} /></Title>
                                                  <Description>
                                                    <strong children={intl.get('report.description')} />
                                                    <span children={scenario.description} />
                                                  </Description>
                                                </TitleContainer>
                                                <div style={{ backgroundColor: 'white', alignItems: 'center', borderBottomRightRadius: 5 }}>
                                                  {scenario['steps'].map((step, stepIndex) => {
                                                    if (step.executionStatus === 'EXECUTANDO' || step.executionStatus === 'PASSOU' ||
                                                      step.executionStatus === 'FALHA' || step.executionStatus === 'NÃO EXECUTADO' ||
                                                      step.executionStatus === 'NÃO CONCLUSIVO') {
                                                      return (
                                                        <CardContainer bgColor={'#ED92B1'} borderColor={'#ED92B1'} key={stepIndex}>
                                                          <Content style={{ height: '100%', lineBreak: 'anywhere' }}>
                                                            <TitleContainer style={{ flexDirection: 'column', height: '100%', backgroundColor: 'white', justifyContent: 'center', paddingLeft: 10, borderTopRightRadius: 5, borderBottomRightRadius: 5, paddingTop: 10 }}>
                                                              <Title><strong children={intl.get('test-execution.step-name')} /><span children={step.name} /></Title>
                                                              <Description>
                                                                <strong children={intl.get('report.description')} />
                                                                <span children={step.description} />
                                                              </Description>
                                                              <StepInformation socketData={socketData} no={step.no} user_confirm={step.user_confirm} />
                                                            </TitleContainer>
                                                          </Content>
                                                        </CardContainer>
                                                      );
                                                    }
                                                  })}
                                                </div>
                                              </Content>
                                            </CardContainer>
                                          );
                                        }
                                      })}
                                    </div>
                                  </Content>
                                </CardContainer>
                              );
                            }
                          })}
                        </div>
                      </Content>
                    </CardContainer>
                  );
                }
              }
              ) : <></>
          }
        </Content>
      </CardContainer>
      {
        endExecution ?
          <>
            <div style={{ backgroundColor: '#e6e6e6' }}>
              {
                getFinishedExecutionDate()
              }
              <TimeExecContainer>
                <h1 style={{ fontSize: 10 }}>{intl.get('test-execution.execution_finished')}{finishedDate} - {finishProject}</h1>
              </TimeExecContainer>
            </div>
            <LogResumeContainer>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <h5>{intl.get('test-execution.resume')}</h5>
              </div>
              {
                project.scripts ?
                  project.scripts.map((script, scriptIndex) => {
                    return (
                      <ResumeItemsContainer key={scriptIndex}>
                        {
                          script.executionStatus !== 'NÃO SELECIONADO' ?
                            <ResumeItems>
                              {
                                iconByStatus(script.executionStatus)
                              }
                              <p><strong>Script</strong> {script.name}</p>
                            </ResumeItems> : null
                        }
                        {
                          script ?
                            script.features.map((feature, featIndex) => {
                              return (
                                <div key={featIndex}>
                                  {
                                    feature.executionStatus !== 'NÃO SELECIONADO' ?
                                      <ResumeItems>
                                        {
                                          iconByStatus(feature.executionStatus)
                                        }
                                        <p><strong>Feature</strong>: {feature.name}</p>
                                      </ResumeItems> : null
                                  }
                                  {
                                    feature ?
                                      feature.scenarios.map((scenario, scenarioIndex) => {
                                        return (
                                          <div key={scenarioIndex}>
                                            {
                                              scenario.executionStatus !== 'NÃO SELECIONADO' ?
                                                <ResumeItems>
                                                  {
                                                    iconByStatus(scenario.executionStatus)
                                                  }
                                                  <p style={{ display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '44ch' }}><strong>Scenario</strong>: {scenario.name}</p>
                                                </ResumeItems> : null
                                            }
                                            {
                                              scenario ?
                                                scenario.steps.map((step, stepIndex) => {
                                                  return (
                                                    <div key={stepIndex}>
                                                      {
                                                        step.executionStatus !== 'NÃO SELECIONADO' ?
                                                          <ResumeItems>
                                                            {
                                                              iconByStatus(step.executionStatus)
                                                            }
                                                            <p><strong>Step</strong>: {step.name}</p>
                                                          </ResumeItems> : null
                                                      }
                                                    </div>
                                                  )
                                                })
                                                : <></>
                                            }
                                          </div>
                                        )
                                      })
                                      : <></>
                                  }
                                </div>
                              )
                            })
                            : <></>
                        }
                      </ResumeItemsContainer>
                    )

                  })
                  : <></>
              }
            </LogResumeContainer>
          </> : <></>
      }

      <div ref={ref} />
    </Container>
  );
})