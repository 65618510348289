import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  
  padding: 1% 7.3%;
`

export const Wrapper = styled.div`
  box-shadow: 0 1px 2px #0003;
  border-radius: 10px;

`

export const RecentFiles = styled.div`
  
  display:flex;
  flex-direction: row;
  gap: 20px;
  

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    ;
  }
`
export const HeaderTitle = styled.h2`


 color: black;
 margin-bottom: 20px;
 font-size: 3.5vh;
`
