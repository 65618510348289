import React from 'react'
import { Container, Select, Title } from './styles';
import { MultiSelect } from "react-multi-select-component";
import intl from "react-intl-universal";

const SelectRepo = ({ options, value, onChange, customValueRenderer, overrideStrings, title}) => {

  return (
    <Container>
      <Title>{title}</Title>
      <MultiSelect 
        options={options} 
        value={value} 
        onChange={onChange} 
        valueRenderer={customValueRenderer}
        overrideStrings={overrideStrings}
      />
    </Container>
  )
}

export default SelectRepo