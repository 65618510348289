import React from 'react'
import intl from "react-intl-universal";
import TableProjects from '../../components/Tables/TableProjects'
import {
  Container,
  HeaderTitle,
  Wrapper
} from './styles'

export default function Projects() {
  
  return (
    <Container>
      <HeaderTitle>{intl.get("projects.projects")}</HeaderTitle>
       <Wrapper>
        <TableProjects />
       </Wrapper>
    </Container>
  )
}
