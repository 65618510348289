import React from 'react'
import { Container, Title } from './styles'
import intl from "react-intl-universal"

export default function Status200() {
  return (
    <Container>
      <Title>{intl.get("repository.registered")}</Title>
    </Container>
  )
}
