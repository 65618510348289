
import styled from 'styled-components';
import { AiOutlineFileText, AiOutlineFolder } from "react-icons/ai";

export const Container = styled.div`
  padding: 15px;

  span {
    margin-left: 15vw;
    font-size: 1.8vh;
  }
`
export const CardsProjects = styled.div`
  
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 20px 0;
`

export const CardsReports = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0;
`

export const Title = styled.h3`

  font-size: 2.5vh;
  font-weight: 600;

`

export const CardContainer = styled.div`

  

`

export const Project = styled.div`


 background-color: #eeeeee;
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
 max-width: 120px;
 font-size: 10.5px;
 margin-right: 20px;
 height: 95px;
 cursor: pointer;
 border-radius: 6px;
 border-bottom: .6vh solid #3b9ccf;
 color: black;
 padding: 0 10px 5px;
  box-shadow: 0px 1px 3px #0003;

 &:hover { 
  background-color: #e1eef6;
  transition: 0.3s ease-in-out; 
}

@media screen and (max-width: 1600px) { 
   height: 80px;
   min-width: 120px;
  }
`

export const Report = styled.div`

 background-color: #eeeeee;
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
 max-width: 120px;
 font-size: 10.5px;
 margin-right: 20px;
 height: 95px;
 cursor: pointer;
 border-radius: 6px;
 border-bottom: .6vh solid #6cc2bf;
 color: black;
 line-break: anywhere;
 padding: 0 6px;
 box-shadow: 0px 1px 3px #0003;
 
 &:hover { 
  background-color:#e4eeed;
  transition: 0.3s ease-in-out; 
}
@media screen and (max-width: 1600px) { 
   height: 80px;
   min-width: 120px;
  }
`

export const Folder = styled(AiOutlineFolder)`

 color: #3b9ccf;
 font-size: 1.85vw;
 margin: 3px 0 10px;

`

export const Sheet = styled(AiOutlineFileText)`

color: #6cc2bf;
font-size: 1.65vw;
 margin: 3px 0 10px;
`

export const Info = styled.p`
  line-break: anywhere;
  display:inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 17ch;
  font-size: 10px
`