import React, { useState } from "react";
import Modal from "react-modal";
import { Container, HeaderModal, ModalContent, Title, Wrapper } from "./styles";
import TextInput from "../../../Input/TextInput";
import ButtonActions from "../../../Button/ButtonActions";
import { IoCloseSharp } from "react-icons/io5";
import { api } from "./../../../../api";
import intl from "react-intl-universal";
import ErrorLog from "../../ModalSync/ErrorLog";
import Success from "../../Success";

export default function ModalAddGit({
  isOpen,
  onRequestClose,
  onClick,
  handleWarn,
  setMessage,
  setIsShowLoader,
}) {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [viewErrorModal, setViewErrorModal] = useState(false);

  const handleNewGitRepo = async (e) => {
    e.preventDefault();
    setIsShowLoader(true);

    if (name === "" || url === "") {
      setIsShowLoader(false);
      setErrorMsg(intl.get("repository.please-feel-all-fields"));
      setViewErrorModal(true);
    } else {
      try {
        await api.post("/repository/git", { name: name, url: url });
        setIsShowLoader(false);
        setSuccessModal(true);
      } catch (error) {
        handleWarn();
        if (error.response.status >= 300) {
          setIsShowLoader(false);
          setMessage(error.response.data.detail);
        }
      }
    }
  };

  const closeModal = (e) => {
    onRequestClose(e);
    setName("");
    setUrl("");
  };

  const closeErrorModal = (e) => {
    e.preventDefault();
    setViewErrorModal(false);
  }

  const handleCloseSuccess = (e) => {
    e.preventDefault();
    setSuccessModal(false);
    onRequestClose(e);
    setName("");
    setUrl("");
  };

  return (
    <Modal
      isOpen={isOpen}
      onClick={onClick}
      closeTimeoutMS={115}
      shouldCloseOnEsc={false}
      onRequestClose={onRequestClose}
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container>
        <HeaderModal>
          <Title>{intl.get("repository.git-register")}</Title>
          <IoCloseSharp
            style={{ cursor: "pointer", marginRight: 7 }}
            size={18}
            onClick={closeModal}
          />
        </HeaderModal>
        <ModalContent>
          <TextInput value={name} onChange={(e) => setName(e.target.value)}>
            <Title>{intl.get("repository.name")}</Title>
          </TextInput>
          <TextInput value={url} onChange={(e) => setUrl(e.target.value)}>
            <Title>URL</Title>
          </TextInput>
          <Wrapper>
            <ButtonActions
              bgColor={"#104068"}
              text={intl.get("repository.save")}
              onClick={handleNewGitRepo}
            />
            <ButtonActions
              bgColor={"#ed6663"}
              text={intl.get("repository.cancel")}
              onClick={closeModal}
            />
          </Wrapper>
        </ModalContent>
        <Success
          isOpen={successModal}
          onRequestClose={handleCloseSuccess}
          title={intl.get("repository.git-register")}
          message={intl.get("repository.msg-sucess")}
        />
        <ErrorLog
          isOpen={viewErrorModal}
          onRequestClose={closeErrorModal}
          title1={intl.get("repository.git-register")}
          message={errorMsg}
        />
      </Container>
    </Modal>
  );
}
