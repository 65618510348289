import React, { useContext, useState, useEffect } from 'react'
import Modal from 'react-modal';
import { Container, HeaderModal, Title, Wrapper, ModalContent } from './styles';
import { IoCloseSharp } from "react-icons/io5";
import { api } from '../../../../../api';
import TextInput from '../../../../Input/TextInput';
import ButtonActions from '../../../../Button/ButtonActions';
import DisabledTxtInput from '../../../../Input/TextInput/Disabled';
import SuccessModal from '../../Success';
import ErrorLog from '../../../ModalSync/ErrorLog';

export default function UpdDisable({ isOpen, onRequestClose, onClick, item }) {

  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openRuleModal, setOpenRuleModal] = useState(false);

  useEffect(() => {
    if (item) {
      setName(item.name);
      setUrl(item.url);
    }
  }, [item])

  function openSuccessModal() {
    setOpenSuccess(true);
  };

  function closeSuccessModal() {
    setOpenSuccess(false);
  };

  const handleSuccess = () => {
    openSuccessModal();
  };

  function openModalRule() {
    setOpenRuleModal(true);
  };

  function closeModalRule() {
    setOpenRuleModal(false);
  };


  function handleWarn() {
    openModalRule();
  };

  const handleUpdGit = async (e) => {
    e.preventDefault();

    const editRepo = async (data) => {
      const response = await api.put(`/repository/${item.id}`,
        { name: name })
      handleSuccess();
    }

    try {
      if (!name) {
        openModalRule();
      }
      else {
        editRepo();
      }
    }
    catch (error) {
      handleWarn();
      alert(error)
    }

  };

  return (
    <Modal
      isOpen={isOpen}
      onClick={onClick}
      onRequestClose={onRequestClose}
      shouldFocusAfterRender={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content">
      <Container>
        <HeaderModal>
          <Title>Git - Editar Repositório</Title>
          <IoCloseSharp
            style={{ cursor: 'pointer', marginRight: 7 }}
            size={18}
            onClick={onRequestClose} />
        </HeaderModal>
        <ModalContent>
          <TextInput
            value={name}
            defaultValue={item ? item.name : ''}
            onChange={(e) => {
              setName(e.target.value);
            }
            }
          >
            <Title>Nome</Title>
          </TextInput>
          <DisabledTxtInput
            disabled
            defaultValue={item ? item.url : ''}
          >
            <Title>URL</Title>
          </DisabledTxtInput>
          <Wrapper>
            <ButtonActions
              bgColor={'#104068'}
              text={'Salvar'}
              onClick={handleUpdGit} />
            <ButtonActions
              bgColor={'#ed6663'}
              text={'Cancelar'}
              onClick={onRequestClose} />
          </Wrapper>
        </ModalContent>
      </Container>
      <SuccessModal
        isOpen={openSuccess}
        onRequestClose={closeSuccessModal}
        title={'Repositórios'}
        message={'Informações armazenadas com sucesso!'} />
      <ErrorLog
        isOpen={openRuleModal}
        onRequestClose={closeModalRule}
        title={'Operação não realizada.'}
        message={'Por favor, preencha todos os campos.'}
      />
    </Modal>
  )
}
