import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineLeftSquare, AiOutlineRightSquare } from "react-icons/ai";
import { Container, InputContainer, PlayIcon, Spinner, Table, Title, TitleContainer } from './styles';
import { Link } from 'react-router-dom';
import moment from 'moment';
import SearchInput from './../../Input/SearchInput';
import { api } from '../../../api';
import EmptyHome from './../EmptyTable/Home/index';
import { HiSortDescending } from "react-icons/hi";
import intl from "react-intl-universal";
import { ToolTip } from '../../ToolTip/Icon';
import { useMediaQuery } from 'react-haiku';

export default function TableHome() {

  const [repos, setRepos] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState('');
  const [pending, setPending] = useState(true);

  const isEdging = useMediaQuery('(max-width: 1400px)');

  const fetchData = async () => {
    try {
      const response = await api.get(`/executed-projects`);
      setRepos(response.data.items)
      setFiltered(response.data.items)
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const searchFilter = useCallback((e) => {

    e.preventDefault()

    const result = repos.filter((item) => {
      return item.name.toLowerCase().match(search.toLowerCase()) ||
        item.description.toLowerCase().match(search.toLowerCase()) ||
        item.repository.toLowerCase().match(search.toLowerCase()) ||
        moment(item.latest_exec_date).format('DD/MM/YYYY - HH:mm:ss').match(search.toString()) ||
        item.total_executions.toString().match(search.toString())
    });

    if (search != '') {
      setPending(true)
      const filtering = setTimeout(() => {
        setFiltered(result)
        setPending(false)
      }, 300)
      return () => clearTimeout(filtering)
    } else {
      setFiltered(result)
    };

    return filtered

  }, [search, repos]);

  const columns = useMemo(() => [
    {
      name: intl.get('home.name'),
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: intl.get('home.description'),
      selector: (row) => row.description,
      sortable: true
    },
    {
      name: intl.get('home.repository'),
      selector: (row) => row.repository,
      sortable: true
    },
    {
      name: intl.get('home.last-execute'),
      selector: (row) => moment(row.latest_exec_date).format('DD/MM/YYYY - HH:mm:ss'),
      sortable: true
    },
    {
      name: intl.get('home.total-execution'),
      selector: (row) => row.total_executions,
      sortable: true
    },
    {
      name: intl.get('home.actions'),
      selector: (row) =>
        <Link to={`/projects/execute-project/${row.project_id}/exec`}>
          <ToolTip text={intl.get('home.roll-test')} children={
            <PlayIcon />
          } />
        </Link>,
      width: "100px"
    },
  ], []);

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: '#eaf1f7',
        fontSize: isEdging ? 14 : 17,
      },
    },
    cells: {
      style: {
        fontSize: isEdging ? 11 : 12.5,
      },
    },
    subHeader: {
      style: {
        backgroundColor: 'transparent'
      }
    }
  };

  const paginationChanges = {
    rowsPerPageText: '',
    rangeSeparatorText: intl.get("projects.range-separator"),
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  const CustomLoader = () => (
    <div style={{ padding: '24px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Spinner />
      <div>{intl.get('loader.loading')}</div>
    </div>
  );

  return (
    <Container>
      <Table
        highlightOnHover={true}
        columns={columns}
        customStyles={customStyles}
        noDataComponent={<EmptyHome />}
        data={filtered}
        progressPending={pending}
        progressComponent={<CustomLoader />}
        selectableRowsHighlight
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10, 15, 20, 30]}
        paginationComponentOptions={paginationChanges}
        paginationIconFirstPage={<AiOutlineLeftSquare size={23} />}
        paginationIconLastPage={<AiOutlineRightSquare size={23} />}
        subHeader
        subHeaderComponent={
          <TitleContainer>
            <Title>{intl.get('home.ranking-of-executed-projects')}</Title>
            <InputContainer>
              <SearchInput
                type="text"
                placeholder={intl.get('home.search')}
                style={{ borderRadius: 5, position: 'relative' }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onSubmit={searchFilter}
              />
              <></>
            </InputContainer>
          </TitleContainer>
        }
        sortIcon={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 5,
              marginTop: 5
            }}>
            <HiSortDescending />
          </div>}
      />
    </Container>
  )
}