import DataTable from 'react-data-table-component';
import { AiOutlineEdit } from 'react-icons/ai';
import { IoCloseSharp } from 'react-icons/io5';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`

 margin: 0;
 padding: 1% 7%;
 
`

export const Wrapper = styled.div`

  
  background-color: white;
  padding: 20px;
`

export const Table = styled(DataTable)`
  

  
  

`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
	margin: 16px;
	animation: ${rotate360} 1s linear infinite;
	transform: translateZ(0);
	border-top: 2px solid grey;
	border-right: 2px solid grey;
	border-bottom: 2px solid grey;
	border-left: 4px solid black;
	background: transparent;
	width: 80px;
	height: 80px;
	border-radius: 50%;
`;

export const Title = styled.h2`


color: black;
 font-size: 3.5vh;
 margin-bottom: 1%;
`

export const TitleContainer = styled.div`

 width: 100%;
 display: flex;
 flex-direction: column;
 
`


export const InputContainer = styled.div`

 display: flex;
 align-items: center;
 flex-direction: row;
 justify-content: space-between;
 width: 100%;
 margin-bottom: 20px;

`

export const CloseIcon =  styled(IoCloseSharp)`

 color:#3b9ccf;
 font-size: 23px;
 cursor: pointer;

 &:hover { 
 background-color: white;
 padding: 1px;
 transition: 0.4s ease-in-out; 
 border-radius: 5px;
 box-shadow: 0 1px 2.8px #0003;
}

        
`;

export const UpdIcon =  styled(AiOutlineEdit)`

 color:#64c6c4;
 font-size: 23px;
 cursor: pointer;

 &:hover { 
 background-color: white;
 padding: 1.5px;
 transition: 0.4s ease-in-out; 
 border-radius: 5px;
 box-shadow: 0 1px 2.8px #0003;
}

        
`;