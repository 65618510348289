import React from 'react';
import { Container, Loading } from './style';
import Modal from 'react-modal';

export default function Loader({ isShow }) {
    return (
        <Modal
            isOpen={isShow}
            closeTimeoutMS={115}
            shouldFocusAfterRender={false}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            overlayClassName="react-modal-overlay"
            className="react-modal-content">
            <Container>
                <Loading />
            </Container>
        </Modal>
    )
} 
