import React, {useState, useEffect} from 'react'
import DataTable from 'react-data-table-component'
import { AiOutlineLeftSquare, AiOutlineRightSquare } from 'react-icons/ai';
import { Container, STable, STH, STHead, STHeadTR } from './styles'
import intl from "react-intl-universal";

export default function EmptyProjects() { 

  const [msg1, setMsg1] = useState(intl.get("projects.msg1"));
  const [rangeSeparator, setRangeSeparator] = useState(intl.get("projects.range-separator"));

  const columns = [
    {
      name: "",
      selector: "",
    },
    {
      name: "",
      selector: "",
    },
    {
      name: "",
      selector: "last_name",
    },
    {
      name: "",
      selector: "title",
      width: "300px",
    },
    {
      name: "",
      selector: "",
    },
    {
      name: "",
      selector: "",
    },
    {
      name: "",
      selector: "last_name",
    }
  ];

  const data = [
    {
      "id" : "1",
      "title": msg1
    },
  
  ];

  const styles = {
    cells: {
      style: {
        paddingLeft: 55,
        marginBottom: 113,
        marginTop: 113
      },
    },
    pagination: {
      style: {
        minWidth: 1100
      },
    },
  };

  const paginationChanges = {
    rowsPerPageText: '',
    rangeSeparatorText: rangeSeparator,
  }; 


  return (
    <Container>
         <STable>
            <STHead>
                <STHeadTR>
                <STH style={{width: 260}}>{intl.get("projects.origin")}</STH>
                <STH style={{width: 260}}>{intl.get("projects.repository")}</STH>
                <STH style={{width: 260}}>{intl.get("projects.project")}</STH>
                <STH style={{width: 260}}>{intl.get("projects.description")}</STH>
                <STH style={{width: 260}}>{intl.get("projects.update-date")}</STH>
                <STH>{intl.get("projects.actions")}</STH>
                </STHeadTR>
            </STHead>
        </STable> 
      <DataTable 
      paginationPerPage={5}
      paginationRowsPerPageOptions={[5, 10, 15, 20, 30]}
      paginationComponentOptions={paginationChanges}
      paginationIconFirstPage={<AiOutlineLeftSquare size={23} />}
      paginationIconLastPage={<AiOutlineRightSquare size={23} />}
      customStyles={styles}
      columns={columns} 
      data={data} 
      noTableHead
      pagination 
      responsive
      />
    </Container>
  );
}
