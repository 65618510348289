import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import {
  Container,
  HeaderModal,
  NextOrder,
  SubTitle,
  Title,
  Wrapper,
} from "./styles";
import { BsQuestionCircle } from "react-icons/bs";
import ButtonActions from "../../../Button/ButtonActions";
import { IoCloseSharp } from "react-icons/io5";
import SuccessModal from "../../ModalGit/Success";
import { api } from "./../../../../api";
import intl from "react-intl-universal";
import ErrorLog from "../../ModalSync/ErrorLog";

export default function ModalOffReport({
  isOpen,
  onRequestClose,
  onClick,
  selectedRows,
  action
}) {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFail, setOpenFail] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  function openSuccessModal() {
    setOpenSuccess(true);
  }

  const closeSuccessModal = (e) => {
    e.preventDefault();
    setOpenSuccess(false);
    onRequestClose(e);
    action();
  };

  function openFailModal() {
    setOpenFail(true);
  }

  function closeFailModal(e) {
    e.preventDefault();
    setOpenFail(false);
    onRequestClose(e);
  }

  const handleDeleteRepo = (e) => {
    e.preventDefault();

    let ids = new URLSearchParams();

    selectedRows.forEach((item) => {
      ids.append("reports", item.id);
    });

    api
      .delete(`/report`, {
        params: ids,
      })
      .then(() => {
        openSuccessModal();
      })
      .catch((error) => {
        setErrorMsg(error.response.data.detail);
        console.log(error);
        openFailModal();
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClick={onClick}
      closeTimeoutMS={115}
      onRequestClose={onRequestClose}
      shouldFocusAfterRender={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container>
        <HeaderModal>
          <Title>{intl.get("report.delete-report")}</Title>
          <IoCloseSharp
            style={{ cursor: "pointer", marginRight: 7 }}
            size={18}
            onClick={onRequestClose}
          />
        </HeaderModal>
        <NextOrder>
          <BsQuestionCircle
            style={{ cursor: "pointer", marginRight: 7, marginBottom: 10 }}
            size={40}
            color={"#ddba84"}
          />
          <SubTitle>
            {intl.get("repository.delete-repository-question")}
          </SubTitle>
        </NextOrder>
        <Wrapper>
          <ButtonActions
            bgColor={"#104068"}
            text={intl.get("repository.yes")}
            onClick={handleDeleteRepo}
          />
          <ButtonActions
            bgColor={"#ed6663"}
            text={intl.get("repository.cancel")}
            onClick={onRequestClose}
          />
        </Wrapper>
      </Container>
      <SuccessModal
        isOpen={openSuccess}
        onRequestClose={closeSuccessModal}
        title={intl.get("repository.repositories")}
        message={intl.get("repository.delete-repository-sucess")}
      />
      <ErrorLog
        isOpen={openFail}
        onRequestClose={closeFailModal}
        message={errorMsg}
      />
    </Modal>
  );
}
