import styled from 'styled-components';

export const Container = styled.div`
 display: ${props => props.showNav && 'none'};
.nav-menu {
    background-color: #103F68;
    width: 65px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0px;
    transition: 200ms;
    z-index: 999;
}

.nav-menu-active {
    background-color: #103F68;
    width: 195px;
    transition: 200ms;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0px;
    z-index: 999;
    height: 100vh;
}

`

export const PtBrButton = styled.button`
  outline: none;
  border:none;
  width: 30px;
  height: 25px;
  border-radius: 10px;
  
  background-color: transparent;

  pointer-events: ${props => props.disabled === true ? "none" : "all"} ;
  filter: ${props => props.disabled === true ? "brightness(0.60)" : "brightness(1)"} ;

`

export const EnButton = styled.button`
  outline: none;
  border:none;
  width: 30px;
  height: 25px;
  border-radius: 10px;
  
  background-color: transparent;
  
  pointer-events: ${props => props.disabled === true ? "none" : "all"} ;
  filter: ${props => props.disabled === true ? "brightness(0.60)" : "brightness(1)"} ;

`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`

export const Date_Time = styled.p`

  position: relative;
  text-align: center;

  @media screen and (max-width:  1220px) {
   width: 39%;
   font-size: 20px;
  }

  @media screen and (max-width:  580px) {
   display: none;
  }
`

export const Title = styled.h3`




`
