import React from 'react'
import { AiOutlineWarning } from 'react-icons/ai';
import { IoCloseSharp } from 'react-icons/io5'
import Modal from 'react-modal'
import ButtonActions from '../../../Button/ButtonActions';
import { Container, HeaderModal, NextOrder, Title, Wrapper } from './styles'
import intl from "react-intl-universal"

export default function FailDelete({isOpen, onClick, onRequestClose}) {

  return (
    <Modal
    isOpen={isOpen}
    onClick={onClick}
    onRequestClose={onRequestClose}
    shouldFocusAfterRender={false}
    overlayClassName="react-modal-overlay"
    className="react-modal-content">
    <Container>
      <HeaderModal>
        <Title>{intl.get("repository.repositories")}</Title>
        <IoCloseSharp
          style={{ cursor: 'pointer', marginRight: 7 }}
          size={18}
          onClick={onRequestClose} />
      </HeaderModal>
      <NextOrder>
    <AiOutlineWarning 
    style={{ marginRight: 7, marginBottom: 10 }} 
    size={40} 
    color={'#ed6663'}/>
    <Title>{intl.get("repository.delete-repository-not-allowed")}</Title>
    </NextOrder>
    <Wrapper>
      <></>
      <ButtonActions
      bgColor={'#ed6663'}
      text={intl.get("repository.cancel")}
      onClick={onRequestClose}/>
    </Wrapper>
    </Container>
  </Modal>
  )
}
