import React from 'react'
import { Container, TooltipCard, Tooltip, TooltipBox } from './styles';
import { BiInfoCircle } from "react-icons/bi";

const ToolTipFolder = ({ children }) => {

    return (
        <Container>
            <TooltipCard>
                <Tooltip>
                   <BiInfoCircle/>
                </Tooltip>
                <TooltipBox>
                    {children}
                </TooltipBox>
            </TooltipCard>
        </Container>
    )
}

export default ToolTipFolder