import styled from 'styled-components';

export const Container = styled.form`
    width: 400px;
    height: 300px;
    background-color: #fff;
    
 
    border-radius: 2px;
`

export const Wrapper = styled.div`
    display: flex;
    justify-content: end;

    margin: 30px 20px 0;
    gap: 20px;

`

export const HeaderModal = styled.div`

 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: space-between;
 padding: 15px 20px;
 box-shadow: 1px 1px 1px #00000029;

`

export const Title = styled.p`



`

export const NextOrder = styled.div`

 display: flex;
 height: 150px;
 flex-direction: column;
 align-items: center;
 justify-content: center;

`

export const SubTitle = styled.p`

 display: flex;
 align-items: center;
 justify-content: center;
 text-align: center;
 font-weight: 500;
 width: 270px;
`