import styled from "styled-components"

export const InfoCard = styled.div`

  display: flex;
  
  flex-direction: row;
  width: 130%;
  align-items: center;

  @media screen and (max-width: 1200px) {
    width: 130px;
  }
  
`
export const Square = styled.div`

  height: 20px;
  width: 20px;
  background: ${(props) => props.bgColor};
  border-radius: 3px;
 
  @media screen and (max-width: 2500px) {
    height: 3vh;
    width: 1.6vw;
  }
`
export const InfoText = styled.p`

 font-weight: 400;
 font-size: .9vw; 
 margin-left: 10px;
 align-items: center;
`