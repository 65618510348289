import React from 'react'
import Modal from 'react-modal';
import { Container, HeaderModal, Icon, InfoContainer, NextOrder, Title, Wrapper } from './styles'
import ButtonActions from '../../../Button/ButtonActions';
import { IoCloseSharp } from 'react-icons/io5';
import intl from "react-intl-universal"

export default function DeleteRepo({ isOpen, onClick, onRequestClose, message, title, title1, hidden }) {

    return (
        <Modal
            isOpen={isOpen}
            onClick={onClick}
            closeTimeoutMS={115}
            onRequestClose={onRequestClose}
            shouldFocusAfterRender={false}
            overlayClassName="react-modal-overlay"
            className="react-modal-content">
            <Container>
                <HeaderModal>
                    <Title style={{ fontWeight: 500 }}>{title1}</Title>
                    <IoCloseSharp
                        style={{ cursor: 'pointer', marginRight: 7 }}
                        size={18}
                        onClick={onRequestClose} />
                </HeaderModal>
                <NextOrder>
                    <Icon
                        hidden={hidden}
                        style={{ marginRight: 7, marginBottom: 10 }}
                        size={40}
                        color={'#ed6663'} />
                    <Title style={{ fontWeight: 600, marginBottom: 5 }}>{title}</Title>
                    <InfoContainer>
                        {intl.get("repository.delete-fail")}
                    </InfoContainer>
                </NextOrder>
                <Wrapper>
                    <></>
                    <ButtonActions
                        bgColor={'#ed6663'}
                        text={intl.get("import-local-directory.cancel")}
                        onClick={onRequestClose} />
                </Wrapper>
            </Container>
        </Modal>
    )
}

