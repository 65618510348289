import styled from 'styled-components';

export const Container = styled.div`
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
  width: 100%;

  margin-top: 10px;
  
`

export const InputContainer = styled.div`


  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 37px;
  width: 100%;
  min-width: 220px;
  background-color: #fafafa;
  border: none;
  line-height: normal;
  padding-left: 10px;
  box-shadow: 0px 1.4px #e2e2e2;

  &:hover {
    box-shadow: 0px 1.4px #777777;
    transition: 0.25s ease-in-out;
  }

  @media screen and (max-width: 1300px) {
    width: 100%;
  }

`

export const Select = styled.select`
 
  outline: none;
  border-color: transparent;
  align-content: center;
  font-size: 11.2px;
  width: 100%;
  background-color: #fafafa;
`

export const Title = styled.h4`


 font-size: 12px;
 color: black;
`