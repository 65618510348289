import styled from 'styled-components';
import { IoSearch } from "react-icons/io5";

export const Container = styled.div`
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
  width: 100%;

  margin-top: 10px;
  
`

export const InputContainer = styled.div`


  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 33px;
  
  
  border: none;
  line-height: normal;
  font-size: 14px;
  
  

`

export const Select = styled.select`
  height: 35px;
  background: white;
  color: gray;
  padding-left: 5px;
  font-size: 14px;
  border: none;
  width: 100%;
  box-shadow: 0px 1.4px #e2e2e2;
  background-color: #fafafa;

  option {
    color: black;
    display: flex;
    min-height: 20px;
    padding: 0px 2px 1px;
    height: 200px;
    flex-direction: column;
  }
`

export const Icon = styled(IoSearch)`

 color: #8ca6b3;
 font-size: 12px;
 

`

export const IconView = styled.div`
 
 background-color: #eff4f8;
 height: 28px;
 width: 25px;
 display: flex;
 align-items: center;
 justify-content: center;
 cursor: pointer;
`

export const Title = styled.h4`

 font-size: 1.8vh;
 color: black;
 margin-bottom: 1vh;
`