import React from 'react'
import { Container, Icon, IconView, Input } from './styles';

const SearchInput = ({ type, placeholder, value, onChange, onSubmit, style }) => {

  return (
    <Container
      style={style}
      onSubmit={onSubmit}>
      <Input
        value={value}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
      />
      <IconView
        type='submit'
        children={<Icon />}
      />
    </Container>
  )
}

export default SearchInput