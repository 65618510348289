import React, { useState } from 'react'
import Modal from 'react-modal';
import { Container, HeaderModal, ModalContent, Title, Wrapper } from './styles';
import TextInput from '../../../Input/TextInput';
import ButtonActions from '../../../Button/ButtonActions';
import { IoCloseSharp } from "react-icons/io5";
import { api } from './../../../../api';
import intl from "react-intl-universal"
import SelectInput from '../../../Input/SelectInput';
import { useEffect } from 'react';

export default function ModalUpdDS({ isOpen, onRequestClose, onClick, setMessage, closeModal, title, item }) {
  const [name, setName] = useState("");
  const [status, setStatus] = useState(null);

  useEffect(() => {
    setName(item?.name);
    setStatus(item?.status === 'Ativo' || item?.status === 'Active' ? true : false);
  }, [item]);

  const handleNewDS = async (e) => {
    e.preventDefault();
    try {
      if (name === "" || !status) {
        setMessage(intl.get("datasheet.empty-fields-message"));
        onRequestClose(false);
        setName("");
      }
      else {
        await api.put(`/datasheet/${item.id}`,
          { name: name, status: status });
        setMessage(intl.get("datasheet.sucess-upd-message"));
        onRequestClose(true);
        setName("");
      }
    }
    catch (error) {
      if (error.response.status >= 300) {
        console.log(error.response.data.detail[0].msg.toUpperCase());
        setMessage(error.response.data.detail[0].msg.toUpperCase());
        onRequestClose(false);
        setName("");
      }
    }
  };

  const onCancelar = () => {
    setName("");
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClick={onClick}
      onRequestClose={closeModal}
      shouldFocusAfterRender={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content">
      <Container>
        <HeaderModal>
          <Title>{title}</Title>
          <IoCloseSharp
            style={{ cursor: 'pointer', marginRight: 7 }}
            size={18}
            onClick={closeModal} />
        </HeaderModal>
        <ModalContent>
          <TextInput
            value={name}
            onChange={(e) => setName(e.target.value)}
          >
            <Title>{intl.get("report.add-DS-Name")}</Title>
          </TextInput>
          <SelectInput
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <Title>Status</Title>
          </SelectInput>
          <Wrapper>
            <ButtonActions
              bgColor={'#104068'}
              text={intl.get("repository.save")}
              onClick={handleNewDS}
            />
            <ButtonActions
              bgColor={'#ed6663'}
              text={intl.get("repository.cancel")}
              onClick={onCancelar}
            />
          </Wrapper>
        </ModalContent>
      </Container>
    </Modal>
  )
}
