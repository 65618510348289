import React from 'react';
import {ButtonContainer}from './styles';

const ButtonActions = ({text, onClick, execButton, bgColor, color, style, children, disabled }) => {
  return (
    <ButtonContainer 
    onClick={onClick} 
    bgColor={bgColor} 
    color ={color}
    style={style}
    execButton={execButton}
    disabled={disabled}>
      {[text, children]}
    </ButtonContainer>
  )
}

export default ButtonActions