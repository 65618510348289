import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 89vh;

    padding: 1% 7%;
`

export const Content = styled.div`
    width: 100%;

    background-color: white;
    border-radius: 5px;
    box-shadow: 0 1px 2px #0003;
`

export const Title = styled.h2`
 color: black;
 font-size: 3.5vh;
 letter-spacing: .5px;
 margin-bottom: 10px;
 display: flex;
 position: relative;
`