import styled from 'styled-components';

export const CardChart = styled.div`

  width: 100%;
  background-color: #fafafa;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  max-width: 165px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 2500px) {
    height: ${props => props.column ? '33vh' : '33vh'};
    max-width: ${props => props.column ? '15vw' : '15vw'};
    margin: 30px 0;
  };
`

export const ChartContainer = styled.div`

  position: relative;
  left: ${props => props.column ? '1.2vw' : '2.9vw'};
  top: 2vh;
`

export const TitleChart = styled.div`

 width: 100%;
 background-color: ${(props) => props.bgColor};
 color: white;
 font-weight: 600;
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding: 10px;
 border-top-left-radius: 8px;
 border-top-right-radius: 8px;
`

export const CardContainer = styled.div`

  display: flex;
  flex-direction: row;
`
export const TypeLength = styled.div`

@media screen and (max-width: 2500px) {
    font-size: 2.2vh;
};

`

export const Title = styled.div`

@media screen and (max-width: 2500px) {
    font-size: 2.2vh;
};
`
