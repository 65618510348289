import React, { useEffect, useState } from "react";
import intl from "react-intl-universal";
import AppRoutes from "./routes/app.routes";
import "react-datepicker/dist/react-datepicker.css";

const locales = {
  "pt-BR": require("./locales/pt-BR.json"),
  "en-US": require("./locales/en-US.json"),
};

function App() {

  const [loadApp, setLoadApp] = useState(false);

  useEffect(() => {
    let currentLocale;

    const langStorage = localStorage.getItem('lang');
    if (!langStorage) {
      localStorage.setItem('lang', 'pt-BR');
      currentLocale = 'pt-BR';
    } else {
      currentLocale = langStorage;
    }

    intl.init({
      currentLocale,
      locales,
      fallbackLocale: 'pt-BR',
    }).then(() => {
      setLoadApp(true);
    });

  }, []);

  return loadApp && <AppRoutes/>
}

export default App;
