import styled from 'styled-components';

export const DeepInfo = styled.div`

  display: ${props => props.column ? 'grid' : 'flex'};
  width: 85%;
  flex-direction: row;
  justify-content: space-between;
  gap: 45px;
  flex-wrap: wrap;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 10px;

`

export const ContainerInfo = styled.div`

  font-size: 12px;
  span {
    font-weight: 600;
  }

  @media screen and (max-width: 2500px) {
    font-size: 1.7vh;
  }
`
export const InfoWrapper = styled.div`

  background-color: #fafafa;
  margin: 30px 0px 15px;
  padding: 15px;
`

export const TitleProject = styled.h4`

  font-weight: 600;
  word-break: break-word;
  width: 500px;

  @media screen and (max-width: 2500px) {
    font-size: 2.5vh;
  }
`

export const ContainerWrapper = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 0;

`
