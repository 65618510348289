import React from "react";

export class Timer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            actualTime: 0,
            minutes: 0,
            hours: 0
        }

        this.counter = null
        this.initTimer = this.initTimer.bind(this)
        this.clearTimer = this.clearTimer.bind(this)
        this.pauseTimer = this.pauseTimer.bind(this)
    }

    initTimer() {
        if (this.counter) {
            this.pauseTimer();
        } else {
            this.counter = setInterval(() => {
            if (this.state.actualTime >= 59 && this.state.actualTime < 60) {
                if (this.state.minutes === 59) {
                    this.setState({
                        actualTime: 0,
                        minutes: 0,
                        hours: this.state.hours + 1
                    })
                }
                this.setState({ 
                    actualTime: 0,
                    minutes: this.state.minutes + 1
                });
            } else {
                this.setState({ actualTime: this.state.actualTime + 0.1 });
            }
            }, 100);
        }
    }

    pauseTimer() {
        clearInterval(this.counter);
        this.counter = null;
    }

    clearTimer() {
        this.setState({ actualTime: 0, minutes: 0 });
        clearInterval(this.counter);
        this.counter = null;
    }

    render() {
        return (
           <p>{this.state.hours}:{this.state.minutes}:{this.state.actualTime.toFixed(2)}</p> 
        )
    }
}
