import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { ButtonContainer, Container, HeroContainer, Print, Title, Wrapper } from './styles'
import ButtonActions from '../../../components/Button/ButtonActions';
import intl from 'react-intl-universal';
import { api } from "../../../api";
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import Charts from './../../../components/Charts';

export default function Overview() {

  const { id } = useParams();
  const [showReexecution, setShowReexecution] = useState(false);
  const [reportInfo, setReportInfo] = useState([]);
  const [reportFields, setReportFields] = useState([]);
  const [reportTitleToPDF, setReportTitleToPDF] = useState(null);
  const [titlePDF, setTitlePDF] = useState(null);

  const componentRef = useRef(null);

  const fetchReportInfo = async () => {
    try {
      const result = await api.get(`/report/${id}/info`);
      setReportInfo([result.data]);
      setTitlePDF(result.data.name)
      setReportTitleToPDF(`${result.data.name}-${moment(result.data.start_date).format('DD/MM/YYYY - HH:mm')}`);
      setShowReexecution(result.data.created_user);
    } catch (err) {
      console.log(`/report/${id}/info ERROR::`, err.message);
    }
  }

  const fetchReportFields = async () => {
    try {
      const result = await api.get(`/report/${id}/fields`);
      setReportFields(result.data);
    } catch (err) {
      console.log(`/report/${id}/fields ERROR::`, err.message);
    }
  };

  useEffect(() => {
    fetchReportFields();
    fetchReportInfo();
  }, [id]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: reportTitleToPDF
  });

  return (
    <Container>
      <Wrapper>
        <HeroContainer>
          <Title children={intl.get('report.report')} />
          <ButtonContainer>
            {
              showReexecution &&
              <Link to={`/projects/execute-project/${id}/reexec`}>
                <ButtonActions
                  bgColor={'#3d85ad'}
                  children={intl.get('report.reexecute')} />
              </Link>
            }
            <ButtonActions
              bgColor={'#0f3c65'}
              disabled={!reportTitleToPDF}
              onClick={handlePrint}
              children={intl.get('report.export_pdf')} />
          </ButtonContainer>
        </HeroContainer>
        <Print ref={componentRef}>
          <Charts
            id={id}
            reportInfo={reportInfo}
            reportFields={reportFields}
            title={titlePDF}
          />
        </Print>
      </Wrapper>
    </Container >
  )
}
