import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import logoutIcon from "./../../icons/icon-logout.svg";
import brazilFlag from "../../assets/brazil-flag.png";
import usaFlag from "../../assets/usa-flag.png";
import { SidebarData } from "./SidebarData";
import "./styles.css";
import {
  PtBrButton,
  EnButton,
  ButtonsWrapper,
  Date_Time,
  Container,
} from "./styles";
import { IoCloseSharp } from "react-icons/io5";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { ptBR } from "date-fns/locale";
import { useAuth } from "../../hooks/useAuth";

registerLocale("pt-BR", ptBR);

function Navbar({ isUser }) {
  const currentDirectory = useLocation();

  const [selectedPage, setSelectedPage] = useState(currentDirectory.pathname);
  const [ptBrButtonDisabled, setPtBrButtonDisabled] = useState(false);
  const [enButtonDisabled, setEnButtonDisabled] = useState(false);
  const { handleLogout, sidebar, setSidebar } = useAuth();

  const handleChangeLanguage = (language) => {
    localStorage.setItem("lang", language);
    window.location.reload();
  };

  useEffect(() => {
    setSelectedPage(currentDirectory.pathname);

    if (localStorage.getItem("lang") === "pt-BR") {
      setPtBrButtonDisabled(true);
      setEnButtonDisabled(false);
      setDefaultLocale("pt-BR");
    }
    if (localStorage.getItem("lang") === "en-US") {
      setEnButtonDisabled(true);
      setPtBrButtonDisabled(false);
      setDefaultLocale("en");
    }
  });

  var weekInPortuguese = [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado",
  ];

  var weekInEnglish = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  var date = new Date();
  var todayInPortuguese = weekInPortuguese[date.getDay()];
  var todayInEnglish = weekInEnglish[date.getDay()];
  var time = date.toLocaleTimeString();
  var hour = Object.freeze(time);

  useEffect(() => {
    if (selectedPage) setSidebar(false);
  }, [selectedPage]);

  return (
    <Container showNav={isUser}>
      <IconContext.Provider value={{ color: "white" }}>
        <div className="navbar" onClick={() => setSidebar(false)}>
          <div className="navbar-title">
            <p>Dagobah</p>
          </div>
          <div className="navbar-gertecLogo">
            <img
              src={require("../../icons/gertec.svg").default}
              alt="Logo Gertec"
            />
          </div>
          <div className="navbar-date-logouticon">
            <ButtonsWrapper>
              <EnButton
                type="button"
                onClick={() => handleChangeLanguage("en-US")}
                disabled={enButtonDisabled}
              >
                <img
                  src={usaFlag}
                  alt="Mudar para Inglês"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                  }}
                />
              </EnButton>
              <PtBrButton
                type="button"
                onClick={() => handleChangeLanguage("pt-BR")}
                disabled={ptBrButtonDisabled}
              >
                <img
                  src={brazilFlag}
                  alt="Mudar para Português"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                  }}
                />
              </PtBrButton>
            </ButtonsWrapper>
            <Date_Time>
              {localStorage.getItem("lang") === "pt-BR"
                ? `${todayInPortuguese}, ${date.toLocaleDateString()} | ${hour}`
                : `${todayInEnglish}, ${date.toLocaleDateString(
                    "en-US"
                  )} | ${hour}`}
            </Date_Time>
            <img src={logoutIcon} alt="Log out" onClick={handleLogout} />
          </div>
        </div>

        <nav className={sidebar ? "nav-menu-active" : "nav-menu"}>
          <ul className="nav-menu-items">
            {SidebarData.map((item, index) => {
              if (item.path === selectedPage) {
                return (
                  <Link
                    to={item.path}
                    onClick={() => (
                      setSelectedPage(item.path), setSidebar(false)
                    )}
                    style={{ textDecoration: "none" }}
                    className={item.cName}
                    key={item.path}
                  >
                    <IconContext.Provider value={{ color: "#FCBB59" }}>
                      <div>{item.icon}</div>
                    </IconContext.Provider>
                    <p style={{ color: "#FCBB59" }}>{item.title}</p>
                  </Link>
                );
              }
              return item.id === 9 ? null : item.id === 1 ? (
                <Link
                  to={item.path}
                  onClick={() => setSidebar(!sidebar)}
                  style={{ textDecoration: "none" }}
                  className={item.cName}
                  key={item.path}
                >
                  <div>
                    {!sidebar ? (
                      item.icon
                    ) : (
                      <IoCloseSharp
                        size={23}
                        style={{ position: "relative", right: 4 }}
                      />
                    )}
                  </div>
                  <p>{item.title}</p>
                </Link>
              ) : (
                <Link
                  to={item.path}
                  onClick={() => (
                    setSelectedPage(item.path), setSidebar(false)
                  )}
                  style={{ textDecoration: "none" }}
                  className={item.cName}
                  key={item.path}
                >
                  <div>{item.icon}</div>
                  <p>{item.title}</p>
                </Link>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </Container>
  );
}

export default Navbar;
