import styled from 'styled-components';

export const Container = styled.div`


  margin: 0;
  padding: 0;
  background-color: #fafafa;
`

export const Wrapper = styled.div`
 padding: 1% 7.2%;
`

export const RecentFiles = styled.div`
  
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    ;
  }
`
export const ContainerFile = styled.div`


  gap: 15px;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  box-shadow: 0 1px 2px #0003;
  background-color: #fff;
  max-width: 430px;
  padding: 20px;
  border-radius: 3px;
`

export const Title = styled.h2`

 font-size: 3.5vh;
 color: black;
 margin-bottom: 1%;
`

export const TitleContainer = styled.div`

 display: flex;
 flex-direction: row;
 justify-content: space-between;
 margin: 20px 0px;
 align-items: center;
`