import styled from 'styled-components';
import { BsArrowLeft } from "react-icons/bs";
import { useMediaQuery } from 'react-haiku';


export const Container = styled.form`

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 3.8vh;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${(props) => props.bgColor};
  border: 1px solid ${(props) => props.borderColor};
  line-height: normal;
  font-size: 14px;
  padding-left: 5px;
  min-width: 90px;

  @media screen and (max-width: 1600px) {
    width: 97.5%;
    height: 4.9vh;
  }
`

export const Input = styled.input`
 
  outline: none;
  border-color: transparent;
  align-content: center;
  font-size: 11px;
  width: 100%;
  background-color: ${(props) => props.bgInput};
`

export const Icon = styled(BsArrowLeft)`

 color: ${(props) => props.color};
 font-size: 12px;

`

export const IconView = styled.button`
 
 background-color: ${(props) => props.bgIcon};
 height: 3.6vh;
 width: 32px;
 display: flex;
 align-items: center;
 justify-content: center;
 cursor: pointer;
 border: transparent;

@media screen and (max-width: 1600px) {
  height: 4.6vh;
}
`