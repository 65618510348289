import styled from "styled-components";

export const Container = styled.div`

 width: 98%;
 margin:10px 10px;
 border-radius: 4px;
 background-color: white;
 border: 1px solid #E6E6E6;
 font-size: 10px;
 padding: 7px;
 display: flex;
 justify-content: center;

 @media screen and (max-width: 2500px) {
    font-size: .8vw;
 }
`