import styled from 'styled-components';

export const Container = styled.form`
    
    width: 100%;
    min-width: 700px;
    height: 91vh;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 2px;
    
    p {
      font-size: 10px;

      font-weight: 500;
    }
`

export const Wrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 20px;
    margin: 30px 0;
`

export const HeaderModal = styled.div`

 display: flex;
 flex-direction: row;
 
 align-items: center;
 justify-content: space-between;
 padding: 15px 20px;

 box-shadow: 1px 1px 1px #00000029;

`

export const ModalContent = styled.div`
    width: 100%;
    padding: 0 40px;

    margin-top: 20px;
`

export const Title = styled.h4`
   font-weight: 500;
`

export const SelectContainer = styled.div`
    display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 63.7px;
  width: 100%;

  margin-top: 10px;
`

export const SelectTitle = styled.h4`
 font-size: 1.7vh;
 color: black;
`

export const SelectInputContainer = styled.div`
    display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  min-width: 220px;
  background-color: #fafafa;
  border: none;
  line-height: normal;
  padding-left: 10px;
  box-shadow: 0px 1.4px #e2e2e2;

  &:hover {
    box-shadow: 0px 1.4px #777777;
    transition: 0.25s ease-in-out;
  }

  @media screen and (max-width: 1300px) {
    width: 100%;
  }

`

export const Select = styled.select`
    outline: none;
  border-color: transparent;
  align-content: center;
  font-size: 11.2px;
  width: 100%;
  background-color: #fafafa;
`

export const AttachmentsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
`

export const AttachmentsTitle = styled.h4`

 font-size: 1.7vh;
 color: black;

`

export const AttachmentsItemsContainer = styled.div`
    display: grid;

    width: 100%;
    height: 150px;
    overflow-x: hidden;

    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: "20px 20px";
    grid-gap: 10px;

    overflow-y: auto;

    margin-bottom: 10px;

    padding: 10px 5px;
    
`

export const TitleDiv = styled.div`

  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

`

export const FileContainer = styled.div`

  display: flex;
  flex-direction: column;
`