import React from 'react'
import { Container, Input, InputContainer, Title } from './styles';

const TextAreaInput = ({ type, placeholder, value, onChange, children, defaultValue, key, styles }) => {

  return (
    <Container
      key={key}
      style={styles}>
      <Title>{children}</Title>
      <InputContainer>
        <Input
          value={value}
          id={key}
          onChange={onChange}
          type={type}
          placeholder={placeholder}
          defaultValue={defaultValue}
        />
      </InputContainer>
    </Container>
  )
}

export default TextAreaInput