import styled, { keyframes } from 'styled-components';

export const Container = styled.div`

 margin: 20px 0;
 padding: 20px; 
 background: #FFFFFF 0% 0% no-repeat padding-box;
 box-shadow: 0px 1px 3px #00000029;
 border-radius: 5px;
 min-width: ${props => props.column && '45vw'};
`
export const ChartWrapper = styled.div`

 display: flex; 
 flex-direction: ${props => props.column ? 'column' : 'row'};
 width: 100%;
 align-items: center;


`

export const InfoWrapper = styled.div`

  background-color: #fafafa;
  margin: 30px 0px 15px;
  padding: 15px;
`

export const TitleProject = styled.h4`

  font-weight: 600;
`

export const ContainerWrapper = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 0;

`

export const DeepInfo = styled.div`

  display: flex;
  width: 85.5%;
  flex-direction: row;
  justify-content: space-between;

`

export const ContainerInfo = styled.div`

  font-size: 12px;
  margin-right: 20px;

  span {
    font-weight: 600;
  }
`

export const Title = styled.h2`


 color: black;
 font-size: 2.5vh;
 letter-spacing: .5px;
 
`

export const CardChart = styled.div`

  width: 100%;
  background-color: #fafafa;
  max-width: 195px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  height: 250px;
 
`

export const ChartContainer = styled.div`

  position: relative;
  left: 29px;
  top: 24px;

`

export const TitleChart = styled.div`

 width: 100%;
 background-color: ${(props) => props.bgColor};
 color: white;
 font-weight: 600;
 display: flex;
 align-items: center;
 padding: 10px;
 border-top-left-radius: 8px;
 border-top-right-radius: 8px;
`

export const CardContainer = styled.div`

  display: ${props => props.column ? 'grid' : 'flex'};
  flex-direction: ${props => props.column ? 'column' : 'row'};
  margin-top: 20px;
  flex-wrap: ${props => props.column && 'grid'};
  grid-column-gap: ${props => props.column && '50px'};
  grid-row-gap: ${props => props.column && '50px'};
  grid-template-columns: ${props => props.column && 'repeat(2, 1fr)'};
  @media screen and (max-width: 2500px) {
    flex-wrap: wrap;
    grid-column-gap: 50px; 
  }

  @media screen and (max-width: 1325px) {
    grid-column-gap: 20px;  
  }
`

export const InfoContainer = styled.div`


  display: flex;
  flex-direction: ${props => props.row ? 'row' : 'column'};
  gap: 15px;
  align-items: center;
  margin-top: ${props => props.row ? '30px' : '0px'};
  margin-left: 35px;


`
export const InfoCard = styled.div`

  display: flex;
  flex-direction: row;
  width: 130%;

  @media screen and (max-width: 1200px) {
    width: 130px;
 }
 
  
`
export const Square = styled.div`

  height: 20px;
  width: 20px;
  margin-bottom: 15px;
  background-color: ${(props) => props.bgColor};
  border-radius: 3px;

  
`
export const InfoText = styled.p`

 font-weight: 400;
 font-size: 13px; 
 margin-left: 10px;
 align-items: start;
`

export const DatasheetWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1px;
`

export const DatasheetItem = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  font-size: 11.5px;
`

export const DatasheetHeader = styled.div`
  display: flex;
  width: 160px;
  height: 100%;
  background-color: #e6e6e6;
  align-items: center;
  justify-content: start;
  font-weight: 600;
  

  P {
    display:inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15ch;
  }

  @media screen and (max-width: 2500px) {
    font-size: .7vw;
  }
`

export const DatasheetContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: white;
  align-items: center;
  border: 0.1px solid;
  border-color: #e6e6e6;

  p {
    margin-left: 12px;
    display:inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 55ch;
  }

  @media screen and (max-width: 2500px) {
    font-size: .7vw;
  }
`

export const DatasheetHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5vh;
`;

export const PDFTitle = styled.span`
font-size: 2.5vw;
font-weight: 600;
display: flex;
align-items: center;
justify-content: center;
padding-top: 2vh;

@media screen and (max-width: 4000px) {
  display: none;
}
`

export const IconView = styled.div`
 display: flex;
 transition: 0.3s ease-out;
  border-radius: 50%;
  padding: 2px;
  align-items: center;
 &:hover {
  background-color: #e2e2e2;
  transition: 0.3s ease-in;
 }
 
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LoadingScreen = styled.div`

height: 40vh;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
`

export const Spinner = styled.div`
	margin: 16px;
	animation: ${rotate360} 1s linear infinite;
	transform: translateZ(0);
	border-top: 2px solid grey;
	border-right: 2px solid grey;
	border-bottom: 2px solid grey;
	border-left: 4px solid black;
	background: transparent;
	width: 80px;
	height: 80px;
	border-radius: 50%;
`;