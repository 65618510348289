import React, { useEffect, useMemo, useState } from 'react'
import Modal from 'react-modal';
import { Container, HeaderModal, ModalContent, Title, Wrapper } from './styles';
import { IoCloseSharp } from "react-icons/io5";
import intl from "react-intl-universal"
import ButtonActions from '../../Button/ButtonActions';
import { PlainJsonEditor } from 'react-plain-json-editor'
import Success from '../Success';
import FailModal from './../Fail';
import ErrorLog from '../ModalSync/ErrorLog';

export default function ModalParams({ isOpen, onRequestClose, content, update, steps, flags }) {

  const [value, setValue] = useState(null);
  const [result, setResult] = useState(null);
  const [editSuccess, setEditSuccess] = useState(false);
  const [openModalFail, setFailModal] = useState(false);
  const [disableUpd, setDisableUpd] = useState(false);
  const [errorValue, setErrValue] = useState(null);
  const [successValue, setSuccessValue] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [openModalFlag, setOpenModalFlag] = useState(false);
  const [openUpdFlag, setOpenUpdFlag] = useState(false);
  const [stepCount, setStepCount] = useState(0)

  useEffect(() => {
    if (result) {
      setValue(result)
    }
    setDisableSave(true)
    setValue(content)
  }, [content]);

  useEffect(() => {
    if (value !== null) {
      setDisableUpd(false);
      setOpenModalFlag(false)
    } else {
      setDisableUpd(true);
    }
  }, [value]);

  useMemo(() => {
    setStepCount(steps)
  }, [value, result, steps, content])

  function openEditSuccess() {
    setEditSuccess(true)
  };

  function closeEditSuccess(e) {
    onRequestClose(e);
    setEditSuccess(false);
    if (result) {
      setSuccessValue(true);
    } else {
      setSuccessValue(false);
    }
  };

  function openFailModal() {
    setFailModal(true);
  };

  function closeFailModal(e) {
    e.preventDefault();
    setFailModal(false);
    setErrValue(errorValue)
  };

  function openModalFlags() {
    setOpenModalFlag(true);
    setOpenUpdFlag(false)
  };

  function closeModalFlags() {
    setOpenModalFlag(false);
  };

  function openUpdFlags() {
    if(successValue) {
      setOpenUpdFlag(true);
    }
    setOpenModalFlag(false);
  };

  function closeUpdFlags(e) {
    e.preventDefault();
    setOpenUpdFlag(false)
  };

  const handleChange = (e) => {
    setDisableSave(false);
    setValue(e);
  };

  useEffect(() => {
    if (result) {
      if (steps === 0) {
        openModalFlags();
      } else {
        openUpdFlags();
      }
    }
  }, [result]);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      if (!disableUpd) {
        // flags()
        update(value);
        setResult(value);
        openEditSuccess();
        setDisableUpd(false);
        setSuccessValue(true);
      } else {
        openFailModal();
        closeEditSuccess();
        cancelUpdate();
        setDisableUpd(true);
      }
    } catch (err) {
      setErrValue(err)
      setDisableUpd(true);
    }
  };

  const cancelUpdate = (e) => {
    onRequestClose(e);
    setDisableSave(false);
    closeModalFlags()
    if (!successValue) {
      setResult(content);
      setValue(content)
      setDisableSave(true)
    }
  };

  const errorHandler = () => {
    setDisableUpd(true);
    setDisableSave(true);
  };

  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={115}
      onRequestClose={onRequestClose}
      shouldFocusAfterRender={false}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      error={disableUpd}
      overlayClassName="react-modal-overlay"
      className="react-modal-content">
      <Container>
        <HeaderModal>
          <Title>{intl.get("repository.custom-params")}</Title>
          <IoCloseSharp
            style={{
              cursor: 'pointer',
              marginRight: 7
            }}
            size={18}
            onClick={cancelUpdate} />
        </HeaderModal>
        <ModalContent>
          <PlainJsonEditor
            value={value}
            showInnerError={true}
            onChange={handleChange}
            onSerializeError={errorHandler}
            styles={{
              root: {
                width: 400,
                height: 350
              },
              textarea: {
                width: 520,
                resize: 'none'
              },
              error: {
                width: 210,
                marginLeft: 135,
              }
            }}
          />
          <Wrapper>
            <ButtonActions
              text={intl.get("repository.save")}
              onClick={handleSubmit}
              disabled={disableSave}
              bgColor={!disableSave ? '#104068' : '#7e7e7e'}
            />
            <ButtonActions
              bgColor={'#ed6663'}
              text={intl.get("repository.cancel")}
              onClick={cancelUpdate}
            />
          </Wrapper>
        </ModalContent>
      </Container>
      <Success
        isOpen={editSuccess}
        onRequestClose={closeEditSuccess}
        title={'Configuração de Pârametro'}
        message={'Parâmetros customizados com sucesso!'} />
      <FailModal
        isOpen={openModalFail}
        onRequestClose={closeFailModal}
        title={'Falha na operação!'}
        message={'Parâmetros inexistentes para configuração.'}
      />
      <ErrorLog
        //flags validation
        title1={'Validar Flags'}
        isOpen={openModalFlag}
        onRequestClose={closeModalFlags}
        title={'Flags diferentes'}
        message={'As flags dos arquivos de features/scenarios não estão de acordo com as flags do arquivo de configuração de parâmetros.'} />
      <ErrorLog
        //flags update
        hidden
        title1={'Atualizar Flags'}
        isOpen={openUpdFlag}
        onRequestClose={closeUpdFlags}
        title={'Atualização de Flags'}
        message={'Existem Features/Scenários que não estão de acordo com as flags do arquivo de parâmetro. A estrutura do projeto será atualizada.'} />
    </Modal>
  )
}

