import React from 'react';

import { useForm } from 'react-hook-form';
import { api } from '../../api';

import { AccessButton, Container, EmailMessage, Form, InputContainer, InputTitle, LoginSide, StyledInput, StyledInputContainer, WelcomeText } from './styles';
import loginImage from '../../assets/loginImage.svg';
import toast, { Toaster } from 'react-hot-toast';

function PasswordRecovery() {

  const { handleSubmit, register } = useForm();

  const onSubmit = async (data, e) => (
    e.preventDefault(),

    await api.post('auth/password/reset', { "email": data['email'] })
      .then(() => {
        toast.success('Recovery email sent successfully');
        setTimeout(() => {
          window.location = '/reset_password';
        }, 1500);
      })
      .catch(() => {
        toast.error('Failed to send recovery email!');
      })
  )

  return (
    <Container id='container'>
      <Toaster position="top-right"
        toastOptions={{
          style: {
            marginTop: '2%'
          }
        }}
      />
      <LoginSide id='loginSide'>
        <Form
          id='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div
            id="titleAndConfirmContainer"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px'
            }}
          >
            <WelcomeText id='welcomeText'>
              RECUPERAR SENHA
            </WelcomeText>

            <EmailMessage>
              Informe o e-mail da conta cadastrada que você está tentando acessar para iniciar o processo de recuperação de senha.
            </EmailMessage>
          </div>

          <div id='loginActionsContainer'>
          <InputContainer style={{ marginBottom: "2vh" }}>
              <InputTitle>
                E-mail
              </InputTitle>
              <StyledInputContainer>
                <StyledInput
                  type="text"
                  style={{ paddingRight: '9%' }}
                  {...register("email")}
                />
              </StyledInputContainer>
            </InputContainer>
          </div>

          <AccessButton
            id="accessButton"
            type="submit"
            value="Acessar"
          />
        </Form>
      </LoginSide>
      <img
        src={loginImage}
        style={{
          width: '50%',
          height: '100%'
        }}
      />
    </Container>
  );
}

export default PasswordRecovery;