import React from 'react'
import { PieChart } from 'react-minimal-pie-chart';
import { CardChart, CardContainer, ChartContainer, Title, TitleChart, TypeLength } from './styles';

export default function DonutCards({ data, bgColor, title, type, length, column }) {

    return (
        <CardContainer type={type}>
            <CardChart column={column}>
                <TitleChart
                    bgColor={bgColor}>
                    <Title children={title}/>
                    <TypeLength children={length}/>
                </TitleChart>
                <ChartContainer column={column}>
                    <PieChart
                        animate
                        animationDuration={800}
                        data={data}
                        totalValue={100}
                        startAngle={0}
                        viewBoxSize={column ? [120, 120] : [150, 150] }
                        segmentsStyle={{ transition: "stroke .2s", cursor: "pointer" }}
                        lineWidth={65}
                        labelPosition={70}
                        label={({ y, dx, dy, dataEntry, index }) => (
                            <text
                                key={index}
                                x={42}
                                stroke="none"
                                y={y}
                                dx={dx}
                                dy={dy}
                                dominantBaseline="central"
                                textAnchor="right"
                                style={{
                                    fontSize: 9,
                                    fontFamily: 'sans-serif',
                                    justifyContent: 'space-between',
                                    fill: 'white',
                                    fontWeight: 600
                                }}>
                                {Math.round(dataEntry.percentage) + '%'}
                            </text>
                        )}
                    />
                </ChartContainer>
            </CardChart>
        </CardContainer>
    )
}
