import { AiOutlineEdit } from 'react-icons/ai';
import { IoCloseSharp } from 'react-icons/io5';
import styled, { keyframes } from 'styled-components';
import ProgressBar from '@ramonak/react-progress-bar';

export const Container = styled.div`
  margin: 0;
  padding: 0;
  background-color: #fafafa;
`
export const Wrapper = styled.div`

 padding: 1% 1% 0 4.5%;
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: space-between;

 @media screen and (max-width: 1700px) {
   padding-left: 5%;
  }

 @media screen and (max-width: 1400px) {
   padding-left: 6.5%;
  }
`

export const Left = styled.div`
  display: flex;

  width: 65%;
  height: 100%;

  margin: 0% 1% 0% 0%;

  gap: 10px;
  
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
`;

export const Right = styled.div`
  display: flex;

  width: 35%;
  height: 100%;
  flex-direction: column;

  padding: 10px;
  border-radius: 3px;
  gap: 15px;

  box-shadow: 0 1px 2px #0003;
  background-color: #fff;
`;

export const Both = styled.div`

  display: flex; 
  width: 100%;
  flex-direction: row;


`

export const ContainerFile = styled.div`
  display:  flex;
  width: 65.5%;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border-radius: 3px;
  gap: 15px;
  box-shadow: 0 1px 2px #0003;
  background-color: #fff;

  @media screen and (max-width: 1400px){
    height: 115px
  }
  @media screen and (max-width: 1175px) {
    width: 100%;
  }
`

export const TreeContainer = styled.div`
  display:flex;

  width: 100%;
  height: 85%;
  max-height: 400px;
  flex-direction: column;
  box-shadow: 0 1px 2px #0003;
  background-color: #fff;
  border-radius: 3px;

  @media screen and (max-width: 2560px) {
    max-height: 700px;
  }
`

export const Title = styled.h1`


 color: black;
 margin: 0 0 12px 5px;

 @media screen and (max-width: 1400px) {
  font-size: 25px;
 }
`

export const TitleStructure = styled.h3`


 padding: 10px;
`


export const SubTitle = styled.h5`

 font-size: 17px;
 color: black;
 margin: 0;
 padding:0;
`

export const ContainerSelectParams = styled.div`
  display: flex;
  width: 100%;

  align-items: center;

  gap: 10px;

  > svg {
    width: 25px;
    height: 25px;

    cursor: pointer;
  }
`

export const InputSelectParams = styled.select`
  width: 85%;
  height: 100%;
  outline: none;
  font-size: 1.3vh;
  min-height: 32px;
  background-color: #fafafa;
  border: none;
  line-height: normal;
  padding-left: 10px;
  box-shadow: 0px 1.4px #e2e2e2;

  option {
    height: 200px;
    margin: 20px;
    padding: 20px;
}

@media screen and (max-width: 1600px) {
  font-size: 1.8vh;
}

@media screen and (max-width: 1280px) {
  max-width: 550px;
  width: 100%;
 }
`

export const ContainerButtons = styled.div`
  display:flex;
  gap: 10px;
`

export const ContainerStatus = styled.div`
  display:flex;
  width: 100%;
  align-items: center;
  gap: 7px;
  margin-top: 10px;
`
export const Status = styled.p`
  display: flex;
  font-size: 10px;

  gap: 7px;
`

export const HeaderFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  margin: 0;
  padding: 15px 20px;
  height: 50px;
  background-color: #eaf2f7;
`
export const TitleContainer = styled.div`

 margin: 1% 6%;
 padding: 0 2%;
`
export const BoxContainer = styled.div`
  display: flex;
 flex-direction: row;
 justify-content: space-between;
 
 @media screen and (max-width: 1175px) {
  flex-direction: column;
  gap: 10px
  }

`

export const RadioContainer = styled.div`

 display: flex;
 width: 100%;
 gap: 12px;
 padding: 10px 0 0 10px;
 font-size: 12px;
 font-weight: 500;
`

export const RadioInput = styled.div`
  gap: 10px;
`

export const RadioLabel = styled.label`
  margin-left: 5px;
`

export const RadioLine = styled.hr`
  margin: 10px 10px 0 30px;
  border: 0;
  border-top: 1px solid #CCC;
`
export const InputNumberContainer = styled.input`
  height: 3.6vh;
  min-width: 6vw;
  padding: 10px;

  outline: none;
  border-color: transparent;

  box-shadow: 0px 1.4px #e2e2e2;
  @media screen and (max-width: 1600px) {
    height: 4.5vh;
  }
`

export const InputContainer = styled.div`


 display: flex;
 flex-direction: row;
 gap: 4px;

`
export const DetailDiv = styled.div`

  display: flex;
  flex-direction: row;
  font-size: 11px;
  gap: 10px;
  color: #315891;

`
export const CardContainer = styled.div`
  
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 5px;
`
export const TextInfo = styled.div``

export const LogContainer = styled.div`

 background-color: #fafafa;
 height: 75%;
 margin: 10px 3px 2px;
 box-shadow: 0 1px 2px #0003;
 display: inline-block;
 
`

export const Response = styled.div`
 width: fit-content;
 padding: 3px;
 height: 370px;

 @media screen and (max-width: 2560px) {
  height: 600px;
  }

  @media screen and (max-width: 1600px) {
    height: 310px;
  }
`

export const ActionContainer = styled.div`

 margin-left: 5px;
 display: flex;
 flex-direction: row;
 width: 100%;
 gap: 12px;

 /* @media screen and (max-width: 1600px) {
  flex-direction: column;
 } */

`

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Progress = styled(ProgressBar)`
@media screen and (max-width: 1600px) {
    width: 190px;
  }
`

export const BarContainer = styled.div`

  border: 2px solid #408BB4;
  border-radius: 20px;
  width: 254px;
  height: 25px;
  
  box-shadow: 1px 1px 3px #0003;

  @media screen and (max-width: 1600px) {
    width: 194px;
  }
`

export const Info = styled.div`

  color: #315891;
  font-weight: 600;
  text-align: center;

  @media screen and (max-width: 1400px) {
    font-size: 14px;
  }

  @media screen and (max-width: 1220px) {
    margin-bottom: 10px;
  }

`

export const CloseIcon = styled(IoCloseSharp)`

 color:#3b9ccf;
 cursor: pointer;

 ${props => props.disabled ? '' : '&:hover { background-color: white; padding: 1.5px; transition: 0.4s ease-in-out; border-radius: 5px; box-shadow: 0 1px 2.8px #0003;}'}

        
`

export const UpdIcon = styled(AiOutlineEdit)`

 color:#64c6c4;
 cursor: pointer;

${(props) => props.disabled ? '' : '&:hover { background-color: white; padding: 1.5px; transition: 0.4s ease-in-out; border-radius: 5px; box-shadow: 0 1px 2.8px #0003;}'}

        
`

export const UpdateParam = styled.div`

 font-size: 10px;
 color: green;
 display: flex;
 justify-content: flex-start;
 position: relative;
 bottom: 2px;
 height: 20px;
`

export const TestResultItem = styled.p`

  display: block;
  position: relative;
  width: 100%;
`
export const DetailProgress = styled.div`


  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  @media screen and (max-width: 1175px) {
    flex-direction: column;
    position: relative;
    top: 15px
  }

`

export const StatusDetail = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;

  @media screen and (max-width: 1220px) {
    margin: 15px 0 0 0;
    flex-direction: column;
  }

  * {
    width: 50%;
  }
 
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
	margin: 16px;
	animation: ${rotate360} 1s linear infinite;
	transform: translateZ(0);
	border-top: 2px solid grey;
	border-right: 2px solid grey;
	border-bottom: 2px solid grey;
	border-left: 4px solid black;
	background: transparent;
	width: 80px;
	height: 80px;
	border-radius: 50%;
`;

export const EditStatus = styled.p`
  display: flex;
  font-size: 1.5vh;
  margin: .4vh 0;
  gap: 7px;

  @media screen and (min-width: 1600px) {
    font-size: 1.25vh;
  }
`;

export const ContainerUpdate = styled.div`
  display:flex;
  width: 100%;
  align-items: center;
  gap: 7px;
`

export const ResultReportLabel = styled.div`
  color: #fff;
  height: 30px;
  width: 140px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2vh 0;
  font-weight: 500;
  font-size: 12px;
  margin-left: 1vw;
  visibility: ${props => props.status === '' && 'hidden'};
  background-color: ${props => {
    switch (props.status) {
      case 'FALHA':
        return '#ed6663';
      case 'PASSOU':
        return '#408bb4';
      case 'NÃO EXECUTADO':
      case 'NÃO CONCLUSIVO':
        return '#7e7e7e';
      default:
        return '#fff';
    }
  }};

  @media screen and (max-width: 1600px) {
    margin-left: 5px;
  }
`;
