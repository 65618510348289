import React from 'react'
import intl from "react-intl-universal"

function getStyle(status) {
  if (status === 'FALHA') {
    return {
      backgroundColor: '#f26a5a'
      , color: 'white'
      , width: 'fit-content'
      , padding: '2px 4px 4px 2px'
    }
  }
  return {
    backgroundColor: '#4fd572'
    , color: 'white'
    , width: 'fit-content'
    , padding: '2px 4px 4px 2px'
  }
}

function getDisplayStyle() {
  return { display: 'flex', flexDirection: 'column', marginBottom: 20, marginTop: 20, fontSize: 12, marginLeft: '10px', marginRight: '10px', gap: '5px' }
}

function getResponseHandler(user_confirm, item) {
  let resultMsg = '';
  let expectedResultMsg = '';
  let gediRetMsg = item.ret_response;

  if (item.status === 'NÃO EXECUTADO') {
    const notExecutedMsg = 'NÃO EXECUTADO OU NÃO DECODIFICOU A RESPOSTA DA GEDI';
    resultMsg = notExecutedMsg;
    return (
      <>

        <span style={{ backgroundColor: '#b8b8b8', color: 'white', width: 'fit-content', padding: '2px 4px 4px 2px' }}>{intl.get("test-execution.not-executed")}</span>
        <span><strong>RESPONSE:</strong>{resultMsg}</span>
      </>
    );
  }
  if (user_confirm) {
    resultMsg = item.result;
    expectedResultMsg = item.expected_result;
    if (Array.isArray(resultMsg)) {
      return (
        <>
          <span><strong>HEX - RESPONSE:</strong> {item.cmd_response}</span>
          <span><strong>RESPONSE: </strong></span>
          {
            resultMsg.map(item => {
              return (
                <div style={getDisplayStyle()}>
                  <span>RESPONSE INDEX {item['index']}</span>
                  <span>{item['field']} OUT EXPECTED: {item['expected']}</span>
                  <span>RECEIVED {item['received']}</span>
                </div>
              )
            })
          }
          <span><strong>EXPECTED RESPONSE:</strong> {expectedResultMsg}</span>
          <span><strong>Return "</strong>{gediRetMsg}<strong>" is the expected result?</strong></span>
          <span><strong>1 - Yes | Other key - No</strong></span>
          {
            'user_confirm_status' in item ?
              <span style={getStyle(item['user_confirm_status'])}>{item['user_confirm_status'] === 'FALHA' ? intl.get("test-execution.fail") : intl.get("test-execution.passed-on")}</span>
              : <></>
          }
        </>
      )
    }
    return (
      <>
        <span><strong>HEX - RESPONSE:</strong> {item.cmd_response}</span>
        <span><strong>RESPONSE:</strong>{resultMsg}</span>
        <span><strong>EXPECTED RESPONSE:</strong> {expectedResultMsg}</span>
        <span><strong>Return "</strong>{gediRetMsg}<strong>" is the expected result?</strong></span>
        <span><strong>1 - Yes | Other key - No</strong></span>
        {
          'user_confirm_status' in item ?
            <span style={getStyle(item['user_confirm_status'])}>{item['user_confirm_status'] === 'FALHA' ? intl.get("test-execution.fail") : intl.get("test-execution.passed-on")}</span>
            : <></>
        }
      </>
    )
  }
  resultMsg = item.result;
  if (Array.isArray(resultMsg)) {
    return (
      <>

        <span style={getStyle('FALHA')}>{intl.get("test-execution.fail")}</span>
        <span><strong>HEX - RESPONSE:</strong> {item.cmd_response}</span>
        <span><strong>RESPONSE: </strong></span>
        {
          resultMsg.map(item => {
            return (
              <div style={getDisplayStyle()}>
                <span>RESPONSE INDEX {item['index']}</span>
                <span>{item['field']} OUT EXPECTED: {item['expected']}</span>
                <span>RECEIVED {item['received']}</span>
              </div>
            )
          })
        }
        <span><strong>Return "</strong>{gediRetMsg}"</span>
      </>
    )
  }
  return (
    <>

      <span style={getStyle(item.status)} >{item.status === 'FALHA' ? intl.get("test-execution.fail") : intl.get("test-execution.passed-on")}</span>
      <span><strong>HEX - RESPONSE:</strong> {item.cmd_response}</span>
      <span><strong>RESPONSE: </strong>{resultMsg}</span>
      {item.status === 'FALHA' ? <span><strong>EXPECTED: </strong>{item.expected_result}</span> : <></>}
      <span><strong>Return "</strong>{gediRetMsg}"</span>
    </>
  );
}

function StepInformation({ socketData, no, user_confirm, user_confirm_input }) {
  return socketData ? socketData.map((item, index) => {
    if (item.no === no) {
      return (
        <div key={index} style={getDisplayStyle()}>
          <span><strong>Command ID:</strong> {item.id}</span>
          {!isNaN(item.no_loop) ? <span><strong>Number Loop:</strong> {item.no_loop+1}</span> : <></>}
          <span><strong>HEX - COMMAND SENT:</strong> {item.cmd}</span>
          {getResponseHandler(user_confirm, item, user_confirm_input)}
        </div>
      )
    }
  }) : ''
}

export default StepInformation;