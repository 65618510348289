import styled from 'styled-components';

export const Container = styled.form`
    
    width: 100%;
    min-width: 600px;
    max-height: 600px;
    background-color: #fff;
    border-radius: 2px;

`

export const Wrapper = styled.div`
    display: flex;
    justify-content: end;
    margin-top:40px;
    position: relative;
    bottom: 20px;
    gap: 20px;

`

export const HeaderModal = styled.div`

 display: flex;
 flex-direction: row;
 
 align-items: center;
 justify-content: space-between;
 padding: 15px 20px;

 box-shadow: 1px 1px 1px #00000029;

`

export const ModalContent = styled.div`
    width: 100%;
    padding: 0 40px;

    margin-top: 20px;
`

export const Title = styled.p`

`