import React, { useState } from 'react'
import Modal from 'react-modal';
import { Container, HeaderModal, ModalContent, Title, Wrapper, WrapperFields, WrapperLeft, WrapperRight } from './styles';
import TextInput from '../../../Input/TextInput';
import ButtonActions from '../../../Button/ButtonActions';
import { IoCloseSharp } from "react-icons/io5";
import { api } from './../../../../api';
import intl from "react-intl-universal"
import { useEffect } from 'react';
import Success from '../../Success';

export default function ModalUpdDSReport({ idDs, isOpen, onRequestClose, onClick, handleWarn, setMessage, setIsShowLoader, closeModal, fetchData }) {

  const [data, setData] = useState({});
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  function closeConfirm() {
    setOpenModalConfirm(false);
  };

  function openConfirm(btn) {
    btn.preventDefault();
    setOpenModalConfirm(true);
  }

  function createTextInputs(itemsDataSheet) {
    const mapDatasheetFields = Object.keys(itemsDataSheet).map((item) => {
      return {
        field:item
        , value : itemsDataSheet[item] 
      };
    });
      return mapDatasheetFields.map((element, index) => {
        return (
          <TextInput
            name="name"
            defaultValue={element.value}
            onChange={(e) => handleChange(e, index, element.field)}
            key={index}
          >
            <Title>{element.field}</Title>
          </TextInput>);
      })
  }

  const getData = async () => {
    const [data, error] = await api.get(`/report/${idDs}/fields`)
      .then(response => {
        const response_data = response.data;
        return [response_data, null];
      })
      .catch(error => {
        return [null, error];
      });

    if (!error) {
      setData(data);
    } else {
      console.log(error);
    }
  };

  useEffect(() => {
    if (idDs !== null) {
      setData({});
      getData();
    }
  }, [idDs]);


  const handleUpdateDs = (e) => {  
    e.preventDefault();
    api.put(`/report/fields`, {
        id: idDs,
      strJson: data
    })
      .then(() => {
        onRequestClose();
        closeConfirm();
        getData()
      })
      .catch(error => {
        console.log(error);
      });
    
  };

  const handleChange = (e, i, field) => {
    setData(oldData => {
      let newData = Object.assign({},oldData);
      newData[field] = e.target.value;
      return newData
    })
  };

  return (
    <Modal
      isOpen={isOpen}
      onClick={onClick}
      onRequestClose={onRequestClose}
      shouldFocusAfterRender={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content">
      <Container>
        <HeaderModal>
          <Title>Datasheet</Title>
          <IoCloseSharp
            style={{ cursor: 'pointer', marginRight: 7 }}
            size={18}
            onClick={() => {getData(); onRequestClose()}} />
        </HeaderModal>
        <ModalContent>
          <WrapperFields>

              
            { createTextInputs(data)
                /*? dataDS.map((element, index) => {
                return (
                <TextInput
                  name="name"
                  onChange={(e) => handleChange(e, index)}
                  key={index}
                >
                  <Title>{element}</Title>
                </TextInput>);
              })
              : console.log("null")} */}
            
          </WrapperFields>
          <Wrapper>
            <ButtonActions
              bgColor={'#104068'}
              text={intl.get("repository.save")}
              onClick={openConfirm}
            />
            <ButtonActions
              bgColor={'#ed6663'}
              text={intl.get("repository.cancel")}
              onClick={() => {onRequestClose(); getData()}}
            />
          </Wrapper>

          <Success
            isOpen={openModalConfirm}
            onRequestClose={handleUpdateDs}
            title={"Datasheet"}
            message={intl.get("repository.data-saved-successfully")}
          />
          
        </ModalContent>
      </Container>
    </Modal>
  )
}
