import styled, { keyframes } from 'styled-components';
import DataTable from 'react-data-table-component';
import { AiOutlineEdit } from 'react-icons/ai';
import { IoCloseSharp } from 'react-icons/io5';

export const Container = styled.div`

  background-color: white;
  padding: 12px;
  
`

export const Wrapper = styled.div`

 flex-direction: column;
 align-items: center;

`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
	margin: 16px;
	animation: ${rotate360} 1s linear infinite;
	transform: translateZ(0);
	border-top: 2px solid grey;
	border-right: 2px solid grey;
	border-bottom: 2px solid grey;
	border-left: 4px solid black;
	background: transparent;
	width: 80px;
	height: 80px;
	border-radius: 50%;
`;

export const HeaderModal = styled.div`

 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: space-between;

`


export const NextOrder = styled.div`

 display: flex;
 flex-direction: column;
 align-items: center;

`

export const SubTitle = styled.p``

export const ButtonContainer = styled.div`
  
`

export const ButtonActionsContainer = styled.div`
  display: flex;
  gap: 20px;

`

export const ActionContainer = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 67px;
`

export const HeaderContainer = styled.div`

  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 12px;
`

export const ContainerInput = styled.div`

  display: flex;
  width: 45%;
  flex-direction: row;

  margin-top: 20px;
  gap: 20px;
`


export const Input = styled.input`

  height: 35px;
  width: 100%;
  max-width: 220px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #408bb3;
  padding: 0 8px;
  line-height: normal;
  font-size: 12px;

`

export const Table = styled(DataTable)`


  


`



export const Title = styled.h2`


 color: black;
 font-size: 28px;
 letter-spacing: .5px;
 margin-bottom: 10px;
 display: flex;
 position: relative;
 right: 23px;

 
`

export const TitleContainer = styled.div`

 width: 100%;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 margin: 20px 0px;
 align-items: center;
`


export const InputContainer = styled.div`

 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 margin: 14px 0;

`;

export const SearchContainer = styled.div`

width: 100%;
position: relative;
right: 25px;

`;
export const CloseIcon =  styled(IoCloseSharp)`

 color:#3b9ccf;
 font-size: 23px;
 cursor: pointer;

 &:hover { 
 background-color: white;
 padding: 1px;
 transition: 0.4s ease-in-out; 
 border-radius: 5px;
 box-shadow: 0 1px 2.8px #0003;
}

        
`;

export const UpdIcon =  styled(AiOutlineEdit)`

 color:#64c6c4;
 font-size: 23px;
 cursor: pointer;

 &:hover { 
 background-color: white;
 padding: 1.5px;
 transition: 0.4s ease-in-out; 
 border-radius: 5px;
 box-shadow: 0 1px 2.8px #0003;
}

        
`;