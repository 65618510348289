import styled from 'styled-components';

export const Container = styled.form`
    
    width: 100%;
    min-width: 700px;
    height: 650px;
    background-color: #fff;
 
    border-radius: 2px;

`

export const Wrapper = styled.div`
    display: flex;
    justify-content: end;

    margin-top: 25px;
    gap: 20px;

`

export const WrapperFields = styled.div`
    display: grid;
    height: 500px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 50px 50px;
    grid-gap: 20px;
    overflow: scroll;
    padding: 10px 0px; 
`

export const WrapperLeft = styled.div`
    width: 100%;
`

export const WrapperRight = styled.div`
    width: 100%;
`

export const HeaderModal = styled.div`

 display: flex;
 flex-direction: row;
 
 align-items: center;
 justify-content: space-between;
 padding: 15px 20px;

 box-shadow: 1px 1px 1px #00000029;

`

export const ModalContent = styled.div`
    width: 100%;
    padding: 0 40px;

    margin-top: 20px;
`

export const Title = styled.p`

`