import React, { useState } from 'react'
import Modal from 'react-modal';
import { Container, HeaderModal, NextOrder, SubTitle, Title, Wrapper } from './styles';
import { BsQuestionCircle } from "react-icons/bs";
import ButtonActions from '../../Button/ButtonActions';
import { IoCloseSharp } from "react-icons/io5";

export default function ModalReconnect({ isOpen, onRequestClose, onClick, title, message}) {

  return (
    <Modal
      isOpen={isOpen}
      onClick={onClick}
      closeTimeoutMS={115}
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender={false}
      shouldCloseOnEsc={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content">
      <Container>
        <HeaderModal>
          <Title>{title}</Title>
        </HeaderModal>
        <NextOrder>
          <BsQuestionCircle
            style={{ cursor: 'pointer', marginRight: 7, marginBottom: 10 }}
            size={40}
            color={'#ddba84'}
            onClick={onRequestClose} />
          <SubTitle>
            {message}
          </SubTitle>
        </NextOrder>
        <Wrapper>
          <ButtonActions
            bgColor={'#104068'}
            text={"Finalizar"}
            onClick={onClick} />
        </Wrapper>
      </Container>
    </Modal>
  )
}
