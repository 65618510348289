import React, { useState } from 'react'
import Modal from 'react-modal';
import { Container, HeaderModal, NextOrder, SubTitle, Title, Wrapper } from './styles';
import { BsQuestionCircle } from "react-icons/bs";
import ButtonActions from '../../../Button/ButtonActions';
import { IoCloseSharp } from "react-icons/io5";
import SuccessModal from '../Success';
import { api } from './../../../../api';
import intl from "react-intl-universal"
import DeleteRepo from './../ModalDelete';

export default function ModalOffGit({ isOpen, onRequestClose, onClick, id }) {

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openErrorLog, setOpenErrorLog] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  function openSuccessModal() {
    setOpenSuccess(true);
  };

  function openErrorModal() {
    setOpenErrorLog(true);
  };

  function closeErrorModal(e) {
    e.preventDefault();
    setOpenErrorLog(false);
    onRequestClose(e);
  }

  function closeSuccessModal(e) {
    e.preventDefault();
    setOpenSuccess(false);
    onRequestClose(e);
  };

  const handleDeleteRepo = (e) => {
    e.preventDefault();
    const url = `/repository/${id}`;
    api.delete(url)
      .then(() => {
        openSuccessModal();
      }).catch((error) => {
        openErrorModal();
        setErrorMsg(error.response.data.detail);
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={115}
      onClick={onClick}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      onRequestClose={onRequestClose}
      shouldFocusAfterRender={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content">
      <Container>
        <HeaderModal>
          <Title>{intl.get("repository.delete-repositories")}</Title>
          <IoCloseSharp
            style={{ cursor: 'pointer', marginRight: 7 }}
            size={18}
            onClick={onRequestClose} />
        </HeaderModal>
        <NextOrder>
          <BsQuestionCircle
            style={{ cursor: 'pointer', marginRight: 7, marginBottom: 10 }}
            size={40}
            color={'#ddba84'}
            onClick={onRequestClose} />
          <SubTitle>{intl.get("repository.delete-repository-question")}</SubTitle>
        </NextOrder>
        <Wrapper>
          <ButtonActions
            bgColor={'#104068'}
            text={intl.get("repository.yes")}
            onClick={handleDeleteRepo} />
          <ButtonActions
            bgColor={'#ed6663'}
            text={intl.get("repository.cancel")}
            onClick={onRequestClose} />
        </Wrapper>
      </Container>
      <SuccessModal
        isOpen={openSuccess}
        onRequestClose={closeSuccessModal}
        title={intl.get("repository.repositories")}
        message={intl.get("repository.delete-repository-sucess")} />
      <DeleteRepo
        isOpen={openErrorLog}
        onRequestClose={closeErrorModal}
        message={errorMsg}
        title1={intl.get('repository.failed')} />
    </Modal>
  )
}
