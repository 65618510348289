import React from 'react'
import { Container, Input, InputContainer, Title } from './styles';

const TextInput = ({ type, placeholder, value, onChange, children, defaultValue, key, styles, ...props }) => {

  return (
    <Container>
      <Title>{children}</Title>
      <InputContainer>
        <Input
          style={styles}
          value={value}
          id={key}
          onChange={onChange}
          type={type}
          placeholder={placeholder}
          defaultValue={defaultValue}
          {...props}
        />
      </InputContainer>
    </Container>
  )
}

export default TextInput