import React from 'react'
import TableReport from '../../components/Tables/TableReport'
import { Container, Title, Wrapper } from './styles'
import intl from 'react-intl-universal';

export default function Reports() {

  return (
    <Container>
      <Wrapper>
        <Title>{intl.get("report.title")}</Title>
        <TableReport />
      </Wrapper>
    </Container>
  )
}
