import React from 'react'
import { Container, Icon, IconView, Input } from './styles';

const LogInput = ({ type, placeholder, value, onChange, onSubmit, onKeyDown, disabled, color, borderColor, bgInput, bgIcon }) => {

  return (
    <Container
      onSubmit={onSubmit}
      onKeyDown={onKeyDown}
      borderColor={borderColor}>
      <Input
        bgInput={bgInput}
        value={value}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
      />
      <IconView
        type='submit'
        bgIcon={bgIcon}
        disabled={disabled}>
        <Icon color={color} />
      </IconView>
    </Container>
  )
}

export default LogInput