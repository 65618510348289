import styled from 'styled-components';

export const Container = styled.div`

  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 
`

export const STable = styled.table`
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    overflow: hidden;
	border-radius: 1px;
    z-index: 0;
    
`;

export const STHead = styled.thead`
    position: sticky;
    z-index: 100;
	  text-align: left;
    border-bottom:.1px solid #eeeeee;
   
`;

export const STHeadTR = styled.tr`
background-color: #eaf2f7;
`;

export const STH = styled.th`
    font-weight: normal;
    text-transform: capitalize;
    font-weight: 600;
	padding: 16.8px;
    font-size: 14px;
    :first-of-type {
    width: 1%;
    white-space: nowrap;
    }
`;
