import DataTable from 'react-data-table-component';
import { IoPlayCircleOutline } from 'react-icons/io5';
import { IoCloseSharp } from 'react-icons/io5'
import {AiOutlineEdit} from 'react-icons/ai'
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`

 margin: 0;
 padding: 0;
 margin-bottom: 50px;
`

export const Wrapper = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0 0 23px;
`

export const Table = styled(DataTable)`

  

`

export const TitleContainer = styled.div`

 width: 100%;
 display: flex;
 flex-direction: column;
 gap: 10px;
 justify-content: space-between;
 margin: 20px 0px;
 
`


export const InputContainer = styled.div`

 display: flex;
 width: 100%;
 flex-direction: row;
 justify-content: space-between;
 gap: 20px;
 align-items: center;
 position: relative;

`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
	margin: 16px;
	animation: ${rotate360} 1s linear infinite;
	transform: translateZ(0);
	border-top: 2px solid grey;
	border-right: 2px solid grey;
	border-bottom: 2px solid grey;
	border-left: 4px solid black;
	background: transparent;
	width: 80px;
	height: 80px;
	border-radius: 50%;
`;

export const PlayIcon =  styled(IoPlayCircleOutline)`

 color:#3b9ccf;
 font-size: 26px;
 cursor: pointer;

 &:hover { 
 background-color: white;
 padding: 1px;
 transition: 0.4s ease-in-out; 
 border-radius: 5px;
 box-shadow: 0 1px 2.8px #0003;
}

        
`

export const CloseIcon =  styled(IoCloseSharp)`

 color:#3b9ccf;
 font-size: 23px;
 cursor: pointer;

 &:hover { 
	background-color: white;
	padding: 1px;
	transition: 0.4s ease-in-out; 
	border-radius: 5px;
	box-shadow: 0 1px 2.8px #0003;
 }
`

export const UpdIcon = styled(AiOutlineEdit)`

 color:#3b9ccf;
 font-size: 23px;
 cursor: pointer;

 &:hover { 
	background-color: white;
	padding: 1px;
	transition: 0.4s ease-in-out; 
	border-radius: 5px;
	box-shadow: 0 1px 2.8px #0003;
 }
        
`;

export const ActionContainer = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 67px;
`
