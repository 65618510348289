import React, { useState } from 'react'
import Modal from 'react-modal';
import { Container, HeaderModal, NextOrder, SubTitle, Title, Wrapper } from './styles';
import { BsQuestionCircle } from "react-icons/bs";
import ButtonActions from '../../../Button/ButtonActions';
import { IoCloseSharp } from "react-icons/io5";
import intl from "react-intl-universal"
import Success from '../../Success';

export default function RemoveUpdate({ isOpen, onRequestClose, onClick, title, message, action, type }) {

  const [deleteUpdate, setDeleteUpdate] = useState(false);

  function openRemoveEnd() {
    setDeleteUpdate(true)
  };

  function closeRemoveEnd(e) {
    setDeleteUpdate(false)
    onRequestClose(e)
  };


  const removeUpd = (e) => {
    e.preventDefault();
    try {
      action(e)
      openRemoveEnd()
    } catch (err) {
      console.log(err)
    }
  };


  return (
    <Modal
      isOpen={isOpen}
      onClick={onClick}
      closeTimeoutMS={115}
      onRequestClose={onRequestClose}
      shouldFocusAfterRender={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content">
      <Container>
        <HeaderModal>
          <Title>{title}</Title>
          <IoCloseSharp
            style={{ cursor: 'pointer', marginRight: 7 }}
            size={18}
            onClick={onRequestClose} />
        </HeaderModal>
        <NextOrder>
          <BsQuestionCircle
            style={{ cursor: 'pointer', marginRight: 7, marginBottom: 10 }}
            size={40}
            color={'#ddba84'}
            onClick={onRequestClose} />
          <SubTitle>{message}</SubTitle>
        </NextOrder>
        <Wrapper>
          <ButtonActions
            bgColor={'#104068'}
            text={intl.get("repository.yes")}
            onClick={removeUpd} />
          <ButtonActions
            bgColor={'#ed6663'}
            text={intl.get("repository.cancel")}
            onClick={onRequestClose} />
        </Wrapper>
      </Container>
      {
        type === "report" ? 
          <Success
            isOpen={deleteUpdate}
            onRequestClose={closeRemoveEnd}
            title={intl.get("home.reports")}
            message={intl.get("test-execution.report-generated-successfully")} />
        :
        <Success
          isOpen={deleteUpdate}
          onRequestClose={closeRemoveEnd}
          title={intl.get("test-execution.parameter-configuration")}
          message={intl.get("test-execution.parameter-editing-successfully-removed")} />
      }
    </Modal>
  )
}
