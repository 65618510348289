import React, { useRef, useState, useEffect, useMemo } from 'react';
import autoAnimate from '@formkit/auto-animate';
import Modal from 'react-modal';
import {
  Container,
  HeaderModal,
  ModalContent,
  Title,
  Wrapper,
  SelectContainer,
  SelectTitle,
  SelectInputContainer,
  Select,
  AttachmentsContainer,
  AttachmentsTitle,
  AttachmentsItemsContainer,
  TitleDiv
} from './styles';
import TextInput from '../../Input/TextInput';
import ButtonActions from '../../Button/ButtonActions';
import { IoCloseSharp } from "react-icons/io5";
import { MdAttachment } from "react-icons/md"
import { api } from '../../../api';
import intl from "react-intl-universal"
import TextAreaInput from '../../Input/TextAreaInput';
import Loader from '../../Loader';
import Success from '../../Modal/Success';
import ErrorLog from '../ModalSync/ErrorLog';

export default function ModalBugRegister({ isOpen, onRequestClose, onClick, bugIndex, addToBugList, bugStructId }) {

  const defaultOption = 'Selecione o projeto';

  const [jiraProject, setJiraProject] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [platform, setPlatform] = useState("");
  const [severity, setSeverity] = useState("Medium");
  const [reproduceSteps, setReproduceSteps] = useState("");
  const [swrelUrl, setSwrelUrl] = useState("");
  const [type, setType] = useState("Relates");
  const [files, setFiles] = useState([]);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [catchError, setCatchError] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const [projectsList, setProjectsList] = useState([]);
  const [repeatFile, setErrRepeatFile] = useState(false);

  const parent = useRef(null);

  const inputRef = useRef(null)

  useMemo(() => {
    parent.current && autoAnimate(parent.current)
  }, [files]);

  useEffect(() => {
    setErrRepeatFile(false)
  }, [files]);

  const resetFileInput = () => {
    inputRef.current.value = null;
  };

  const closeSuccess = (e) => {
    e.preventDefault();
    setIsSuccess(false);
    onRequestClose(e);
  };

  const closeError = (e) => {
    e.preventDefault();
    setIsError(false);
  };

  const handler = () => {
    if (jiraProject.trim() === "" || jiraProject.trim() === defaultOption) {
      setErrorMessage("O campo Projeto Jira é obrigatório!");
      setIsError(true);
    } else if (title.trim() === "") {
      setErrorMessage("O campo Título é obrigatório!");
      setIsError(true);
    } else if (description.trim() === "") {
      setErrorMessage("O campo Descrição é obrigatório!");
      setIsError(true);
    } else {
      setIsShowLoader(true);
      const body = {
        project: jiraProject,
        title: title,
        description: description,
        priority: severity,
        issueType: 'Bug',
        struct_id: bugStructId
      };

      api.post('/jira/open-issue', body)
        .then((res) => {
          const { response, data } = res;

          if (response) {
            setErrorMessage(response.data.detail);
            setIsError(true);
            setIsShowLoader(false);
            return;
          }

          const isFieldErrorsExists = data.body.hasOwnProperty('errors');
          const isIdFieldExists = data.body.hasOwnProperty('id');
          if (isFieldErrorsExists) {
            setErrorMessage("Erro ao cadastrar bug no JIRA");
            setIsError(true);
            setIsShowLoader(false);
            return;
          }

          if (isIdFieldExists && files.length) {
            const formData = new FormData()
            for (let i = 0; i < files.length; i++) {
              formData.append(`uploadFileList`, files[i])
            }
            const newIssueId = data.body.id
            api.post(`/jira/attachment/${newIssueId}`, formData)
          }

          addToBugList(bugIndex, data.body.jira_url);
          setIsSuccess(true);
          setIsShowLoader(false);
          cleaner();
        }).catch((error) => {
          console.log(error)
          setErrorMessage(error.detail);
          setIsError(true);
          setIsShowLoader(false);
        })
    }
  };

  const cleaner = () => {
    setJiraProject("");
    setTitle("");
    setDescription("");
    setPlatform("");
    setReproduceSteps("");
    setSwrelUrl("");
    setFiles('')
  }

  const handleBugRegister = async (e) => {
    e.preventDefault();
    try {
      if (e.target.value === defaultOption) {
        setIsError(true);
        setErrorMessage(catchError)
      } else {
        handler()
      }

    }
    catch (error) {
      if (error.response.status >= 300) {
        setErrorMessage(error.response.data.detail);
        setCatchError(error.response.data.detail)
        setIsError(true);
        setIsShowLoader(false);
      }
    }
  };

  const onCancel = (e) => {
    e.preventDefault();
    onRequestClose();
    cleaner()
  };

  const addAttachment = (event) => {
    let attachmentAux = event.target.files;
    let isRepeat = false;

    try {
      if (attachmentAux) {
        for (let i = 0; i < attachmentAux.length; i++) {
          for (let j = 0; j < files.length; j++) {
            if (files[j].name === attachmentAux[i].name) {
              isRepeat = true;
              setErrRepeatFile(true)
            }
          }
          if (!isRepeat) {
            setErrRepeatFile(false)
            setFiles(oldFiles => {
              if (oldFiles === null)
                return [attachmentAux[i]]
              return [...oldFiles, attachmentAux[i]]
            });
          }
          isRepeat = false;
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const removeAttachment = (attachmentName) => {
    setErrRepeatFile(false);
    setFiles(files.filter(attachment => attachment.name !== attachmentName));
    resetFileInput();
  }

  useEffect(() => {
    api.get('/jira/projects')
      .then(response => {
        const { data } = response;
        setProjectsList(data.body.values);
      })
      .catch(err => {
        setErrorMessage(err)
      })
  }, [files]);

  const handleChange = (e) => {
    setJiraProject(e.target.value);
  }

  return (
    <Modal
      isOpen={isOpen}
      onClick={onClick}
      closeTimeoutMS={115}
      onRequestClose={onRequestClose}
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content">
      <Container>
        <HeaderModal>
          <Title>Open tickets in Jira</Title>
          <IoCloseSharp
            style={{ cursor: 'pointer', marginRight: 7 }}
            size={18}
            onClick={onCancel} />
        </HeaderModal>
        <ModalContent>
          <SelectContainer>
            <TitleDiv>
              <SelectTitle>Project Jira</SelectTitle>
            </TitleDiv>
            <SelectInputContainer>
              <Select value={jiraProject} onChange={handleChange}>
                <option defaultValue={defaultOption}>{defaultOption}</option>
                {projectsList.map((project, index) => (<option key={index} value={project.key}>{project.key}</option>))}
              </Select>
            </SelectInputContainer>
          </SelectContainer>
          <TextInput
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          >
            <TitleDiv>
              Title
            </TitleDiv>
          </TextInput>
          <TextAreaInput
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          >
            <TitleDiv>
              Description
            </TitleDiv>
          </TextAreaInput>
          <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
            <TextInput
              styles={{ width: '175%' }}
              value={platform}
              onChange={(e) => setPlatform(e.target.value)}
            >
              Ambiente
            </TextInput>
            <SelectContainer>
              <SelectTitle>
                Priority
              </SelectTitle>
              <SelectInputContainer>
                <Select onChange={(e) => setSeverity(e.target.value)}>
                  <option value="Low">Low</option>
                  <option value="Medium" selected >Medium</option>
                  <option value="High">High</option>
                  <option value="Critical">Critical</option>
                </Select>
              </SelectInputContainer>
            </SelectContainer>
          </div>
          <TextInput
            value={reproduceSteps}
            onChange={(e) => setReproduceSteps(e.target.value)}
          >
            Steps to reproduce
          </TextInput>
          <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
            <TextInput
              styles={{ width: '175%' }}
              value={swrelUrl}
              onChange={(e) => setSwrelUrl(e.target.value)}
            >
              SWREL URL
            </TextInput>
            <SelectContainer>
              <SelectTitle>Type</SelectTitle>
              <SelectInputContainer>
                <Select onChange={(e) => setType(e.target.value)}>
                  <option value="blocks">Blocks</option>
                  <option value="relates" selected >Relates</option>
                </Select>
              </SelectInputContainer>
            </SelectContainer>
          </div>

          <AttachmentsContainer>
            <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
              <AttachmentsTitle>Attachments</AttachmentsTitle>
              <MdAttachment onClick={() => document.getElementById('addAttachmentInput').click()} style={{ fontSize: '20px', cursor: 'pointer' }} />
              <input
                ref={inputRef}
                style={{ display: 'none' }}
                onChange={addAttachment}
                type="file"
                id="addAttachmentInput"
                multiple />
              {repeatFile ? <p>Arquivo já importado</p> : null}
            </div>
            <div style={{ background: '#fafafa' }}>
              <AttachmentsItemsContainer ref={parent}>
                {
                  files &&
                  files.map((attachment, index) => {
                    return (
                      <div style={{ display: 'flex', alignItems: 'center', width: '300px', gap: '20px' }} key={index}>
                        <div style={{ width: '20px', height: '20px', alignItems: 'center' }} >
                          <IoCloseSharp style={{ cursor: 'pointer', width: '100%', height: '100%' }} onClick={() => removeAttachment(attachment.name)} />
                        </div>
                        <div style={{ display: 'flex', width: '200px', height: '100%', alignItems: 'center' }}>
                          <p style={{ lineBreak: 'anywhere', fontSize: '12px' }}>{attachment.name}</p>
                        </div>
                      </div>
                    );
                  })
                }
              </AttachmentsItemsContainer>
            </div>
          </AttachmentsContainer>

          <Wrapper>
            <ButtonActions
              bgColor={'#104068'}
              text={intl.get("repository.save")}
              onClick={handleBugRegister} />
            <ButtonActions
              bgColor={'#ed6663'}
              text={intl.get("repository.cancel")}
              onClick={onCancel} />
          </Wrapper>
        </ModalContent>
      </Container>
      <Success
        isOpen={isSuccess}
        message={"Bug cadastrado com sucesso"}
        title={"Cadastro de bug"}
        onRequestClose={closeSuccess}
      />
      <ErrorLog
        isOpen={isError}
        message={errorMessage}
        title1={"Erro"}
        onRequestClose={closeError}
      />
      <Loader isShow={isShowLoader} />
    </Modal>
  )
}
