import React from 'react'
import { AiOutlineWarning } from 'react-icons/ai';
import { IoCloseSharp } from 'react-icons/io5'
import Modal from 'react-modal'
import { Container, HeaderModal, NextOrder, SubTitle, Title, Wrapper } from './styles'
import intl from "react-intl-universal"
import ButtonActions from '../../Button/ButtonActions';

export default function FailModal({isOpen, onClick, onRequestClose, title, message}) {

  return (
    <Modal
    isOpen={isOpen}
    closeTimeoutMS={115}
    onClick={onClick}
    onRequestClose={onRequestClose}
    shouldFocusAfterRender={false}
    overlayClassName="react-modal-overlay"
    className="react-modal-content">
    <Container>
      <HeaderModal>
        <Title style={{fontWeight: 400}}>{'Configuração de Parâmetro'}</Title>
        <IoCloseSharp
          style={{ cursor: 'pointer', marginRight: 7 }}
          size={18}
          onClick={onRequestClose} />
      </HeaderModal>
      <NextOrder>
    <AiOutlineWarning 
    style={{ marginRight: 7, marginBottom: 10 }} 
    size={40} 
    color={'#ed6663'}/>
    <Title>{title}</Title>
    <SubTitle>{message}</SubTitle>
    </NextOrder>
    <Wrapper>
      <></>
      <ButtonActions
      bgColor={'#ed6663'}
      text={intl.get("repository.cancel")}
      onClick={onRequestClose}/>
    </Wrapper>
    </Container>
  </Modal>
  )
}
