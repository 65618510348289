import styled from "styled-components";

export const Container = styled.div`

 background-color: #408bb3;
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 height: 65px;
`