import React from "react";
import Modal from "react-modal";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";
import { Container, HeaderModal, NextOrder, Title, Wrapper } from "./styles";
import ButtonActions from "../../Button/ButtonActions";

export default function Success({ isOpen, onRequestClose, message, title }) {

  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={115}
      onRequestClose={onRequestClose}
      shouldFocusAfterRender={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content">
      <Container>
        <HeaderModal>
          <Title>{title}</Title>
          <IoCloseSharp
            style={{ cursor: "pointer", marginRight: 7 }}
            size={18}
            onClick={onRequestClose}
          />
        </HeaderModal>
        <NextOrder>
          <IoCheckmarkCircleOutline
            style={{ marginRight: 7, marginBottom: 10 }}
            size={40}
            color={"#73e995"}
          />
          <Title>{message}</Title>
        </NextOrder>
        <Wrapper>
          <></>
          <ButtonActions
            bgColor={"#104068"}
            text={"Ok"}
            onClick={onRequestClose}
          />
        </Wrapper>
      </Container>
    </Modal>
  );
}
