import React from 'react'
import { Container, Input, InputContainer, Title } from './styles';

const DisabledTxtInput = ({type, placeholder, value, onChange, children, defaultValue, disabled}) => {

    return (
      <Container>
      <Title>{children}</Title>
     <InputContainer>
     <Input
      value={value} 
      onChange={onChange}
      type={type}
      placeholder={placeholder}
      defaultValue={defaultValue}
      disabled={disabled}
      />
     </InputContainer>
      </Container>
    )
  };

export default DisabledTxtInput;
  
