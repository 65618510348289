import React, { useEffect, useState } from "react";
import { api } from "../../../api";
import {
  CardContainer,
  CardsProjects,
  CardsReports,
  Container,
  Folder,
  Info,
  Project,
  Report,
  Sheet,
  Title,
} from "./styles";
import { Link } from "react-router-dom";
import intl from "react-intl-universal";

export default function FileCards({ type }) {
  const [projects, setProjects] = useState([]);
  const [reports, setReports] = useState([]);

  const fetchProjects = async () => {
    try {
      const response = await api.get(`/projects/recents?skip=0&limit=4`);
      setProjects(response.data.items);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchReports = async () => {
    try {
      const response = await api.get(`/report/recents?skip=0&limit=4`);
      setReports(response.data.items);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchReports();
  }, []);

  return (
    <Container>
      {type === "projects" ? (
        <CardContainer type={type}>
          <Title>{intl.get("home.projects")}</Title>
          <CardsProjects>
            {projects.length > 0 ? (
              projects.map((item, index) => {
                return (
                  <Link
                    key={index}
                    to={`/projects/execute-project/${item.id}/exec`}
                  >
                    <Project>
                      <Folder />
                      <Info>{item.name}</Info>
                    </Project>
                  </Link>
                );
              })
            ) : (
              <span children={intl.get("home.no-projects")} />
            )}
          </CardsProjects>
        </CardContainer>
      ) : type === "reports" ? (
        <CardContainer type={type}>
          <Title>{intl.get("home.reports")}</Title>
          <CardsReports>
            {reports.length > 0 ? (
              reports.map((item) => {
                return (
                  <Link key={item.id} to={`/reports/data/${item.id}`}>
                    <Report>
                      <Sheet />
                      <Info>{`${item.name}`}</Info>
                    </Report>
                  </Link>
                );
              })
            ) : (
              <span children={intl.get("home.no-reports")} />
            )}
          </CardsReports>
        </CardContainer>
      ) : (
        <Container type={type}>Não há projetos há exibir</Container>
      )}
    </Container>
  );
}
