import React from 'react'
import { STable, STBody, STBodyTR, STD, STH, STHead, STHeadTR, Container } from './styles'

export default function UserConfirm({ data, titleA, titleB, caption, status }) {
  return (
    <Container>
      {data.map((item, index) => (
        item.user_confirm ?
          <STable key={index}>
            <caption>{caption}</caption>
            <STHead>
              <STHeadTR>
                <STH>{titleA}</STH>
                <STH>{titleB}</STH>
              </STHeadTR>
            </STHead>
            <STBody>
              <STBodyTR>
                <STD children={item.description} />
                <STD children={status} />
              </STBodyTR>
            </STBody>
          </STable> : null
      ))}
    </Container>
  )
}