import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Badge,
  ContainerTitle,
  FolderContainer,
  Status,
  StyledFile,
  StyledFolder,
  StyledTree,
  Title,
} from "./styles";
import IN from '../../../assets/IN.png';
import OUT from '../../../assets/OUT.png'
import UC from '../../../assets/UC.png'
import ToolTipFolder from "../../ToolTip/Folder";
import autoAnimate from "@formkit/auto-animate";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import intl from "react-intl-universal"
import BouncingDots from "../../Loader/BouncingDots";

const getHexColorByType = (type) => {
  if (type.toLowerCase() === 'project')
    return '#65a1c2';
  if (type.toLowerCase() === 'script')
    return '#82d0cf';
  if (type.toLowerCase() === 'feature')
    return '#ac91e0';
  if (type.toLowerCase() === 'scenario')
    return '#e4b46f';
  if (type.toLowerCase() === 'step')
    return '#ed92b1';
  return 'black';
};

function getStructureTypeByItem(item) {
  let output = '';
  if ('scripts' in item)
    return 'project'
  if ('features' in item)
    return 'script'
  if ('scenarios' in item)
    return 'feature'
  if ('steps' in item)
    return 'scenario'
  if ('params' in item)
    return 'step'
  return output
}

const ParamBase = ({ text, type, value }) => {
  return (
    <div style={{ paddingLeft: 20 }}>
      <StyledFile>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 10 }}>
          <span style={{
            color: 'black', fontSize: 13, fontWeight: 600
          }}>
            {text}
          </span>
          {
            type === 'IN'
              ?
              <img src={IN} width={24} style={{ cursor: 'pointer', marginLeft: 7 }} />
              :
              type === "OUT"
                ?
                <img src={OUT} width={24} style={{ cursor: 'pointer', marginLeft: 7 }} />
                :
                null
          }
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', height: 30, alignItems: 'center' }}>
          {
            value.length < 40 ? <p>{value}</p> : <p>{`${value.substring(0, 37)}...`}</p>
          }
        </div>
      </StyledFile>
    </div>
  );
};

const Param = ({ text, type, value }) => {
  return (
    <ParamBase
      text={text}
      type={type}
      value={isNaN(value) ? value : `${value}`}
    />
  );
};

function handleTreeToggle(type) {
  if (getStructureTypeByItem(type) === 'feature') {
    return false
  } else if (getStructureTypeByItem(type) === 'scenario') {
    return false
  } else if (getStructureTypeByItem(type) === 'step') {
    return false
  } else {
    return true
  }
};

const Base = ({ item, execution, endExecution, getProjectStatus, onChangeCheckbox }) => {

  const [isOpen, setIsOpen] = useState(handleTreeToggle(item));

  const parent = useRef(null);

  useEffect(() => {
    getProjectStatus(item.executionStatus);
  }, [endExecution]);

  useEffect(() => {
    parent.current && autoAnimate(parent.current)
  }, [])

  const iconStyle = {
    backgroundColor: getHexColorByType(getStructureTypeByItem(item)),
    cursor: 'pointer',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    height: 35,
    width: 20,
    paddingTop: 7.5,
    paddingLeft: 2,
    position: 'relative',
    right: 10,
    top: 0.6,
    color: 'white'
  }

  const handleCheckbox = (event) => {
    onChangeCheckbox(item.no, event.target.checked, true);
  }

  const handleToggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  function getItemDescription(description) {
    if (!description) {
      return intl.get("test-execution.tree-!description")
    }
    return description
  }
  const getChildrenItems = useCallback((item) => {
    if (getStructureTypeByItem(item) === 'project') {
      return item.scripts;
    }
    if (getStructureTypeByItem(item) === 'script') {
      return item.features;
    }
    if (getStructureTypeByItem(item) === 'feature') {
      return item.scenarios;
    }
    if (getStructureTypeByItem(item) === 'scenario') {
      return item.steps;
    }
    if (getStructureTypeByItem(item) === 'step') {
      return item.params
    }
    return []
  }, [item]);

  function getChildrenElementByItem(parentItem) {
    if (getStructureTypeByItem(parentItem) !== 'step')
      return (getChildrenItems(parentItem)).map((item, index) =>
        <Base
          item={item}
          execution={execution}
          onChangeCheckbox={onChangeCheckbox}
          key={index}
          getProjectStatus={getProjectStatus}
        />
      )
    if ('params' in parentItem)
      return parentItem.params.map((param, index) =>
        <Param
          key={index}
          text={param.name}
          type={param.type}
          value={param.type === 'IN' ? param.value_in : param.value_out}
        />
      )
    return <Fragment />
  };

  const handleStatusExecution = useCallback(() => {
    switch (item.executionStatus) {
      case 'NÃO SELECIONADO':
        return <Status fontColor={"#e1e1e1"} children={intl.get("test-execution.not-selected")} />;
      case 'AGUARDANDO':
        return <BouncingDots />
      case 'EXECUTANDO':
        return <Status fontColor={"#464646"} children={intl.get("test-execution.executing")} />;
      case 'PASSOU':
        return <Status fontColor={"#4fd572"} children={intl.get("test-execution.passed-on")} />
      case 'FALHA':
        return <Status fontColor={"#f26a5a"} children={intl.get("test-execution.fail")} />;
      case 'NÃO EXECUTADO':
        return <Status fontColor={"#b8b8b8"} children={intl.get("test-execution.not-executed")} />;
      case 'NÃO CONCLUSIVO':
        return <Status fontColor={"#f1c073"} children={intl.get("test-execution.not-completed")} />;
      default:
        return ''
    }
  }, [item.executionStatus]);

  function handleIcon() {
    if (isOpen) {
      return <IoIosArrowUp size={16} />
    } else {
      return <IoIosArrowDown size={16} />
    }
  };

  return (
    <StyledFolder ref={parent}>
      <FolderContainer>
        <ContainerTitle>
          <div style={iconStyle} onClick={handleToggle} children={handleIcon()} />
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 7 }}>
            <Title children={item.id} />
            {
              item.user_confirm === true || (item.steps && item.steps.filter((step) => step.user_confirm === true).length > 0)
                ?
                <img src={UC} width={24} style={{ cursor: 'pointer', marginRight: 2, marginBottom: 4 }} />
                :
                <div style={{ width: 25 }} />
            }
          </div>
        </ContainerTitle>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <div children={handleStatusExecution()} />
          <ToolTipFolder children={getItemDescription(item.description)} />
          <Badge style={{ backgroundColor: getHexColorByType(getStructureTypeByItem(item)) }}>{getStructureTypeByItem(item)}</Badge>
          <span style={{ paddingRight: 10, fontSize: 20, color: '#ececec' }}>|</span>
          <input
            id={item.no}
            type="checkbox"
            checked={item.checked}
            onChange={handleCheckbox}
            disabled={execution} />
        </div>
      </FolderContainer>
      {isOpen && getChildrenElementByItem(item)}
    </StyledFolder>
  )
};

export const TestTree = (
  { data,
    onSelectTreeItem,
    execution,
    endExecution,
    firstFilter,
    failFilter,
    notExecutedFilter,
    notConcludedFilter,
    passedFilter,
    notSelectedFilter,
    getProjectStatus
  }) => {
  const [executionFilter, setExecutionFilter] = useState(firstFilter);

  function reexecutionFilters(
    notSelectedFilter,
    passedFilter,
    failFilter,
    notExecutedFilter,
    notConcludedFilter,
    firstFilter,
    newStructure,
  ) {
    let selected = false
    let uncheckedSteps = [];
    let notSelectedFilterSteps = [];
    let passedFilterSteps = [];
    let failFilterSteps = [];
    let notExecutedFilterSteps = [];
    let notConcludedFilterSteps = [];
    let executedSteps = getScenarios(newStructure);

    if (notSelectedFilter === true) {
      selected = true
      notSelectedFilterSteps = executedSteps.filter((item) => item.executionStatus === "NÃO SELECIONADO");
    }

    if (passedFilter === true) {
      selected = true
      passedFilterSteps = executedSteps.filter((item) => item.executionStatus === "PASSOU");
    }

    if (failFilter === true) {
      selected = true
      failFilterSteps = executedSteps.filter((item) => item.executionStatus === "FALHA");
    }
    if (notExecutedFilter === true) {
      selected = true
      notExecutedFilterSteps = executedSteps.filter((item) => item.executionStatus === "NÃO EXECUTADO");
    }
    if (notConcludedFilter === true) {
      selected = true
      notConcludedFilterSteps = executedSteps.filter((item) => item.executionStatus === "NÃO CONCLUSIVO");
    }

    let markedSteps = failFilterSteps.concat(notExecutedFilterSteps, notConcludedFilterSteps, notSelectedFilterSteps, passedFilterSteps)
    uncheckedSteps = executedSteps.filter((step) => !(markedSteps.includes(step)));

    if (failFilter === false && notExecutedFilter === false && notConcludedFilter === false && passedFilter === false && notSelectedFilter === false) {
      markedSteps = executedSteps;
      uncheckedSteps = executedSteps.filter((step) => !(markedSteps.includes(step)));
    } else {
      selected = true
      newStructure = markSteps(markedSteps, uncheckedSteps, newStructure);
    }
    return [newStructure, selected]
  }

  useMemo(() => {
    let selectedFlag = false
    setExecutionFilter(firstFilter);

    let newStructure = Object.assign({}, data);

    let executedSteps
    let steps, markedSteps, uncheckedSteps;

    if (firstFilter === 0) {
      selectedFlag = true
      steps = getSteps(newStructure);
      markedSteps = steps;
      executedSteps = markedSteps;
    }

    if (firstFilter === 1) {
      selectedFlag = true
      steps = getSteps(newStructure);
      markedSteps = steps;
      markedSteps.forEach((step) => { newStructure = updateTree(step.no, true, newStructure) });
      executedSteps = markedSteps;
    }
    if (firstFilter === 2) {
      selectedFlag = true
      let scenarios = getScenarios(newStructure)
      scenarios = scenarios.map(scenario => {
        scenario['user_confirm'] = scenario['steps'].some((step) => 'user_confirm' in step && step.user_confirm === true)
        return scenario
      })
      steps = scenarios
      markedSteps = steps.filter((item) => item.user_confirm === true)
      uncheckedSteps = steps.filter((step) => !(markedSteps.includes(step)));
      newStructure = markSteps(markedSteps, uncheckedSteps, newStructure);
      executedSteps = markedSteps;
    }
    if (firstFilter === 3) {
      selectedFlag = true
      let scenarios = getScenarios(newStructure)
      scenarios = scenarios.map(scenario => {
        scenario['user_confirm'] = scenario['steps'].some((step) => 'user_confirm' in step && step.user_confirm === true)
        return scenario
      })
      steps = scenarios
      console.log(steps)
      markedSteps = steps.filter((item) => item.user_confirm === false);
      uncheckedSteps = steps.filter((step) => !(markedSteps.includes(step)));
      newStructure = markSteps(markedSteps, uncheckedSteps, newStructure)
      executedSteps = markedSteps;
    }
    let values = reexecutionFilters(
      notSelectedFilter,
      passedFilter,
      failFilter,
      notExecutedFilter,
      notConcludedFilter,
      firstFilter,
      newStructure);
    newStructure = values
    if(selectedFlag)
      onSelectTreeItem(false)
  }, [firstFilter, endExecution, notSelectedFilter, passedFilter, failFilter, notExecutedFilter, notConcludedFilter]);

  function markSteps(markedSteps, uncheckedSteps, newStructure) {
    if (markedSteps) {
      markedSteps.forEach((step) => {
        step.checked = true;
        newStructure = updateTree(step.no, true, newStructure);
      });
    }
    if (uncheckedSteps) {
      uncheckedSteps.forEach((step) => {
        step.checked = false;
        newStructure = updateTree(step.no, false, newStructure);
      });
    }
    return newStructure;
  }
  function getSteps(data) {
    if (Array.isArray(data))
      return data.map(item => {
        const iterScripts = script => {
          const iterScenarios = scenario => scenario.steps
          const iterFeatures = feature => feature.scenarios.map(iterScenarios).flat()
          return script.features.map(iterFeatures).flat()
        }
        return item.scripts.map(iterScripts).flat()
      }).flat()
    return data['scripts'].map(script => {
      return script['features'].map(feature => {
        return feature['scenarios'].map(scenario =>
          scenario['steps'].flat()
        ).flat()
      }).flat()
    }).flat()
  }

  function getScenarios(data) {
    if (Array.isArray(data))
      return data.map(item => {
        const iterScripts = script => {
          const iterScenarios = scenario => scenario.steps
          const iterFeatures = feature => feature.scenarios.map(iterScenarios).flat()
          return script.features.map(iterFeatures).flat()
        }
        return item.scripts.map(iterScripts).flat()
      }).flat()
    return data['scripts'].map(script => {
      return script['features'].map(feature => {
        return feature['scenarios'].flat()
      }).flat()
    }).flat()
  }

  function updateTree(no, checked, oldStructure = data) {
    let newStructure = Object.assign(oldStructure);
    const stepsForEach = (step) => {
      step.checked = checked
    }
    const scenariosForEach = (scenario) => {
      scenario.checked = checked;
      scenario.steps.forEach(stepsForEach)
    }
    const featuresForEach = (feature) => {
      feature.checked = checked;
      feature.scenarios.forEach(scenariosForEach)
    }
    const scriptsForEach = (script) => {
      script.checked = checked;
      script.features.forEach(featuresForEach)
    }
    let filterChecked = (item) => item.checked;
    if (newStructure.no === no) {
      newStructure.checked = checked
      newStructure.scripts.forEach(scriptsForEach)
    } else {
      newStructure.scripts.forEach((script) => {
        if (script.no === no) {
          script.checked = checked
          script.features.forEach(featuresForEach)
        } else {
          script.features.forEach((feature) => {
            if (feature.no === no) {
              feature.checked = checked
              feature.scenarios.forEach(scenariosForEach)
            } else {
              feature.scenarios.forEach((scenario) => {
                if (scenario.no === no) {
                  scenario.checked = checked
                  scenario.steps.forEach(stepsForEach)
                } else {
                  scenario.steps.forEach((step) => {
                    if (step.no === no) {
                      step.checked = checked
                    }
                  });
                }
                scenario.checked = scenario.steps.filter(filterChecked).length > 0
              });
            }
            feature.checked = feature.scenarios.filter(filterChecked).length > 0
          });
        }
        script.checked = script.features.filter(filterChecked).length > 0
      });
      newStructure.checked = newStructure.scripts.filter(filterChecked).length > 0
    }

    return newStructure;
  }

  const handleChangeCheckbox = (no, checked, isCustom = true) => {
    updateTree(no, checked)
    onSelectTreeItem(isCustom);
  }

  return (
    <StyledTree ending={endExecution ? true : false}>
      <Base
        key={1}
        item={data}
        execution={execution}
        endExecution={endExecution}
        getProjectStatus={getProjectStatus}
        onChangeCheckbox={handleChangeCheckbox}
      />
    </StyledTree>
  );
};
