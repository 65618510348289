import React from 'react'
import { STable, STBody, STBodyTR, STD, STH, STHead, STHeadTR, Container } from './styles'
import intl from 'react-intl-universal';

export default function DynamicResult({ params }) {

  function getInputValue(initial_value_in, status) {
    if (status === "NÃO EXECUTADO" || status === "NÃO SELECIONADO")
      return initial_value_in
    if (initial_value_in === '@user_input') {
      return ''
    }
    return initial_value_in
  };

  function getManualInput(initial_value_in, status, value_in) {
    if (value_in === '@user_input')
      return ''
    if (status === "NÃO EXECUTADO" || status === "NÃO SELECIONADO")
      return ''
    if (initial_value_in !== '@user_input')
      return ''
    return value_in
  };

  return (
    <Container>
      {params.find(i => i.type === "IN") && (
        <STable>
          <caption>Inputs</caption>
          <STHead>
            <STHeadTR>
              <STH children={intl.get("params.field")} />
              <STH children={intl.get("params.value")} style={{ marginLeft: '4vw' }} />
              <STH children={intl.get("params.input_manual")} />
            </STHeadTR>
          </STHead>
          <STBody>
            {params.map((item, index) => (
              item.type === "OUT" ? null :
                <STBodyTR key={index}>
                  <STD children={item.name} />
                  <STD children={getInputValue(item.initial_value_in, item.param_status)} />
                  <STD children={getManualInput(item.initial_value_in, item.param_status, item.value_in)} />
                </STBodyTR>
            ))}
          </STBody>
        </STable>
      )}
      {params.find(i => i.type === "OUT") && (
        <STable>
          <caption>Outputs</caption>
          <STHead>
            <STHeadTR>
              <STH children={intl.get("params.field")} />
              <STH children={intl.get("params.value")} style={{ marginLeft: '2vw' }} />
              <STH children={intl.get("params.received")} />
            </STHeadTR>
          </STHead>
          <STBody>
            {params.map((item, index) => (
              item.type === "IN" ? null :
                <STBodyTR key={index}>
                  <STD children={item.name} />
                  <STD children={item.value_out ? item.value_out : '-'} />
                  <STD children={item.received ? item.received : '-'} />
                </STBodyTR>
            ))}
          </STBody>
        </STable>
      )}
    </Container>
  )
}
