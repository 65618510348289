import styled from 'styled-components';

export const ButtonContainer = styled.button`
  outline: none;
  border:none;
  border-radius: .7vh;
  font-weight: bolder;
  color:white;
  background-color: ${(props) => props.bgColor};
  font-weight: 400;
  letter-spacing: 1px;
  height: 3.8vh;
  padding: 1vh 7px;
  min-width: 8vw;
  max-width: 8vw;
  box-shadow: 1px 1px 3px #0003;
  transition: 0.2s ease-in;
  font-size: 1.5vh;

  @media screen and (max-width: 1600px) {
    height: 4.8vh;
    ${props => props.execButton && 'min-width: 3.3vw; max-width: 3.3vw'}
  };

  ${props => props.execButton && 'min-width: 5.5vw; max-width: 3.3vw'}

  ${props => props.disabled ? '' : '&:hover { filter:brightness(0.92); transition: 0.4s ease-out; box-shadow: none }'}

  ${props => !props.disabled && 'cursor: pointer;'}
`