import React, { useEffect, useRef, useState } from 'react';

import { useForm } from 'react-hook-form';
import { api } from '../../../api';

import { AccessButton, Container, Form, InputContainer, InputTitle, LoginSide, ShowPassword, StyledInput, StyledInputContainer, WelcomeText } from './styles';

import showPassword from '../../../assets/showPasswordIconOn.svg';
import hiddenPassword from '../../../assets/showPasswordIconOff.svg';
import loginImage from '../../../assets/loginImage.svg';
import toast, { Toaster } from 'react-hot-toast';
import { EmailMessage } from '../styles';

function ResetPassword() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const { handleSubmit, register } = useForm();

  const onSubmit = async (data, e) => (
    e.preventDefault(),

    await api.post('auth/reset', { "password": data["password"], "confirmPassword": data["confirmPassword"], "reset_code": data["reset_code"] })
      .then(response => {
        localStorage.setItem("@production-dagobah-web", JSON.stringify(response.data.access_token));
        toast.success('Successfully Reset Password');
        setTimeout(() => {
          window.location = '/login';
        }, 1500)
      })
      .catch(() => {
        toast.error('Fail Reset Password')
      })
  )

  return (
    <Container id='container'>
      <Toaster position="top-right"
        toastOptions={{
          style: {
            marginTop: '2%'
          }
        }} />
      <LoginSide id='loginSide'>
        <Form
          id='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div
            id="titleAndConfirmContainer"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px'
            }}
          >
            <WelcomeText id='welcomeText'>
              ALTERAR SENHA
            </WelcomeText>

            <EmailMessage>
              Crie uma nova senha de acesso com no mínimo 6 caracteres.
            </EmailMessage>
          </div>

          <div>
            <InputContainer style={{ marginBottom: "2vh" }}>
              <InputTitle>
                Inserir código
              </InputTitle>
              <StyledInputContainer>
                <StyledInput
                  type="text"
                  style={{ paddingRight: '9%' }}
                  {...register("reset_code")}
                />
              </StyledInputContainer>
            </InputContainer>

            <InputContainer style={{ marginBottom: "2vh" }}>
              <InputTitle>
                Nova senha
              </InputTitle>
              <StyledInputContainer>
                <StyledInput
                  type={passwordVisible ? "text" : "password"}
                  style={{ paddingRight: '9%' }}
                  {...register("password")}
                />
                <ShowPassword
                  src={passwordVisible ? showPassword : hiddenPassword}
                  onClick={() => setPasswordVisible(!passwordVisible)}
                />
              </StyledInputContainer>
            </InputContainer>

            <InputContainer>
              <InputTitle>
                Confirmar senha
              </InputTitle>
              <StyledInputContainer>
                <StyledInput
                  type={confirmVisible ? "text" : "password"}
                  style={{ paddingRight: '9%' }}
                  {...register("confirmPassword")}
                />
                <ShowPassword
                  src={confirmVisible ? showPassword : hiddenPassword}
                  onClick={() => setConfirmVisible(!confirmVisible)}
                />
              </StyledInputContainer>
            </InputContainer>
          </div>

          <AccessButton
            id="accessButton"
            type="submit"
            value="Acessar"
          />
        </Form>
      </LoginSide>
      <img
        src={loginImage}
        style={{
          width: '50%',
          height: '100%'
        }}
      />
    </Container>
  );
}

export default ResetPassword;