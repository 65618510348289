import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  width: 100%;
  height: 100vh;
`;

export const LoginSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50%;
  height: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  width: 65vh;
  height: 75vh;
  padding: 10% 5% 20% 5%;

  background-color: #C8E6F0;
`;

export const WelcomeText = styled.span`
  display: flex;

  width: 100%;

  font-size: 2.5vh;
  font-weight: 600;
  color: #103F68;
  text-align: left;
`;

export const EmailMessage = styled.span`
  display: flex;

  width: 100%;

  font-size: 1.3vh;
  font-weight: 500;
  text-align: left;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const InputTitle = styled.span`
  display: flex;

  width: 100%;
  margin-bottom: 1vh;

  font-size: 1.3vh;
  color: #103F68;
  text-align: left;
`;

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  width: 100%;
  height: 4vh;

  border-radius: 5px;

  padding-left: 10px;
  padding-right: 10px;

  background-color: white;

  border: 1px solid #BBBBBB;
`;

export const StyledInput = styled.input`
  display: flex;

  width: 100%;

  border-radius: 5px;

  padding-right: 10px;

  outline: none;
`;

export const AccessButton = styled.input`
  width: 100%;
  height: 4vh;

  border: 0px solid transparent;
  border-radius: 5px;

  color: white;
  background-color: #5096BB;
  cursor: pointer;
`;