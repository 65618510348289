import styled from "styled-components";

export const Container = styled.div`

  display: flex;
  flex-direction: column;
  margin: 0 .7%;
`

export const STable = styled.table`
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    overflow: hidden;
	border-radius: 6px;
    border: 1px solid #eee;
    z-index: 0;
    caption {      
        margin: 5px 0;
        font-size: 14px;

        @media screen and (max-width: 2500px) {
          font-size: 1.2vw;
        }
    }
`;

export const STHead = styled.thead`
    position: sticky;
    z-index: 100;
	text-align: left;
    border-bottom:.1px solid #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const STHeadTR = styled.tr`
  background-color: #dddddd;
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const STH = styled.th`

    font-weight: normal;
    text-transform: capitalize;
    font-weight: 400;
	padding: 5px;
    font-size: 11px;

    @media screen and (max-width: 2500px) {
      font-size: 1vw;
    }
`;

export const STBody = styled.tbody`
     text-align: center;
    
`;

export const STBodyTR = styled.tr`
    display: flex;
    justify-content: space-around;
`;

export const STD = styled.td`
  font-size: 11px;
  background-color: white;
  padding: 10px;
  border-bottom:.1px solid #eeeeee;
  border-right: 1px solid #eee;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
  word-break: break-word;

  @media screen and (max-width: 2500px) {
    font-size: .8vw;
  }
`;