import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineLeftSquare, AiOutlineRightSquare } from "react-icons/ai";
import { CloseIcon, Container, InputContainer, Spinner, Table, Title, TitleContainer, UpdIcon, Wrapper } from './styles';
import { api } from '../../../api';
import { HiSortDescending } from "react-icons/hi";
import intl from "react-intl-universal";
import EmptyHome from '../../../components/Tables/EmptyTable/Home';
import SearchInput from '../../../components/Input/SearchInput';
import { ActionContainer } from '../../../components/Tables/TableRepos/styles';
import ModalAddDS from '../../../components/Modal/ModalDataSheet/ModalAddDS';
import ModalOffDatasheet from '../../../components/Modal/ModalDataSheet/ModalOffDatasheet';
import Success from '../../../components/Modal/Success';
import ErrorLog from '../../../components/Modal/ModalSync/ErrorLog';
import ModalUpdDS from '../../../components/Modal/ModalDataSheet/ModalUpdDS';
import { ToolTip } from '../../../components/ToolTip/Icon';
import ButtonActions from '../../../components/Button/ButtonActions';
import { useMediaQuery } from 'react-haiku';

export default function Datasheet() {

  const [repos, setRepos] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState('');
  const [pending, setPending] = useState(true);
  const [openAddDS, setOpenAddDS] = useState(false);
  const [openUpdDS, setOpenUpdDS] = useState(false);
  const [updItem, setUpdItem] = useState(null);
  const [openSucess, setOpenSucess] = useState(false);
  const [openSucess1, setOpenSucess1] = useState(false);
  const [openErrorLog, setOpenErrorLog] = useState(false);
  const [openErrorLog1, setOpenErrorLog1] = useState(false);
  const [openModalOffDatasheet, setOpenModalOffDatasheet] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [message, setMessage] = useState("");

  const isEdging = useMediaQuery('(max-width: 1400px)');

  const fetchData = async () => {
    try {
      const response = await api.get(`/datasheet`);
      setRepos(response.data.map((item) => {
        item.status = item.status ? `${intl.get("datasheet.active-status")}` : `${intl.get("datasheet.inactive-status")}`
        return item;
      }))

      setFiltered(response.data)
    } catch (error) {
      console.log(error)
    }

  };

  useEffect(() => {
    fetchData();
  }, []);

  const searchFilter = useCallback((e) => {

    e.preventDefault();

    const result = repos.filter((item) => {
      return item.name.toLowerCase().match(search.toLowerCase()) ||
        item.status.toString().match(search.toLowerCase()) ||
        item.type.toLowerCase().match(search.toLowerCase())
    });

    if (search != '') {
      setPending(true)
      const filtering = setTimeout(() => {
        setFiltered(result)
        setPending(false)
      }, 300)
      return () => clearTimeout(filtering)
    } else {
      setFiltered(result)
    };

    return filtered

  }, [search, repos]);

  function OpenModalOffDatasheet(row) {
    setOpenModalOffDatasheet(true);
    setSelectedItem(row);
  }

  function CloseOffDatasheet(row) {
    setOpenModalOffDatasheet(false);
    setSelectedItem(row);
  }

  const columns = [
    {
      name: intl.get('datasheet.name'),
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true
    },
    {
      name: intl.get('datasheet.type'),
      selector: (row) => row.type === "Padrão" ? intl.get("datasheet.standard") : intl.get("datasheet.editable"),
      sortable: true
    },
    {
      name: intl.get('home.actions'),
      selector: (row) =>
        row.type !== 'Editável' ? <></> : <ActionContainer>
          <ToolTip text={intl.get('datasheet.updateds')} children={
            <UpdIcon style={{ cursor: 'pointer', marginRight: 7 }} size={23} color={'#64c6c4'}
              onClick={() => { setUpdItem({ "id": row.id, "name": row.name, "status": row.status }); setOpenUpdDS(true); }} />
          } />
          <ToolTip text={intl.get('datasheet.deleteds')} children={
            <CloseIcon style={{ cursor: 'pointer', marginRight: 7 }} size={23} color={'#3b9ccf'}
              onClick={() => OpenModalOffDatasheet(row)} />
          } />
        </ActionContainer>,
      width: "100px"
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: '#eaf1f7',
        fontSize: isEdging ? 14 : 17,
      },
    },
    cells: {
      style: {
        fontSize: isEdging ? 11 : 12.5,
      },
    },
    subHeader: {
      style: {
        backgroundColor: 'transparent'
      }
    }
  };

  const paginationChanges = {
    rowsPerPageText: '',
    rangeSeparatorText: intl.get("datasheet.of"),
  }

  const closeAddDS = flag => {
    if (flag) {
      setOpenAddDS(false);
      setOpenSucess(true);
    } else {
      setOpenAddDS(false);
      setOpenErrorLog(true);
    }
  };

  const closeSucess = () => {
    setOpenSucess(false);
    fetchData();
  };

  const closeSucess1 = () => {
    setOpenSucess1(false);
    fetchData();
  };

  const closeErrorLog = () => {
    setOpenErrorLog(false);
  };

  const closeErrorLog1 = () => {
    setOpenErrorLog1(false);
  };

  const closeUpdDS = flag => {
    if (flag) {
      setOpenUpdDS(false);
      setOpenSucess1(true);
    } else {
      setOpenUpdDS(false);
      setOpenErrorLog1(true);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  const CustomLoader = () => (
    <div style={{ padding: '24px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Spinner />
      <div>{intl.get('loader.loading')}</div>
    </div>
  );

  return (
    <Container>
      <Title>Datasheet</Title>
      <Wrapper>
        <Table
          highlightOnHover={true}
          columns={columns}
          customStyles={customStyles}
          noDataComponent={<EmptyHome />}
          data={filtered}
          selectableRowsHighlight
          progressPending={pending}
          progressComponent={<CustomLoader />}
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20, 30]}
          paginationComponentOptions={paginationChanges}
          paginationIconFirstPage={<AiOutlineLeftSquare size={23} />}
          paginationIconLastPage={<AiOutlineRightSquare size={23} />}
          subHeader
          subHeaderComponent={
            <TitleContainer>
              <InputContainer>
                <SearchInput
                  type="text"
                  placeholder={intl.get('datasheet.search-filter')}
                  style={{
                    borderRadius: 5,
                    position: 'relative', right: '1.5%'
                  }}
                  value={search}
                  onSubmit={searchFilter}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <ButtonActions
                  text={intl.get('datasheet.add-button')}
                  bgColor={'#103f68'}
                  onClick={() => setOpenAddDS(true)}
                  style={{ minWidth: 130 }}
                />
              </InputContainer>
            </TitleContainer>
          }
          sortIcon={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 5,
                marginTop: 5
              }}>
              <HiSortDescending />
            </div>}
        />
      </Wrapper>
      <ModalAddDS
        isOpen={openAddDS}
        onRequestClose={closeAddDS}
        closeModal={() => setOpenAddDS(false)}
        title={intl.get("report.title-addDS")}
        setMessage={setMessage}
      />
      <ModalUpdDS
        item={updItem}
        isOpen={openUpdDS}
        onRequestClose={closeUpdDS}
        closeModal={() => setOpenUpdDS(false)}
        title={intl.get("report.title-updDS")}
        setMessage={setMessage}
      />
      <ModalOffDatasheet
        isOpen={openModalOffDatasheet}
        onRequestClose={CloseOffDatasheet}
        id={selectedItem ? selectedItem.id : null}
      />
      <Success
        isOpen={openSucess}
        onRequestClose={closeSucess}
        title={intl.get("report.title-addDS")}
        message={message}
      />
      <Success
        isOpen={openSucess1}
        onRequestClose={closeSucess1}
        title={intl.get("report.title-updDS")}
        message={message}
      />
      <ErrorLog
        isOpen={openErrorLog}
        onRequestClose={closeErrorLog}
        title1={intl.get("report.title-addDS")}
        message={message}
      />
      <ErrorLog
        isOpen={openErrorLog1}
        onRequestClose={closeErrorLog1}
        title1={intl.get("report.title-updDS")}
        message={message}
      />
    </Container>
  )
}