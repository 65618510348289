import React, { createContext, useEffect, useLayoutEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { api } from '../api';

export const AppContext = createContext()

export const AppProvider = ({ children }) => {

    const [authToken, setAuthToken] = useState(null)
    const [isUserLogged, setUserLoggedIn] = useState(false);
    const [name, setName] = useState("");
    const [tokenJira, setTokenJira] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [charge, setCharge] = useState("");
    const [type, setType] = useState("");
    const [status, setStatus] = useState(true);
    const [isTokenJira, setIsTokenJira] = useState('');
    const [sidebar, setSidebar] = useState(false);

    const handleLogin = async (data, e) => (
        e.preventDefault(),

        await api.post('/auth/login', `username=${data['username']}&password=${data['password']}`)
            .then(response => {
                localStorage.setItem("@production-dagobah-web", JSON.stringify(response.data.access_token));
                localStorage.setItem("@production-dagobah-web-user-type", JSON.stringify(response.data.user_type));
                toast.success('Successfully Logged In!');
                setTimeout(() => {
                    window.location = '/';
                }, 1500)
            })
            .catch((error) => {
                toast.error('Authentication Failed!')
            })
    );

    const handleLogout = () => {
        localStorage.removeItem("@production-dagobah-web")
        toast('Logging Out!', {
            icon: '👋',
        });
        setTimeout(() => {
            window.location = '/'
        }, 1500)
    };

    const getUserData = async (id) => {
        const [data, error] = await api.get(`/user/${id}`)
            .then(response => {
                const data = response.data;
                setIsTokenJira(data.token_jira)
                return [data, null];
            })
            .catch(error => {
                return [null, error];
            });

        if (!error) {
            setName(data?.name);
            setEmail(data?.email);
            setCompany(data?.id_company);
            setCharge(data?.id_job);
            setType(data?.id_type);
            setStatus(data?.active);
            setTokenJira(data?.token_jira);
        }
    };

    useEffect(() => {
        setUserLoggedIn(false);

        try {
            const token = JSON.parse(localStorage.getItem("@production-dagobah-web"))

            if (token) {
                setAuthToken(api.defaults.headers.Authorization = `Bearer ${token}`)
                setUserLoggedIn(true);
            } else {
                setAuthToken(authToken)
            }

        }
        catch (error) {
            console.log('Error: ', error)
        }

    }, []);

    useLayoutEffect(() => {
        api.interceptors.response.use(
            (response) => response, 
            (error) => {
              if (error.response && error.response.status === 401) {
                localStorage.removeItem("@production-dagobah-web")
                window.location = '/'
              } else {
                // This is important, so that unhandled cases make axios throw errors
                return Promise.reject(error);
              }
            });
    },[])

    return (
        <AppContext.Provider value={{
            handleLogin, handleLogout, isUserLogged, sidebar, setSidebar,
            name, tokenJira, email, company, charge, type, status, isTokenJira, authToken,
            setName, setEmail, setCompany, setCharge, setType, setStatus, setTokenJira, getUserData
        }}>
            <Toaster position="top-right"
                toastOptions={{
                    style: {
                        marginTop: '3%',
                        marginRight: '6%',
                        fontSize: 20
                    }
                }} />
            {children}
        </AppContext.Provider>
    )
}
