import React from 'react'
import TableUsers from '../../components/Tables/TableUsers'
import {
  Container,
  Content,
  Title
} from './styles'
import intl from "react-intl-universal"

export default function UserRegister() {
  return (
    <Container>
      <Title>{intl.get("users.user-register")}</Title>
      <Content>
        <TableUsers />
      </Content>
    </Container>
  )
}
