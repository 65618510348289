import React, { useState } from 'react';

import {
  AccessButton, Container, EmailMessage, FormContainer,
  InputContainer, InputTitle, StyledInput, Title
} from './styles';

import loginImage from '../../assets/loginImage.svg';

import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { api } from '../../api';

import toast from 'react-hot-toast';

function ConfirmRegister() {
  const { id } = useParams();

  const [showTextInputs, setShowTextInputs] = useState(false);

  const { handleSubmit, register } = useForm();

  const onSubmit = async (data, e) => {
    e.preventDefault();

    const body = {
      "password": data["newPassword"],
      "confirmPassword": data["confirmPassword"]
    };

    await api.post(`auth/${id}/confirm`, body)
      .then(() => {
        toast.success("Password Successfully Registration!");
        setTimeout(() => {
          window.location = "/login";
        }, [2000]);
      })
      .catch(error => {
        toast.error(error);
      });
  };

  return (
    <Container id="container">
      <img
        src={loginImage}
        style={{
          width: '50%',
          height: '100%'
        }}
      />

      <div
        style={{
          display: "flex",
          width: "50%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <FormContainer
          id="actionContainer"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div
            id="titleAndConfirmContainer"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px'
            }}
          >
            <Title>
              Confirmar Cadastro
            </Title>

            <EmailMessage>
              Email confirmado. Por favor, criar uma senha para autenticação.
            </EmailMessage>
          </div>

          <div
            id="inputsContainer"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '3vh'
            }}
          >
            <InputContainer>
              <InputTitle>
                Criar uma senha (Mínimo de 6 caracteres)
              </InputTitle>
              <StyledInput
                id="newPasswordInput"
                type={showTextInputs === false ? "password" : "text"}
                {...register("newPassword")}
              />
            </InputContainer>

            <InputContainer>
              <InputTitle>
                Confirmar Senha
              </InputTitle>
              <StyledInput
                id="confirmPasswordInput"
                type={showTextInputs === false ? "password" : "text"}
                {...register("confirmPassword")}
              />
            </InputContainer>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "1vh"
              }}
            >
              <input
                type="checkbox"
                checked={showTextInputs}
                onChange={() => setShowTextInputs(!showTextInputs)}
                style={{
                  cursor: "pointer"
                }}
              />
              <InputTitle style={{ marginBottom: 0 }}>
                Mostrar Senha
              </InputTitle>
            </div>
          </div>

          <AccessButton
            type="submit"
            value="Salvar"
          />
        </FormContainer>
      </div>
    </Container>
  );
}

export default ConfirmRegister;