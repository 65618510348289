import React from 'react'
import Modal from 'react-modal';
import { Container, HeaderModal, NextOrder, SubTitle, Title, Wrapper } from './styles';
import { BsQuestionCircle } from "react-icons/bs";
import ButtonActions from '../../../Button/ButtonActions';
import { IoCloseSharp } from "react-icons/io5";
import intl from "react-intl-universal";

export default function ModalConfirm({ isOpen, onRequestClose, title, subtitle, onConfirmClick, onCancelClick }) {


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldFocusAfterRender={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content">
      <Container>
        <HeaderModal>
          <Title>{title}</Title>
          <IoCloseSharp
            style={{ cursor: 'pointer', marginRight: 7 }}
            size={18}
            onClick={onRequestClose} />
        </HeaderModal>
        <NextOrder>
          <BsQuestionCircle
            style={{ cursor: 'pointer', marginRight: 7, marginBottom: 10 }}
            size={40}
            color={'#ddba84'}
            onClick={onRequestClose} />
          <SubTitle>{subtitle}</SubTitle>
        </NextOrder>
        <Wrapper>
          <ButtonActions
            bgColor={'#104068'}
            text={intl.get("repository.yes")}
            onClick={onConfirmClick} />
          <ButtonActions
            bgColor={'#ed6663'}
            text={intl.get("repository.cancel")}
            onClick={onCancelClick} />
        </Wrapper>
      </Container>
    </Modal>
  )
}
