import React from "react";
import Home from "../pages/Home";
import Projects from "../pages/Projects";
import Repo from "../pages/Repository";
import ExecProj from "../pages/Projects/Exec";
import Navbar from "../components/Navbar";
import NotFound from "../pages/NotFound";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Outlet,
  Navigate,
} from "react-router-dom";
import DataSheet from "../pages/Report/DataSheet";
import OverView from "../pages/Report/Overview";
import Reports from "../pages/Report";
import Compare from "../pages/Report/Compare";
import { useTransition, animated } from "react-spring";
import UserRegister from "../pages/UserRegister";
import { getUserType } from "../api";
import Login from "../pages/Login";
import ConfirmRegister from "../pages/ConfirmRegister";
import PasswordRecovery from "../pages/PasswordRecovery";
import ResetPassword from "../pages/PasswordRecovery/ResetPassword";
import { useAuth } from "../hooks/useAuth";
import Header from "../components/Header";

const AnimationLayout = () => {
  const { setSidebar } = useAuth();
  const location = useLocation();
  const transitions = useTransition(location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    config: { duration: 400 },
  });
  return (
    <>
      {transitions((styles) => (
        <animated.div onClick={() => setSidebar(false)} style={styles}>
          <Outlet />
        </animated.div>
      ))}
    </>
  );
};

const Component = () => {
  if (getUserType().toLowerCase() === "admin") {
    return <UserRegister />;
  }
  return <NotFound />;
};

const PrivateRoutes = () => {
  const { isUserLogged } = useAuth();

  return isUserLogged ? <Outlet /> : <Navigate to="/login" />;
};

function AppRoutes() {
  const { isUserLogged } = useAuth();

  return (
    <Router>
      {isUserLogged ? <Navbar /> : <Header />}
      <Routes>
        <Route element={<AnimationLayout />}>
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Home />} />
            <Route path="/projects" element={<Projects />} />
            <Route
              path="/projects/execute-project/:ID/:method"
              element={<ExecProj />}
            />
            <Route path="/repository" element={<Repo />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/reports/comparing/:id1/:id2" element={<Compare />} />
            <Route path="/reports/datasheet" element={<DataSheet />} />
            <Route path="/reports/data/:id" element={<OverView />} />
            <Route path="/user-register" element={<Component />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Login />} />
          <Route path="/confirm_account/:id" element={<ConfirmRegister />} />
          <Route path="/recovery_password" element={<PasswordRecovery />} />
          <Route path="/reset_password" element={<ResetPassword />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default AppRoutes;
