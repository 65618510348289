import styled, { keyframes } from 'styled-components';

const Spinner = keyframes`  
  0% {
    transform: rotate(0deg); 
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
    width: 100px;
    height: 100px;
    top: 0;
    left: 0;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.75);
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);

    backdrop-filter: blur(15px);
`

export const Loading = styled.div`
   
    background:linear-gradient(#32528d, #330d69, #31a8bc);
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 10px),#fff 0);
            mask:radial-gradient(farthest-side,#0000 calc(100% - 10px),#fff 0);
    border-radius: 50%;  
    position: fixed;
    inset : calc(50% - 40px);
    animation: ${Spinner} 2s linear infinite;
`

