export const EXECUTION_STATUS = {
    STS_MAIN_FIRST_STATUS: 0
    , STS_MAIN_CREATE_EXECUTION: 1
    , STS_MAIN_INIT_QUEUE: 2
    , STS_MAIN_GET_NEXT_STEP: 3
    , STS_MAIN_EXECUTE_STEP: 4
    , STS_MAIN_END_EXECUTION: 5
    , STS_ALT_WAIT_INPUT: 6
    , STS_ALT_CONFIRM_VALUE: 7
    , STS_ALT_PAUSE_EXECUTION: 8
  }