import React from 'react';
import { Container, SubTitle, Title, Wrapper } from './styles'
import intl from 'react-intl-universal';

export default function NotFound() {
  return (
    <Container>
        <Wrapper>
            <Title>404</Title>
            <SubTitle>{intl.get('404.message')}</SubTitle>
        </Wrapper>
    </Container>
  )
}
