import React from "react";
import CardStatus from "../../Status/Report/Tree/CardStatus";
import {
  ContainerInfo,
  ContainerWrapper,
  DeepInfo,
  InfoWrapper,
  TitleProject,
} from "./styles";
import intl from "react-intl-universal";

export default function ProjectInfo({
  id,
  start_date,
  end_date,
  time,
  override_confirm,
  repository_name,
  repository_type,
  status,
  name,
  description,
  column,
}) {
  return (
    <InfoWrapper>
      <TitleProject>{name}</TitleProject>
      <ContainerWrapper>
        <DeepInfo column={column}>
          <ContainerInfo>
            <span>ID: </span>
            <br />
            {id}
          </ContainerInfo>
          <ContainerInfo>
            <span>{intl.get("report.start_at")}</span>
            <br />
            {start_date}
          </ContainerInfo>
          <ContainerInfo>
            <span>{intl.get("report.end_at")}</span>
            <br />
            {end_date}
          </ContainerInfo>
          <ContainerInfo>
            <span>{intl.get("report.time")}</span>
            <br />
            {time}
          </ContainerInfo>
          <ContainerInfo>
            <span>Override Confirm:</span>
            <br />
            {override_confirm}
          </ContainerInfo>
          <ContainerInfo>
            <span>{intl.get("report.repository")}</span>
            <br />
            {repository_name}
          </ContainerInfo>
          <ContainerInfo>
            <span>{intl.get("report.type")}</span>
            <br />
            {repository_type}
          </ContainerInfo>
          {column && (
            <ContainerInfo>
              <span>{intl.get("report.description")}</span>
              <br />
              {description}
            </ContainerInfo>
          )}
        </DeepInfo>
        <div style={{ marginLeft: 10, position: "relative", left: 6 }}>
          <CardStatus status={status} />
        </div>
      </ContainerWrapper>
      {!column && (
        <ContainerInfo>
          <span>{intl.get("report.description")}</span>
          <br />
          {description}
        </ContainerInfo>
      )}
    </InfoWrapper>
  );
}
