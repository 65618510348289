import { Fragment, useState } from "react";

export const ToolTip = ({ children, text }) => {

    const [showTT, setShowTT] = useState(false);

    return (
        <Fragment>
            <div className="tooltip" style={showTT ? { visibility: "visible" } : {}}>
                {text}
            </div>
            <div
                onMouseEnter={() => setShowTT(true)}
                onMouseLeave={() => setShowTT(false)}>
                {children}
            </div>
        </Fragment>
    )
};