import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import moment from "moment";
import { api } from "../../../api";
import { ToolTip } from "./../../ToolTip/Icon";
import {
  ActionContainer,
  ButtonContainer,
  ButtonActionsContainer,
  Container,
  HeaderContainer,
  InputContainer,
  Table,
  SearchContainer,
  UpdIcon,
  CloseIcon,
  Spinner,
} from "./styles";
import ButtonActions from "../../Button/ButtonActions";
import SearchInput from "../../Input/SearchInput";
import ModalAddGit from "./../../Modal/ModalGit/ModalAddGit";
import ModalUpdGit from "../../Modal/ModalGit/ModalUpdGit";
import {
  AiOutlineEdit,
  AiOutlineLeftSquare,
  AiOutlineRightSquare,
  AiOutlineWarning,
} from "react-icons/ai";
import ModalOffGit from "../../Modal/ModalGit/ModalOffGit";
import ModalAlert from "../../Modal/ModalGit/ModalAlert";
import ModalConfirm from "../../Modal/ModalGit/ModalConfirm";
import ErrorLog from "./../../Modal/ModalSync/ErrorLog/index";
import ModalErrorImportGit from "./../../Modal/ModalGit/ModalErrorImportGit";
import EmptyRepos from "./../EmptyTable/Repos";
import UpdDisable from "./../../Modal/ModalGit/ModalUpdGit/UpdDisable";
import { HiSortDescending } from "react-icons/hi";
import Success from "../../../components/Modal/Success";
import FailDelete from "../../Modal/ModalGit/Fail";
import Loader from "../../Loader";
import SuccessModal from "../../Modal/ModalGit/Success";
import intl from "react-intl-universal";
import Status100 from "../../Status/Repository/Status100";
import Status200 from "../../Status/Repository/Status200";
import Status201 from "../../Status/Repository/Status201";
import Status409 from "../../Status/Repository/Status409";
import { useMediaQuery } from "react-haiku";

Modal.setAppElement("#root");

export default function TableRepos() {
  const [repos, setRepos] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState("");
  const [openModalAddGit, setOpenModalAddGit] = useState(false);
  const [errorAddMsg, setErrorAddMsg] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openDouble, setOpenDouble] = useState(false);
  const [openModalUpdGit, setOpenModalUpdGit] = useState(false);
  const [openModalOffGit, setOpenModalOffGit] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openErrorLog, setErrorLog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [checked, setChecked] = useState(false);
  const [errorImportMessage, setErrorImportMessage] = useState("");
  const [openImportError, setOpenImportError] = useState(false);
  const [openModalAlertSync, setOpenModalAlertSync] = useState(false);
  const [openUpdDisable, setOpenUpdDisable] = useState(false);
  const [isOpenModalSuccess, setIsOpenModalSuccess] = useState(false);
  const [openModalFail, setFailModal] = useState(false);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [msgErrorSync, setMsgErrorSync] = useState("");
  const [zipFile, setZipFile] = useState("");
  const [titleErrorSync, setTitleErrorSync] = useState();
  const [gitItems, setGitItem] = useState([]);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [pending, setPending] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  const isEdging = useMediaQuery("(max-width: 1400px)");

  function openAddGit() {
    setOpenModalAddGit(true);
  }

  function closeAddGit(e) {
    e.preventDefault();
    setOpenModalAddGit(false);
    fetchData();
  }

  async function closeSuccessModal() {
    setOpenSuccess(false);
    const repos = await api.get("/repository");
    setFiltered(repos.data.items);
    fetchData();
  }

  function openDoubleModal() {
    setOpenDouble(true);
  }

  function closeDoubleModal(e) {
    e.preventDefault()
    setOpenDouble(false);
  }

  function openUpdGit(row) {
    setOpenModalUpdGit(true);
    setSelectedItem(row);
  }

  async function closeUpdGit(row) {
    setOpenModalUpdGit(false);
    setSelectedItem(row);
    const repos = await api.get("/repository");
    setFiltered(repos.data.items);
    fetchData();
  }

  function openModalDisable(row) {
    setOpenUpdDisable(true);
    setSelectedItem(row);
  }

  async function closeUpdDisable(row) {
    setOpenUpdDisable(false);
    setSelectedItem(row);
    const repos = await api.get("/repository");
    setFiltered(repos.data.items);
    fetchData();
  }

  function openOffGit(row) {
    setOpenModalOffGit(true);
    setSelectedItem(row);
  }

  function closeOffGit(e, row) {
    e.preventDefault()
    setOpenModalOffGit(false);
    setSelectedItem(row);
    setSelectedRows([])
    fetchData();
  }

  function closeConfirm() {
    setOpenModalConfirm(false);
  }

  function openFailModal(row) {
    setFailModal(true);
  }

  function closeFailModal(row) {
    setFailModal(false);
  }

  function openErrorModal(row) {
    console.log(row)
    setErrorImportMessage("");
    setMsgErrorSync(row.error);
    setTitleErrorSync(intl.get("sync-projects.sync-fail"));
    setOpenImportError(true);
  }

  function submitSync() {
    setIsShowLoader(true);

    api
      .post("repository/sync", { items: selectedRows.map(item => item.id) })
      .then(() => {
        setIsShowLoader(false);
        setOpenModalAlertSync(true);
      })
      .catch((err) => {
        setIsShowLoader(false);
        openErrorModal(intl.get("sync-projects.sync-fail"));
      });
  }

  function closeAlertSync(e) {
    setOpenModalAlertSync(false);
    fetchData();
  }

  function closeErrorModal(row) {
    setErrorLog(false);
    setSelectedItem(row);
  }

  function closeImportErrorModal(e) {
    e.preventDefault();
    setOpenImportError(false);
  }

  function closeSuccessModal(e) {
    e.preventDefault();
    setIsOpenModalSuccess(false);
  }

  function openModalError() {
    setOpenError(true);
  }

  function closeModalError(e) {
    e.preventDefault();
    setOpenError(false);
  }

  const columns = [
    {
      width: "60px",
      selector: (row) => (
        <input
          type="checkbox"
          checked={selectedRows.find((sRow) => sRow.id === row.id) || false}
          onChange={() => handleRowClicked(row)}
        />
      ),
    },
    {
      name: intl.get("repository.type"),
      selector: (row) => row.type,
      sortable: true,
      width: "100px",
    },
    {
      name: intl.get("repository.name"),
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "URL",
      selector: (row) => row.url,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => {
        if (row.status === "CADASTRADO") {
          return <Status200 text={row.status} />;
        } else if (row.status === "SINCRONIZADO") {
          return <Status201 text={row.status} />;
        } else if (row.status === "EM SINCRONIZAÇÃO") {
          return <Status100 text={row.status} />;
        } else if (row.status === "FALHA") {
          return <Status409 text={row.status} />;
        } else if (row.status === "-") {
          return <h1>—</h1>;
        }
      },
      sortable: true,
    },
    {
      name: intl.get("repository.update-date"),
      selector: (row) => moment(row.updated_at).format("DD/MM/YYYY - HH:mm:ss"),
      sortable: true,
    },
    {
      name: intl.get("repository.actions"),
      selector: (row) => {
        return (
          <ActionContainer>
            <ToolTip
              text={intl.get("repository.update-repo")}
              children={
                row.status === "CADASTRADO" ? (
                  <UpdIcon onClick={() => openUpdGit(row)} />
                ) : row.status === "SINCRONIZADO" ? (
                  <UpdIcon onClick={() => openModalDisable(row)} />
                ) : row.status === "EM SINCRONIZAÇÃO" ? (
                  <UpdIcon onClick={() => openModalDisable(row)} />
                ) : row.status === "FALHA" ? (
                  <UpdIcon onClick={() => openUpdGit(row)} />
                ) : (
                  ""
                )
              }
            />
            <ToolTip
              text={intl.get("repository.delete-repo")}
              children={
                row.status === "CADASTRADO" ? (
                  <CloseIcon onClick={() => openOffGit(row)} />
                ) : row.status === "SINCRONIZADO" ? (
                  <CloseIcon onClick={() => openFailModal(row)} />
                ) : row.status === "EM SINCRONIZAÇÃO" ? (
                  <CloseIcon onClick={() => openFailModal(row)} />
                ) : row.status === "FALHA" ? (
                  <CloseIcon onClick={() => openOffGit(row)} />
                ) : row.status === "-" ? (
                  <CloseIcon onClick={() => openOffGit(row)} />
                ) : (
                  ""
                )
              }
            />
          </ActionContainer>
        );
      },
      width: "100px",
    },
    {
      name: intl.get("repository.errors"),
      selector: (row) => {
        if (row.status === "FALHA") {
          return (
            <AiOutlineWarning
              style={{ cursor: "pointer", marginRight: 7 }}
              size={23}
              color={"#dea24d"}
              onClick={() => openErrorModal(row)}
            />
          );
        } else {
          return null;
        }
      },
      width: "80px",
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#eaf1f7",
        fontSize: isEdging ? 14 : 17,
      },
    },
    cells: {
      style: {
        fontSize: isEdging ? 11 : 12.5,
        cursor: "pointer",
      },
    },
  };

  const searchFilter = useCallback(
    (e) => {
      e.preventDefault();

      const result = repos.filter((item) => {
        return (
          item.type.toLowerCase().match(search.toLowerCase()) ||
          item.name.toLowerCase().match(search.toLowerCase()) ||
          item.url.toLowerCase().match(search.toLowerCase()) ||
          item.status.toLowerCase().match(search.toLowerCase()) ||
          moment(item.updated_at)
            .format("DD/MM/YYYY - HH:mm:ss")
            .match(search.toString())
        );
      });

      if (search != "") {
        setPending(true);
        const filtering = setTimeout(() => {
          setFiltered(result);
          setPending(false);
        }, 300);
        return () => clearTimeout(filtering);
      } else {
        setFiltered(result);
      }

      return filtered;
    },
    [search, repos]
  );

  const fetchData = async () => {
    try {
      const response = await api.get("/repository");
      setRepos(response.data.items);
      setFiltered(response.data.items);
    } catch (error) {
      if (error.response.status >= 400) {
        setErrorMsg(error.response.data.detail);
        openModalError();
        closeDoubleModal();
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const paginationChanges = {
    rowsPerPageText: "",
    rangeSeparatorText: intl.get("repository.of"),
  };

  useEffect(() => {
    if (zipFile) {
      let zipName = zipFile.name;
      let zipExists = false;
      repos.forEach((repo) => {
        if (repo.name == zipName) {
          zipExists = true;
        }
      });
      if (zipExists) {
        setOpenModalConfirm(true);
        return;
      }
      handleInsertLocalRepo();
    }
  }, [zipFile]);

  const changeInputFile = (event) => {
    let zipFile = event.target.files[0];
    setZipFile(zipFile);
  };

  function handleInsertLocalRepo() {
    let formData = new FormData();
    formData.append("zipfile", zipFile);
    setIsShowLoader(true);
    api
      .post("repository/local", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp, arg1) => {
        fetchData();
        closeConfirm();
        setIsOpenModalSuccess(true);
        setIsShowLoader(false);
        document.getElementById("addLocalInput").value = "";
      })
      .catch((err) => {
        closeConfirm();
        setIsShowLoader(false);
        setTitleErrorSync(intl.get("import-local-directory.import-summary"));
        if (err.response.status === 400) {
          setErrorLog(true);
          setErrorMsg(
            intl.get("import-local-directory.project-files-not-found")
          );
        }
        if (err.response.status === 412) {
          setErrorImportMessage(err.response.data);
          setOpenImportError(true);
        }
        if (err.response.status === 500) {
          setErrorLog(true);
          setErrorMsg(
            intl.get("import-local-directory.project-files-not-found")
          );
        }
        document.getElementById("addLocalInput").value = "";
      });
  }

  const handleChange = (state) => {
    setChecked(state.selectedCount);
    const IDs = [];

    for (var i = 0; i < state.selectedRows.length; i++) {
      if (state.selectedRows[i]["type"] === "GIT") {
        IDs.push(state.selectedRows[i]["id"]);
      } else {
        setChecked(false);
      }
    }
    setGitItem(IDs);
  };

  const handleRowClicked = (row) => {
    const find = selectedRows.find((sRow) => sRow.id === row.id);
    if (find) {
      setSelectedRows(selectedRows.filter((sRow) => sRow.id !== find.id));
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };

  useEffect(() => {
    setChecked(false);
    selectedRows.map((i) => {
      return i.type === "GIT" ? setChecked(true) : setChecked(false);
    });
  }, [selectedRows]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  const CustomLoader = () => (
    <div
      style={{
        padding: "24px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Spinner />
      <div>{intl.get("loader.loading")}</div>
    </div>
  );

  const conditionalRowStyles = [
    {
      when: () => true,
      style: (row) => ({
        backgroundColor:
          selectedRows.find((sRow) => sRow.id === row.id) && "#e3f2fd",
      }),
    },
  ];

  return (
    <Container>
      <Table
        highlightOnHover
        columns={columns}
        customStyles={customStyles}
        noDataComponent={<EmptyRepos />}
        data={filtered}
        onRowClicked={handleRowClicked}
        conditionalRowStyles={conditionalRowStyles}
        progressPending={pending}
        progressComponent={<CustomLoader />}
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10, 15, 20, 30]}
        paginationComponentOptions={paginationChanges}
        paginationIconFirstPage={<AiOutlineLeftSquare size={23} />}
        paginationIconLastPage={<AiOutlineRightSquare size={23} />}
        subHeader
        subHeaderComponent={
          <HeaderContainer>
            <InputContainer>
              <SearchContainer>
                <SearchInput
                  type="text"
                  placeholder={intl.get("home.search")}
                  style={{ borderRadius: 5, position: "relative", left: ".7%" }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onSubmit={searchFilter}
                  // local filter
                />
              </SearchContainer>
              <ButtonContainer>
                <ButtonActionsContainer>
                  <ButtonActions
                    bgColor={checked ? "#408bb3" : "#797979"}
                    text={intl.get("repository.synchronize")}
                    onClick={submitSync}
                    disabled={checked ? false : true}
                  />
                  <ButtonActions
                    bgColor={"#63c5c4"}
                    text={intl.get("repository.add-location")}
                    onClick={() => {
                      document.getElementById("addLocalInput").value = "";
                      document.getElementById("addLocalInput").click();
                    }}
                  />
                  <input
                    onChange={changeInputFile}
                    type="file"
                    id="addLocalInput"
                    style={{ display: "none" }}
                    accept=".zip"
                  />
                  <ButtonActions
                    bgColor={"#01294d"}
                    text={intl.get("repository.add-git")}
                    onClick={openAddGit}
                  />
                </ButtonActionsContainer>
                <Success
                  isOpen={isOpenModalSuccess}
                  onClickCloseModal={(e) => closeSuccessModal(e)}
                  title={intl.get("repository.repositories")}
                  message={intl.get(
                    "import-local-directory.repository-imported-successfully"
                  )}
                />
                <ModalAddGit
                  isOpen={openModalAddGit}
                  onRequestClose={closeAddGit}
                  handleWarn={openDoubleModal}
                  setMessage={setErrorAddMsg}
                  setIsShowLoader={setIsShowLoader}
                />
                <SuccessModal
                  isOpen={openSuccess}
                  closeModal={closeSuccessModal}
                  title={intl.get("projects.repository")}
                  message={intl.get(
                    "import-local-directory.repository-imported-successfully"
                  )}
                />
                <ErrorLog
                  isOpen={openDouble}
                  onRequestClose={closeDoubleModal}
                  title={
                    errorAddMsg ===
                    "Operação não realizada. Repositório já cadastrado"
                      ? intl.get("import-local-directory.error-msg")
                      : intl.get("import-local-directory.error-msg")
                  }
                  title1={intl.get("projects.repository")}
                  message={
                    errorAddMsg ===
                    "Operação não realizada. Repositório já cadastrado"
                      ? intl.get(
                          "import-local-directory.repository-already-registered"
                        )
                      : errorAddMsg
                  }
                />
                <ModalUpdGit
                  isOpen={openModalUpdGit}
                  onRequestClose={closeUpdGit}
                  id={selectedItem ? selectedItem.id : null}
                  item={selectedItem}
                />
                <UpdDisable
                  isOpen={openUpdDisable}
                  onRequestClose={closeUpdDisable}
                  id={selectedItem ? selectedItem.id : null}
                  item={selectedItem}
                />
                <FailDelete
                  isOpen={openModalFail}
                  onRequestClose={closeFailModal}
                />
                <ModalConfirm
                  title={intl.get(
                    "import-local-directory.repository-already-imported-title"
                  )}
                  subtitle={intl.get(
                    "import-local-directory.repository-already-imported-subtitle"
                  )}
                  isOpen={openModalConfirm}
                  onRequestClose={closeConfirm}
                  onConfirmClick={(button) => {
                    button.preventDefault();
                    handleInsertLocalRepo();
                  }}
                  onCancelClick={(button) => {
                    closeConfirm();
                  }}
                />
                <ModalOffGit
                  isOpen={openModalOffGit}
                  onRequestClose={closeOffGit}
                  id={selectedItem ? selectedItem.id : null}
                />
                <ErrorLog
                  isOpen={openErrorLog}
                  onRequestClose={closeErrorModal}
                  message={errorMsg}
                  title1={intl.get("projects.repository")}
                  id={selectedItem ? selectedItem.id : null}
                />
                <ErrorLog
                  isOpen={openError}
                  onRequestClose={closeModalError}
                  title={intl.get(
                    "import-local-directory.directory-import-failed"
                  )}
                  title1={intl.get("projects.repository")}
                  message={errorMsg}
                />
                <ModalAlert
                  isOpen={openModalAlertSync}
                  onRequestClose={closeAlertSync}
                />
                <ModalErrorImportGit
                  isOpen={openImportError}
                  errors={errorImportMessage}
                  msgErrorSync={msgErrorSync}
                  titleErrorSync={titleErrorSync}
                  onRequestClose={closeImportErrorModal}
                />
              </ButtonContainer>
            </InputContainer>
          </HeaderContainer>
        }
        sortIcon={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: 5,
              marginTop: 5,
            }}
          >
            <HiSortDescending />
          </div>
        }
      />
      <Loader isShow={isShowLoader} />
    </Container>
  );
}
