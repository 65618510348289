import styled from 'styled-components';

export const Container = styled.div`

 background-color: red;
 width: 40%;
 min-width: 100px;
 border-radius: 10px;
 cursor: pointer;
 display: flex;
 justify-content: center;
`

export const Title = styled.p`

 padding: 3px 2px;
 color: white;
 font-size: 1.1vh;
`