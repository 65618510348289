import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import {
  CardContainer, Container, Title, ChartWrapper, InfoContainer,
  DatasheetWrapper, DatasheetItem, DatasheetHeader,
  DatasheetContent, DatasheetHeaderContainer, PDFTitle, IconView, Spinner, LoadingScreen
} from './styles';
import { api } from "../../api";
import ReportTree from '../Tree/ReportTree';
import ProjectInfo from '../Cards/ProjectReportInfo';
import moment from 'moment';
import Indicator from '../Cards/Indicator';
import autoAnimate from '@formkit/auto-animate';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import DonutCards from '../Cards/DonutsCards';
import ModalBugRegister from '../Modal/ModalBugRegister';
import intl from 'react-intl-universal';
import { useMediaQuery } from 'react-haiku';

export default function Charts({ id, collapsible = true, reportInfo, reportFields, title, column, row }) {

  const [dataSheetItems, setDataSheetItems] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const [openBugRegisterModal, setOpenBugRegisterModal] = useState(false);
  const [bugIndex, setBugIndex] = useState(true);
  const [info, setInfo] = useState([]);
  const [project, setProject] = useState({
    name: '',
    description: '',
    start_date: '',
    end_date: '',
    elapsed_time: '',
    override_confirm: '',
    repository_name: '',
    repository_type: '',
    time: ''
  });
  const [scriptLength, setScriptLength] = useState('');
  const [scenarioLength, setScenarioLength] = useState('');
  const [featureLength, setFeatureLength] = useState('');
  const [stepLength, setStepLength] = useState('');
  const [jiraKey, setJiraKey] = useState(null);
  const [bugStructId, setBugStructId] = useState(0);
  const [isCleaningModal, setCleaningModal] = useState(false);
  const [generalStatus, setGeneralStatus] = useState('');
  const [pending, setPending] = useState(true)

  const isEdging = useMediaQuery('(max-width: 1600px)');

  const addToBugList = (index, url) => {
    for (var i = 0; i < info.length; i++) {
      for (var j = 0; j < info[i].children.length; j++) {
        for (var k = 0; k < info[i]['children'][j]['children'].length; k++) {
          if (index === k) {
            info[i]['children'][j]['children'][k].bug_url = url;
          }
        }
      }
    }

    setInfo(info)
  };

  const closeBugRegisterModal = () => {
    setOpenBugRegisterModal(false);
  }

  const openBugRegister = (index, id) => {
    setBugStructId(id)
    setBugIndex(index);
    setOpenBugRegisterModal(true);
    setCleaningModal(true);
  }

  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current)
  }, [parent]);

  function getFormattedDate(date, format = 'DD/MM/YYYY - HH:mm:ss') {
    if (date === null || date === undefined) {
      return '-';
    }
    return moment(date).format(format)
  }

  function getTime(time) {
    if (time === null || time === undefined) {
      return '-'
    }
    return time
  }

  useEffect(() => {
    if (reportFields) {
      setDataSheetItems(reportFields);
    }
  }, [reportFields]);

  function mergeParams(baseParams, params) {
    return baseParams.map((base, index) => {
      const parameter = params[index]
      if(base['name'] === 'szValue'){
        console.log('aqui')
      }
      if(!base['value_in'].startsWith('$')){
        parameter['value_in'] = base['value_in']
      }
      if(!base['value_out'].startsWith('$')){
        parameter['value_out'] = base['value_out']
      }
      base['initial_value_in'] = parameter['value_in']
      base['initial_value_out'] = parameter['value_out']
      return base
    })
  };
  

  useEffect(() => {
    const fetchJiraKey = async () => {
      try {
        const response = await api.get(`/session/info`);
        setJiraKey(response.data.token_jira)
      } catch (error) {
        console.log(error)
      }
    };
    fetchJiraKey()
  }, [])

  useEffect(() => {
    if (reportInfo && reportInfo.length > 0) {
      setProject(reportInfo[0]);
      setGeneralStatus(reportInfo[0].struct_status);
      const formatInfo = reportInfo[0].children?.map((script) => ({
        "id": `${script.id}`,
        "name": `${script.name}`,
        "type": "SCRIPT",
        "skip": script.skip,
        "struct_status": `${script.struct_status}`,
        "type_value": `${script.type_value}`,
        "start_date": `${getFormattedDate(script.start_date)}`,
        "end_date": `${getFormattedDate(script.end_date)}`,
        "elapsed_time": `${getTime(script.elapsed_time)}`,
        "children":
          script.children.map((feature) => ({
            "id": `${feature.id}`,
            "name": `${feature.name}`,
            "type": "FEATURE",
            "elapsed_time": `${getTime(feature.elapsed_time)}`,
            "struct_status": `${feature.struct_status}`,
            "type_value": `${feature.type_value}`,
            "skip": feature.skip,
            "children":
              feature.children.map((scenario) => ({
                "id": `${scenario.id}`,
                "name": `${scenario.name}`,
                "type": "SCENARIO",
                "elapsed_time": `${getTime(scenario.elapsed_time)}`,
                "struct_status": `${scenario.struct_status}`,
                "type_value": `${scenario.type_value}`,
                "bug_id": scenario.bug_id,
                "bug_url": scenario.bug_url,
                "skip": scenario.skip,
                "children":
                  scenario.children.map((step) => ({
                    "id": `${step.id}`,
                    "name": `${step.name}`,
                    "type": "STEP",
                    "elapsed_time": `${getTime(step.elapsed_time)}`,
                    "struct_status": `${step.struct_status}`,
                    "type_value": `${step.type_value}`,
                    "result": `${step.result && step.result.result}`,
                    "expected_result": `${step.result && step.result.expected_result || '-'}`,
                    "received_result": `${step.result && step.result.received_result || '-'}`,
                    "skip": step.skip,
                    "step_info":
                      step.step_info.map((inf) => ({
                        "description": `${inf.description}`,
                        "result": `${inf.result}`,
                        "user_confirm": inf.user_confirm,
                        "name": `${inf.name}`,
                        "valueIn":
                          inf.params?.map(i => i)
                      })),
                    "params":
                      mergeParams(step.result?.params || step.params, step.step_info[0].params).map((param) => ({
                        ...param,
                        "param_status": `${step.struct_status}`,
                      })),
                    "result":
                      step.result?.params?.map((param) => ({
                        "name": `${param.name}`,
                        "type": `${param.type}`,
                        "value_in": `${param.value_in}`,
                        "value_out": `${param.value_out}`,
                        "received": param.received
                      }))
                  }))
              }))
          }))
      }));
      setInfo(formatInfo);
      setPending(false)
    }
  }, [reportInfo, id]);

  function mapDatasheetItems(dataSheetItems) {

    const mapDatasheetFields = Object.keys(dataSheetItems)?.map((item) => {
      return {
        field: item
        , value: dataSheetItems[item]
      };
    });

    return mapDatasheetFields?.map((item, index) => {
      return (
        <DatasheetItem key={item.toString() + '-' + index}>
          <DatasheetHeader><p style={{ marginLeft: 8 }}>{item['field']}</p></DatasheetHeader>
          <DatasheetContent><p>{item['value']}</p></DatasheetContent>
        </DatasheetItem>
      );
    })
  }

  const valueData = useMemo(() => {
    let scriptsArr = [];
    let featArr = [];
    let scenarioArr = [];
    let stepArr = [];

    const findSkipArr = info.filter((i) => !('skip' in i && i.skip==true)) 

    if (findSkipArr && findSkipArr.length > 0) {

      function reducerInfo(prev, current) {

        prev.success = (prev.success + (current.struct_status === 'PASSOU' ? 1 : 0))
        prev.fail = (prev.fail + (current.struct_status === 'FALHA' ? 1 : 0))
        prev.not_conclusive = (prev.not_conclusive + (current.struct_status === 'NÃO CONCLUÍDO' ? 1 : 0))
        prev.not_executed = (prev.not_executed + (current.struct_status === 'NÃO EXECUTADO' ? 1 : 0))
        prev.not_selected = (prev.not_selected + (current.struct_status === 'NÃO SELECIONADO' ? 1 : 0))

        return prev;
      }

      function InitData(length) {
          this.success = 0,
          this.not_executed = 0,
          this.fail = 0,
          this.not_conclusive = 0,
          this.not_selected = 0,
          this._length = length,
          this.percentage = (key) => {
            return (this[key] / this._length * 100)
          }
      };

      function mapChildren(array) {
        return array?.map((item) => {
          return item.children
        }).flat()
      };

      const scriptData = findSkipArr.reduce(reducerInfo, new InitData(Number((findSkipArr.length))));
      //scripts
      const featStructure = mapChildren(findSkipArr);
      const featData = featStructure.reduce(reducerInfo, new InitData(Number((featStructure.length))));
      //features
      const scenarioStructure = mapChildren(featStructure)
      const scenarioData = scenarioStructure.reduce(reducerInfo, new InitData(Number((scenarioStructure.length))))
      //scenarios
      const StepStructure = mapChildren(scenarioStructure)
      const stepData = StepStructure.reduce(reducerInfo, new InitData(Number((StepStructure.length))));
      //steps
      setScriptLength(scriptData._length);
      setFeatureLength(featData._length);
      setScenarioLength(scenarioData._length);
      setStepLength(stepData._length);

      scriptsArr = [
        { title: intl.get('report.success'), value: scriptData.percentage('success'), color: "#52de76" },
        { title: intl.get('report.fail'), value: scriptData.percentage('fail'), color: "#fc4b37" },
        { title: intl.get('report.not_conclusive'), value: scriptData.percentage('not_conclusive'), color: "#fdc97a" },
        { title: intl.get('report.not_executed'), value: scriptData.percentage('not_executed'), color: "#979797" },
        { title: intl.get('report.not_selected'), value: scriptData.percentage('not_selected'), color: "#e1e1e1" },
      ];

      featArr = [
        { title: intl.get('report.success'), value: featData.percentage('success'), color: "#52de76" },
        { title: intl.get('report.fail'), value: featData.percentage('fail'), color: "#fc4b37" },
        { title: intl.get('report.not_conclusive'), value: featData.percentage('not_conclusive'), color: "#fdc97a" },
        { title: intl.get('report.not_executed'), value: featData.percentage('not_executed'), color: "#979797" }, ,
        { title: intl.get('report.not_selected'), value: featData.percentage('not_selected'), color: "#e1e1e1" },
      ];

      scenarioArr = [
        { title: intl.get('report.success'), value: scenarioData.percentage('success'), color: "#52de76" },
        { title: intl.get('report.fail'), value: scenarioData.percentage('fail'), color: "#fc4b37" },
        { title: intl.get('report.not_conclusive'), value: scenarioData.percentage('not_conclusive'), color: "#fdc97a" },
        { title: intl.get('report.not_executed'), value: scenarioData.percentage('not_executed'), color: "#979797" },
        { title: intl.get('report.not_selected'), value: scenarioData.percentage('not_selected'), color: "#e1e1e1" },
      ];

      stepArr = [
        { title: intl.get('report.success'), value: stepData.percentage('success'), color: "#52de76" },
        { title: intl.get('report.fail'), value: stepData.percentage('fail'), color: "#fc4b37" },
        { title: intl.get('report.not_conclusive'), value: stepData.percentage('not_conclusive'), color: "#fdc97a" },
        { title: intl.get('report.not_executed'), value: stepData.percentage('not_executed'), color: "#979797" },
        { title: intl.get('report.not_selected'), value: stepData.percentage('not_selected'), color: "#e1e1e1" },
      ];
    }

    return ({
      scriptsArr, featArr, scenarioArr, stepArr
    });
  }, [info]);

  const handleDatasheetChangeVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleIconSize = () => {
    return isEdging ? 18 : 24
  };

  const CustomLoader = () => (
    <LoadingScreen>
      <Spinner />
      <span children={intl.get('loader.reports')} />
    </LoadingScreen>
  );

  return (
    <Fragment>
      <PDFTitle children={title} />
      {
        reportFields &&
        <Container>
          <DatasheetHeaderContainer>
            <Title>Datasheet</Title>

            {!isVisible
              ?
              <IconView children={<IoIosArrowDown onClick={handleDatasheetChangeVisibility} size={handleIconSize()} style={{ cursor: "pointer" }} />} />
              :
              <IconView children={<IoIosArrowUp onClick={handleDatasheetChangeVisibility} size={handleIconSize()} style={{ cursor: "pointer" }} />} />
            }
          </DatasheetHeaderContainer>
          <DatasheetWrapper ref={parent}>
            {isVisible && mapDatasheetItems(dataSheetItems)}
          </DatasheetWrapper>
        </Container>
      }

      {pending ? <CustomLoader /> :
        <Container column={column}>
          <Title style={column && {display: 'flex', justifyContent: 'center'}}>Report</Title>
          <ChartWrapper column={column}>
            <CardContainer column={column}>
              <DonutCards
                column={column}
                bgColor={'#60bebd'}
                title={'Scripts'}
                data={valueData.scriptsArr.filter((a) => a.value != '0.00' || undefined)}
                length={scriptLength < 10 ? `0${scriptLength}` : scriptLength} />
              <DonutCards
               column={column}
                bgColor={'#9877da'}
                title={'Feature'}
                data={valueData.featArr.filter((a) => a.value != '0.00' || undefined)}
                length={featureLength < 10 ? `0${featureLength}` : featureLength} />
              <DonutCards
                column={column}
                bgColor={'#ff8d41'}
                title={'Scenarios'}
                data={valueData.scenarioArr.filter((a) => a.value != '0.00' || undefined)}
                length={scenarioLength < 10 ? `0${scenarioLength}` : scenarioLength} />
              <DonutCards
                column={column}
                bgColor={'#ea789f'}
                title={'Steps'}
                data={valueData.stepArr.filter((a) => a.value != '0.00' || undefined)}
                length={stepLength < 10 ? `0${stepLength}` : stepLength} />
            </CardContainer>
            <InfoContainer row={row}>
              <Indicator
                bgColor={'#52de76'}
                children={intl.get('report.success')} />
              <Indicator
                bgColor={'#fc4b37'}
                children={intl.get('report.fail')} />
              <Indicator
                bgColor={'#979797'}
                children={intl.get('report.not_executed')} />
              <Indicator
                bgColor={'#fdc97a'}
                children={intl.get('report.not_conclusive')} />
              <Indicator
                bgColor={'#e1e1e1'}
                children={intl.get('report.not_selected')} />
            </InfoContainer>
          </ChartWrapper>
          <ProjectInfo
            id={id}
            column={column}
            description={project.description}
            name={project.name}
            start_date={project.start_date ? moment(project.start_date).format('DD/MM/YYYY - HH:mm:ss') : '-'}
            end_date={project.end_date ? moment(project.end_date).format('DD/MM/YYYY - HH:mm:ss') : '-'}
            repository_name={project.repository_name}
            repository_type={project.repository_type}
            override_confirm={project.override_confirm.toString()}
            status={generalStatus}
            time={project.elapsed_time ? project.elapsed_time : '-'}
          />
          <div className='page-break'>
            <ReportTree
              data={info}
              collapsible={collapsible}
              openBugRegister={openBugRegister}
              closeBugRegisterModal={closeBugRegisterModal}
              jiraKey={jiraKey}
            />
          </div>
          <ModalBugRegister
            isOpen={openBugRegisterModal}
            onRequestClose={closeBugRegisterModal}
            bugIndex={bugIndex}
            bugStructId={bugStructId}
            addToBugList={addToBugList}
            openModal={isCleaningModal}
          />
        </Container>
      }
    </Fragment>
  )
}