import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  ContainerTitle,
  FolderContainer,
  StyledFolder,
  Title,
  Subtitle,
  Cursive,
  Row,
  JiraReport,
  TableContainer,
  HeaderContainer,
  Wrapper,
  DownArrow,
  UpArrow,
  IconContainer,
  JiraIcon,
  JiraLink
} from "./styles";
import CardStatus from "../../Status/Report/Tree/CardStatus";
import ResultStep from "../../Tables/Manual/ResultStep";
import DynamicResult from "../../Tables/Manual/DynamicResult";
import NoParams from "../../Tables/Manual/NoParams";
import autoAnimate from "@formkit/auto-animate";
import intl from 'react-intl-universal';
import UserConfirm from "../../Tables/Manual/UserConfirm";
import { api } from "../../../api";

const Folder = ({
  id,
  index,
  type,
  step_info,
  params,
  name,
  elapsed_time,
  start_date,
  expected_result,
  received_result,
  end_date,
  struct_status,
  openBugRegister,
  bugUrl,
  bugId,
  skip,
  collapsible,
  children,
  jiraKey
}) => {
  const colorStyle =
    type === "PROJECT" && "#65A1C2" ||
    type === "SCRIPT" && "#82d0cf" ||
    type === "FEATURE" && "#ac91e0" ||
    type === "SCENARIO" && "#fea366" ||
    type === "STEP" && "#ed92b1";

  const [isOpen, setIsOpen] = useState(true);

  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const handleToggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };


  function stepsTable() {
    return (
      <TableContainer>
        <ResultStep
          caption={intl.get('report.results')}
          titleA={intl.get("params.result_expected")}
          titleB={intl.get("params.result_received")}
          childrenA={expected_result}
          childrenB={received_result}
        />
        {params && <DynamicResult params={params} step_info={step_info} />}
        <UserConfirm
          caption={'User Confirm'}
          titleA={intl.get("params.question")}
          titleB={'User Input'}
          data={step_info}
          status={struct_status}
        />
        {!params && <NoParams />}
      </TableContainer>
    );
  };

  const validateJiraToken = useCallback(() => {
    if (jiraKey && !skip) {
      if (struct_status === 'FALHA' && type === 'SCENARIO') {
        if (!bugUrl) {
          return <JiraIcon
            onClick={() => openBugRegister(index, id)}
            size={23}
            style={{ cursor: 'pointer', color: '#0051c6' }} />;
        } else {
          return (
            <JiraLink a href={bugUrl} target="_blank">
              {bugId}
            </JiraLink>);
        }
      }
    }
    return <JiraIcon hidden />
  }, [id, jiraKey]);

  return (
    <StyledFolder ref={parent} skip={skip}>
      <FolderContainer>
        <Wrapper bgColor={colorStyle}>
          <IconContainer bgColor={colorStyle}>
            {collapsible ? !isOpen
              ? <DownArrow color={colorStyle} onClick={handleToggle} size={23} hidden={type === "STEP"} />
              : <UpArrow color={colorStyle} onClick={handleToggle} size={23} hidden={type === "STEP"} />
              : null}
          </IconContainer>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 5 }}>
            <HeaderContainer>
              <ContainerTitle>
                <Cursive>
                  <Row>
                    <Title color={colorStyle}>
                      {type}
                    </Title>
                    <Subtitle>
                      {name}
                    </Subtitle>
                  </Row>
                  {type === "SCRIPT" &&
                    <>
                      <Row>
                        <Title children={intl.get("report.start")} />
                        <Subtitle>
                          {start_date ? start_date : '-'}
                        </Subtitle>
                      </Row>
                      <Row>
                        <Title children={intl.get("report.end")} />
                        <Subtitle>
                          {end_date ? end_date : '-'}
                        </Subtitle>
                      </Row>
                    </>
                  }
                </Cursive>
              </ContainerTitle>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}>
                <Row style={{ marginTop: 7 }}>
                  <Title children={intl.get("report.elapsed_time")} />
                  <Subtitle children={elapsed_time && elapsed_time} />
                </Row>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <JiraReport children={validateJiraToken()} />
                </div>
                <CardStatus status={struct_status} />
              </div>
            </HeaderContainer>
            {type === 'STEP' && stepsTable()}
          </div>
        </Wrapper>
      </FolderContainer>
      {isOpen && children}
    </StyledFolder>
  );
};

export default function ReportTree({ data, collapsible, jiraKey, openBugRegister }) {
  if (data && data.length > 0) {
    return data.map((item, index) => {
      return (
        <Folder
          key={index}
          id={item.id || 0}
          type={item.type}
          result={item.result}
          expected_result={item.expected_result}
          received_result={item.received_result}
          step_info={item.step_info}
          params={item.params}
          name={item.name}
          elapsed_time={item.elapsed_time}
          start_date={item.start_date}
          end_date={item.end_date}
          struct_status={item.struct_status}
          openBugRegister={openBugRegister}
          bugUrl={item.bug_url}
          bugId={item.bug_id}
          skip={item.skip}
          collapsible={collapsible}
          jiraKey={jiraKey}
        >
          <ReportTree data={item.children} openBugRegister={openBugRegister} collapsible={collapsible} jiraKey={jiraKey} />
        </Folder>
      );
    })
  };
}
