import React, { useMemo, useState } from 'react';
import Modal from 'react-modal';
import {
  Container,
  HeaderModal,
  ModalContent,
  Title,
  Wrapper,
  FieldsContainer
} from './styles';
import TextInput from '../../Input/TextInput';
import ButtonActions from '../../Button/ButtonActions';
import { IoCloseSharp } from "react-icons/io5";
import { api } from '../../../api';
import intl from "react-intl-universal"
import { useEffect } from 'react';
import Loader from '../../Loader';
import Success from '../../Modal/Success';
import ErrorLog from '../ModalSync/ErrorLog';
import SelectInput from '../../Input/SelectInput';
import { useAuth } from './../../../hooks/useAuth';

export default function ModalUserRegister({ isOpen, onRequestClose, onClick, modalType, userId }) {

  const [isShowLoader, setIsShowLoader] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fieldsData, setFieldsData] = useState([]);
  const { name, tokenJira, email, company, charge, type, status, setName, setEmail, setCompany, setCharge, setType, setStatus, setTokenJira } = useAuth();

  const closeSuccess = (e) => {
    e.preventDefault();
    setIsSuccess(false);
    onRequestClose(e);
  };

  const closeError = (e) => {
    e.preventDefault();
    setIsError(false);
  };

  const getFieldsItems = async () => {
    try {
      const response = await api.get('/user/search/fields');
      setFieldsData(response.data);
    } catch (error) {
      console.log(error)
    }
  };

  const cleanUserModal = () => {
    setName("");
    setEmail("");
    setCompany("");
    setCharge("");
    setType("");
    setTokenJira("");
    setStatus(true);
  };

  useEffect(() => {
    getFieldsItems();
  }, []);

  useMemo(() => {
    if (modalType === "add") {
      cleanUserModal();
    }
  }, [isOpen, userId]);

  const handler = async () => {
    if (name.trim() === "") {
      setErrorMessage("O campo Nome é obrigatório!");
      setIsError(true);
    } else if (email === "") {
      setErrorMessage("O campo Email é obrigatório!");
      setIsError(true);
    } else if (company === "") {
      setErrorMessage("O campo Empresa é obrigatório!");
      setIsError(true);
    } else if (charge === "") {
      setErrorMessage("O campo Cargo é obrigatório!");
      setIsError(true);
    } else if (type === "") {
      setErrorMessage("O campo Tipo user é obrigatório!");
      setIsError(true);
    } else if (status === "") {
      setErrorMessage("O campo Status é obrigatório!");
      setIsError(true);
    }
    else {
      setIsShowLoader(true);
      const body = {
        name: name,
        email: email,
        active: status,
        id_company: company,
        id_job: charge,
        id_type: type,
        token_jira: tokenJira
      };

      let url = "";

      if (modalType === "add") {
        url = "/user";
        await api.post(url, body)
          .then(() => {
            setIsShowLoader(false);
            setIsSuccess(true);
            cleanUserModal();
          })
          .catch(error => {
            setIsShowLoader(false);
            setErrorMessage(error);
            setIsError(true);
          });
      }

      if (modalType === "upd") {
        url = `/user/${userId}`;
        await api.put(url, body)
          .then(() => {
            setIsShowLoader(false);
            setIsSuccess(true);
            cleanUserModal();
          })
          .catch(error => {
            setIsShowLoader(false);
            setErrorMessage(error);
            setIsError(true);
          });
      }
    }
  };

  const handleUserRegister = async (e) => {
    e.preventDefault();
    try {
      handler()
    }
    catch (error) {
      setIsError(true);
      setIsShowLoader(false);
    }
  };

  const onCancel = (e) => {
    e.preventDefault();
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClick={onClick}
      closeTimeoutMS={115}
      onRequestClose={onRequestClose}
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content">
      <Container>
        <HeaderModal>
          <Title>{intl.get(modalType === "add" ? "users.add-user" : "users.update-user")}</Title>
          <IoCloseSharp
            style={{ cursor: 'pointer', marginRight: 7 }}
            size={18}
            onClick={onCancel} />
        </HeaderModal>
        <ModalContent>

          <FieldsContainer>
            <TextInput value={name} onChange={(e) => setName(e.target.value)}>{intl.get("users.name")}</TextInput>
            <TextInput value={email} onChange={(e) => setEmail(e.target.value)}>Email</TextInput>
          </FieldsContainer>
          <FieldsContainer>
            <SelectInput
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              type={"company"}
              data={fieldsData.company}
            >
              {intl.get("users.company")}
            </SelectInput>
            <SelectInput
              value={charge}
              onChange={(e) => setCharge(e.target.value)}
              type={"charge"}
              data={fieldsData.job}
            >
              {intl.get("users.charge")}
            </SelectInput>
          </FieldsContainer>
          <FieldsContainer>
            <SelectInput
              value={type}
              onChange={(e) => setType(e.target.value)}
              type={"userType"}
              data={fieldsData.type}
            >
              {intl.get("users.user-type")}
            </SelectInput>
            <SelectInput
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              type={"status"}
            >
              Status
            </SelectInput>
          </FieldsContainer>
          <FieldsContainer>
            <TextInput
              value={tokenJira}
              onChange={(e) => setTokenJira(e.target.value)}
            >Token Jira</TextInput>
          </FieldsContainer>

          <Wrapper>
            <ButtonActions
              bgColor={'#104068'}
              text={intl.get("repository.save")}
              onClick={handleUserRegister}
            />
            <ButtonActions
              bgColor={'#ed6663'}
              text={intl.get("repository.cancel")}
              onClick={onCancel} />
          </Wrapper>
        </ModalContent>
      </Container>
      <Success
        isOpen={isSuccess}
        message={modalType === "add" ? "Usuário cadastrado com sucesso !" : "Usuário atualizado com sucesso !"}
        title={modalType === "add" ? "Cadastro de usuário" : "Atualizar cadastro de usuário"}
        onRequestClose={closeSuccess}
      />
      <ErrorLog
        isOpen={isError}
        message={errorMessage}
        title1={"Erro"}
        onRequestClose={closeError}
      />
      <Loader isShow={isShowLoader} />
    </Modal>
  )
}
