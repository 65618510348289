import { api } from './../../../api';

export async function createExecution(id, execution_id, requestBody) {
    return api.post(`projects/create/exec/${id}/${execution_id || 0}`, requestBody)
        .then(res => {
            return {
                success: true
                , json: res.data.json
                , id: res.data.id
            }
        })
        .catch(res => {
            return {
                success: false
                , error: res.data
            }
        })
}

export function changeStartStatusBySelection(structItem) {
    let newStatus = structItem.checked === true ? 'AGUARDANDO' : 'NÃO SELECIONADO';
    structItem.executionStatus = newStatus
}

export function copyProperties(from, to){
    to['db_id'] = from['db_id']
    to['params'] = from['params']
}
