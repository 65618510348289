import React from 'react';
import { Container, ContainerFile, RecentFiles, Title, Wrapper } from './styles'
import TableHome from '../../components/Tables/TableHome'
import intl from "react-intl-universal";
import FileCards from '../../components/Cards/FileCards';

export default function Home() {

  return (
    <Container>
      <Wrapper>
        <Title>{intl.get('home.title')}</Title>
        <RecentFiles>
          <ContainerFile>
            <FileCards type={'projects'}/>
          </ContainerFile>
          <ContainerFile>
            <FileCards type={'reports'}/>
          </ContainerFile>
        </RecentFiles>
        <TableHome />
      </Wrapper>
    </Container>
  )
}