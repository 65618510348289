import styled from 'styled-components';

export const Container = styled.div`


  margin: 0;
  padding: 0;
  background-color: #fafafa;
`

export const Wrapper = styled.div`
 
 height: 70vh;
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
`

export const Title = styled.h1`


 color: #222222;
 margin-bottom: 1%;
 font-size: 5.5rem;
`


export const SubTitle = styled.h5`


 color: #444444;
 margin: 0;
 padding:0;
 font-size: 1rem;
`