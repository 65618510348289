import React from 'react'
import { STable, STBody, STBodyTR, STD, STH, STHead, STHeadTR, Container } from './styles'

export default function ResultStep({ titleA, titleB, childrenA, childrenB, caption }) {
  return (
    <Container>
      <STable>
        <caption>{caption}</caption>
        <STHead>
          <STHeadTR>
            <STH>{titleA}</STH>
            <STH>{titleB}</STH>
          </STHeadTR>
        </STHead>
        <STBody>
          <STBodyTR>
            <STD>{childrenA}</STD>
            <STD>{childrenB}</STD>
          </STBodyTR>
        </STBody>
      </STable>
    </Container>
  )
}