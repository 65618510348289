import React from 'react'
import * as FaIcons from "react-icons/fa"
import * as AiIcons from "react-icons/ai"
import * as VscIcons from "react-icons/vsc";
import * as BsIcons from "react-icons/bs";
import * as BiIcons from "react-icons/bi"
import intl from "react-intl-universal";
import { getUserType } from '../../api';


const locales = {
    "pt-BR": require("../../locales/pt-BR.json"),
    "en-US": require("../../locales/en-US.json"),
};

if (!localStorage.getItem("lang")) {
    localStorage.setItem('lang', 'pt-BR');
}

intl.init({
    currentLocale: localStorage.getItem("lang")
    , locales
    , fallbackLocale: 'pt-BR'
})

let filteredSidebarData = [
    {
        id: 1,
        title: "",
        path: "#",
        icon: <FaIcons.FaBars />,
        cName: "nav-text-exit",
    },
    {
        id: 2,
        title: intl.get("home.home"),
        path: "/",
        icon: <AiIcons.AiOutlineHome />,
        cName: "nav-text",
    },
    {
        id: 3,
        title: intl.get("home.projects"),
        path: "/projects",
        icon: <AiIcons.AiOutlineFolder />,
        cName: "nav-text",
    },
    {
        id: 4,
        title: intl.get("home.repositories"),
        path: "/repository",
        icon: <AiIcons.AiOutlineFileText />,
        cName: "nav-text",
    },
    {
        id: 5,
        title: intl.get("home.reports"),
        path: "/reports",
        icon: <VscIcons.VscGraph />,
        cName: "nav-text",
    },
    {
        id: 6,
        title: intl.get("home.datasheet"),
        path: "/reports/datasheet",
        icon: <BsIcons.BsFileEarmarkSpreadsheet />,
        cName: "nav-text",
    },
    {
        id: 7,
        title: intl.get("home.users"),
        path: "/user-register",
        icon: <BiIcons.BiUserCircle />,
        cName: "nav-text",
    },
]

function applyUserFilter(listData) {
    if (getUserType().toLowerCase() !== 'admin') {
        return listData.slice(0, -1)
    }
    return listData;
}


export const SidebarData = applyUserFilter(filteredSidebarData);
