import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  width: 100%;
  
  flex-direction: column;

  border: 2px solid #BFBFBF;

  border-radius: 10px;

  overflow-y: auto;

  span {
    color: #000;
    font-weight: 600;
  }

  @media screen and (max-width: 2560px) {
    height: 590px;
  }

  @media screen and (max-width: 1600px) {
    height: 315px;
  }
`;

export const CardContainer = styled.div`

 background-color: ${props => props.bgColor};
 display: flex; 
 justify-content: flex-end;
 border: 1px solid;
 border-color: ${props => props.borderColor};
 border-radius: 5px;
`

export const TitleContainer = styled.div`
  min-height: 30px;
  display: flex;
  flex-direction: row;
  padding: 8px 0;
  gap: 5px;
`;

export const Content = styled.div`
display: flex;
flex-direction: column;
width: 98%;
`;

export const TimeExecContainer = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  padding-left: 15px;
  align-items: center;
`;

export const LogResumeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  background-color: #f8f8f8;
`

export const ResumeItems = styled.div`
  display: flex;
  gap: 10px;
`

export const ResumeItemsContainer = styled.div`
  margin-top: 10px;
  font-size: 13px;
`
export const Title = styled.p`
  font-size: 12px;
  width: 500px;
  strong {
    font-weight: 700;
  }
  span { 
  word-wrap: break-word;
  width: 10px;
  font-weight: 500;
  };

  @media screen and (max-width: 1600px) {
    width: 330px;
  }
`

export const Description = styled.p`
  font-size: 12px;
  width: 500px;
  strong {
    font-weight: 700;
  }
  span { 
  word-wrap: break-word;
  width: 10px;
  font-weight: 500;
  }
  @media screen and (max-width: 1600px) {
    width: 330px;
  }
`