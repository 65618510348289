import React, { useCallback } from 'react'
import { Container, Title } from './styles';
import intl from 'react-intl-universal';
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { IoCloseCircleOutline } from "react-icons/io5";
import { BsQuestionCircle } from "react-icons/bs";
import { useMediaQuery } from 'react-haiku';

export default function CardStatus({ status }) {

	const isEdging = useMediaQuery('(max-width: 1600px)');

	const translateStatus = useCallback(() => {
		switch (status) {
			case 'PASSOU':
				return intl.get('report.success');
			case 'FALHA':
				return intl.get('report.fail');
			case 'NÃO EXECUTADO':
				return intl.get('report.not_executed');
			case 'NÃO CONCLUÍDO':
				return intl.get('report.not_conclusive');
			case 'NÃO SELECIONADO':
				return intl.get('report.not_selected');
			default:
				return '';
		}
	}, [status]);

	const getIconByStatus = useCallback(() => {
		switch (status) {
			case 'PASSOU':
				return <IoCheckmarkCircleOutline size={isEdging ? 23 : 28} />
			case 'FALHA':
				return <IoCloseCircleOutline size={isEdging ? 23 : 28} />
			case 'NÃO CONCLUÍDO':
				return <BsQuestionCircle size={isEdging ? 20 : 24} />
			case 'NÃO EXECUTADO':
			case 'NÃO SELECIONADO':
				return <HiOutlineExclamationCircle size={isEdging ? 23 : 28} />;
			default:
				return '';
		}
	}, [status]);

	return (
		<Container
			status={status}>
			{getIconByStatus()}
			<Title>{translateStatus()}</Title>
		</Container>
	)
}
