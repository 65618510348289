import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AccessButton, Container, Form, InputContainer, InputTitle, LoginSide, ShowPassword, StyledInput, StyledInputContainer, WelcomeText, Wrapper } from './styles';
import showPassword from '../../assets/showPasswordIconOn.svg';
import hiddenPassword from '../../assets/showPasswordIconOff.svg';
import { useAuth } from './../../hooks/useAuth';

function Login() {

  const [passwordVisible, setPasswordVisible] = useState(false);
  const { handleLogin } = useAuth()
  const { handleSubmit, register } = useForm();

  return (
    <Wrapper>
      <Container id='container'>
        <LoginSide id='loginSide'>
          <Form
            id='form'
            onSubmit={handleSubmit(handleLogin)}
          >
            <WelcomeText id='welcomeText'>
              BEM VINDO!
            </WelcomeText>
            <div id='loginActionsContainer'>
              <InputContainer style={{ marginBottom: "2vh" }}>
                <InputTitle>
                  E-mail
                </InputTitle>
                <StyledInputContainer>
                  <StyledInput
                    type="text"
                    style={{ paddingRight: '9%' }}
                    {...register("username")}
                  />
                </StyledInputContainer>
              </InputContainer>

              <InputContainer >
                <InputTitle>
                  Nova senha
                </InputTitle>
                <StyledInputContainer>
                  <StyledInput
                    type={passwordVisible ? "text" : "password"}
                    style={{ paddingRight: '9%' }}
                    {...register("password")}
                  />
                  <ShowPassword
                    src={passwordVisible ? showPassword : hiddenPassword}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  />
                </StyledInputContainer>
              </InputContainer>

              <div
                id="recoveryPasswordButton"
                style={{
                  display: 'flex',
                  width: '100%',
                  marginTop: '2vh',
                  justifyContent: 'flex-end'
                }}
              >
                <span
                  id="recoveryPasswordButtonText"
                  onClick={() => window.location = "/recovery_password"}
                  style={{
                    fontSize: '1.3vh',
                    color: '#408BB4',
                    cursor: 'pointer'
                  }}
                >
                  Recuperar senha
                </span>
              </div>
            </div>

            <AccessButton
              id="accessButton"
              type="submit"
              value="Acessar"
            />
          </Form>
        </LoginSide>
        <Fragment />
      </Container>
    </Wrapper>
  );
}

export default Login;