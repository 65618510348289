import styled from 'styled-components';
import { IoIosArrowDown } from "react-icons/io";

export const StyledTree = styled.div`
  line-height: 1.5;
  padding: 10px 10px 10px 0;
  border-radius: 7px;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (max-width: 2560px) {
    ${props => props.ending ? 'height: 495px' : 'height: 550px'}
  }

  @media screen and (max-width: 1600px) {
    ${props => props.ending ? 'height: 250px' : 'height: 265px'}
  }
`;

export const ContainerTitle = styled.div`

 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: space-around;
`

export const IconContainer = styled.div`


`

export const Title = styled.div`

 color: black;
 font-size: 13px;
 font-weight: 600;

 @media screen and (max-width: 1200px) {
 /* display:inline-block;
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
 max-width: 15ch; */
 }
 
`

export const Badge = styled.div`

 width: 90px;
 display: flex;
 justify-content: center;
 align-items: center;
 border-radius: 7px;
 color: white;
 margin: 0 5px;
 font-size: 11px;
 padding: 3px 0;
 cursor: pointer;
`

export const FolderContainer = styled.div`

  padding: 0 10px;
  display: flex;
  height: 35px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 1px 2.5px #0003;
  background-color: #fbfbfb;
  border-radius: 5px;
  width: 100%;
  

`
export const StyledFile = styled.div`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 1px 2.5px #0003;
  background-color: #fbfbfb;
  border-radius: 5px;
  z-index: 999;
  margin: 3px 0;
  span {
    margin-left: 5px;
  }
`;
export const StyledFolder = styled.div`
  padding-left: 20px;
  margin: 3px 0px;
  .folder--label {
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }
`;
export const Collapsible = styled.div`
  height: ${p => (p.isOpen ? "auto" : "0")};
  overflow: ${p => (p.isOpen ? "visible" : "hidden")};
`;
export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

export const Label = styled.label`
  position: absolute;
  width: 14px;
  height: 14px;
  background-color: transparent;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA5NiA5NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgOTYgOTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiMzMzMzMzM7fQ0KPC9zdHlsZT4NCjxnIGlkPSJYTUxJRF80XyI+DQoJPHBvbHlnb24gaWQ9IlhNTElEXzZfIiBjbGFzcz0ic3QwIiBwb2ludHM9IjM4LjUsNjEuMiAyNS4zLDQ4IDIwLjgsNTIuNSAzOC41LDcwLjIgNzYuNSwzMi4yIDcyLDI3LjcgCSIvPg0KPC9nPg0KPC9zdmc+DQo=');
  background-position: center center;
  visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  pointer-events:none;
  margin-left: 5px;
`

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-top: -8px;
  margin-left:5px;
  content: '';
  top: 50%;
  right: 0;
  border-radius: 4px;
  border: 1px solid black;
  background: #ffffff;
  transition: 0.2s;
`
export const Icon = styled(IoIosArrowDown)`

 color: white;
 font-size: 15px;
 display: flex;
 align-self: center;
`

export const Status = styled.span`
font-size: 14px;
color: ${(props) => props.fontColor};
margin-right: 20px;
font-weight: 500;
`
