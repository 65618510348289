import React from 'react';

import { 
  Container, 
  Title, 
  InputContainer, 
  Select 
} from './styles';
import intl from "react-intl-universal"

function SelectInput({ onChange, children, value, type, data }) {
  if (value === true) {
    return (
      <Container>
        <Title>{children}</Title>
        <InputContainer>
          <Select onChange={onChange}>
            <option value={true} selected>{intl.get("users.active")}</option>
            <option value={false}>{intl.get("users.inactive")}</option>
          </Select>
        </InputContainer>
      </Container>
    );
  }

  if (value === false) {
    return (
      <Container>
        <Title>{children}</Title>
        <InputContainer>
          <Select onChange={onChange}>
            <option value={true}>{intl.get("users.active")}</option>
            <option value={false} selected>{intl.get("users.inactive")}</option>
          </Select>
        </InputContainer>
      </Container>
    );
  }


  if (type === 'userType') {
    return (
      <Container>
        <Title>{children}</Title>
        <InputContainer>
          <Select onChange={onChange}>
            <option value={""} selected >{intl.get("users.select-a-type")}</option>
            {
              data ?
                data.map(item => {
                  return (
                    <option
                      selected={item.id === value ? true : false}
                      value={item.id}
                    >
                      {item.name}
                    </option>
                  );
                })
                : <></>
            }
          </Select>
        </InputContainer>
      </Container>
    );
  }

  if (type === 'charge') {
    return (
      <Container>
        <Title>{children}</Title>
        <InputContainer>
          <Select onChange={onChange}>
            <option value={""}>{intl.get("users.select-a-charge")}</option>
            {
              data ?
                data.map(item => {
                  return (
                    <option
                      selected={item.id === value ? true : false}
                      value={item.id}
                    >
                      {item.name}
                    </option>
                  );
                })
                : <></>
            }
          </Select>
        </InputContainer>
      </Container>
    );
  }

  if (type === 'company') {
    return (
      <Container>
        <Title>{children}</Title>
        <InputContainer>
          <Select onChange={onChange}>
            <option value={""}>{intl.get("users.select-a-company")}</option>
            {
              data ?
                data.map(item => {
                  return (
                    <option
                      selected={item.id === value ? true : false}
                      value={item.id}>
                      {item.name}
                    </option>
                  );
                })
                : <></>
            }
          </Select>
        </InputContainer>
      </Container>
    );
  }

  if (type === 'status') {
    return (
      <Container>
        <Title>{children}</Title>
        <InputContainer>
          <Select onChange={onChange}>
            <option value={""} selected >{intl.get("users.select-a-status")}</option>
            <option value={true}>{intl.get("users.active")}</option>
            <option value={false}>{intl.get("users.inactive")}</option>
          </Select>
        </InputContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Title>{children}</Title>
      <InputContainer>
        <Select onChange={onChange} value={value}>
          <option value="none" selected disabled hidden>{intl.get("users.select-a-status")}</option>
          <option value={true}>{intl.get("users.active")}</option>
          <option value={false}>{intl.get("users.inactive")}</option>
        </Select>
      </InputContainer>
    </Container>
  );
}

export default SelectInput;