import styled from 'styled-components';

export const ContainerFile = styled.div`
  display: flex;
  width: 36%;
  gap: 15px;
  flex-direction: column;
  box-shadow: 0 1px 2px #0003;
  background-color: #fff;
  margin-right: 10px;
  padding: 10px;
  border-radius: 3px;
  justify-content: center;

  @media screen and (max-width: 1400px){
    height: 115px;    
    width: 50%;
  }

  @media screen and (max-width: 1175px) {
    width: 100%;
  }
`

export const SubTitle = styled.h5`
  font-size: 17px;
  color: black;
  margin: 0;
  padding:0;
`

export const InputContainer = styled.input`
  width: 100%;
  height: 3.6vh;
  min-width: 6vw;
  padding: 10px;

  outline: none;
  border-color: transparent;

  box-shadow: 0px 1.4px #e2e2e2;
  @media screen and (max-width: 1600px) {
    height: 4.5vh;
  }
`

export const ContainerStatus = styled.div`
  display:flex;
  width: 100%;
  align-items: center;
  gap: 7px;
`

export const Status = styled.p`
  display: flex;
  font-size: 1.5vh;
  margin: .4vh 0;
  gap: 7px;

  @media screen and (min-width: 1600px) {
    font-size: 1.25vh;
  }
`

export const ButtonContainer = styled.div`
display: flex;
flex-direction: row;
`

export const Wrapper = styled.div`
display: flex;
flex-direction: row;
width: 100%;
align-items: center;
`