import styled, { keyframes } from 'styled-components';
import DataTable from 'react-data-table-component';
import { IoCloseSharp } from 'react-icons/io5';
import { BsFileText, BsPieChartFill } from 'react-icons/bs';
import { VscFilePdf } from 'react-icons/vsc';

export const Container = styled.div`
	margin-bottom: 50px;
	padding: 12px;
	background-color: white;

	.rdt_Table .rdt_TableRow:hover {
		cursor: pointer
	};

	.custom-datepicker {
		height: 40px;
		font-size: 16px;
		background-color: #fff;
    	border: 1px solid #ccc;
    	border-radius: 4px;
		padding: 10px;
		min-width: 200px;
	}

	.custom-datepicker:focus-visible {
		border-color: red !important;
	}
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
	margin: 16px;
	animation: ${rotate360} 1s linear infinite;
	transform: translateZ(0);
	border-top: 2px solid grey;
	border-right: 2px solid grey;
	border-bottom: 2px solid grey;
	border-left: 4px solid black;
	background: transparent;
	width: 80px;
	height: 80px;
	border-radius: 50%;
`;

export const Wrapper = styled.div`

 display: flex;
 flex-direction: row;
 justify-content: space-between;
  
`

export const ButtonContainer = styled.div`

  display: flex;
  align-items: center;
  font-size: 10px;
  position: relative;
  gap: 15px;
  margin-top: 4vh;

  @media (max-width: 2560px) {
    min-width: 350px;
  }

  @media (max-width: 1460px) {
    min-width: 220px;
  }

  @media (max-width: 1024px) {
    margin-left: 0;
    justify-content: center;
  }
`

export const ActionContainer = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 120px;
`

export const HeaderContainer = styled.div`

  display: flex;
  flex-direction: column;
  width: 100%;  
  position: relative;
  right: 20px;
`

export const Period = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
  margin-top: 10px;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const DateInput = styled.input`
  width: 100%;
  height: 3.8vh;
  min-width: 200px;
  padding: 10px;
  background-color: #fafafa;
  outline: none;
  border-color: transparent;
  font-size: 0.75vw;

  box-shadow: 0px 1.4px #e2e2e2;
  @media screen and (max-width: 1600px) {
    height: 5.5vh;
  }
`;


export const Input = styled.input`

  height: 35px;
  width: 100%;
  max-width: 220px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #408bb3;
  padding: 0 8px;
  line-height: normal;
  font-size: 12px;

`

export const DateContainer = styled.div`
display: flex;
flex-direction: column;
gap: 1vh;
padding-top: 1.5vh;
`

export const Title = styled.h2`
	font-size: 1.8vh;
	color: black;
	margin-bottom: 7px;
`

export const TitleContainer = styled.div`

 width: 100%;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 margin: 20px 0px;
 align-items: center;
`


export const InputContainer = styled.div`

 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 margin: 14px 0 8.5px;

 @media (max-width: 800px) {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 14px 0;
 }

`
export const CloseIcon = styled(IoCloseSharp)`

 color:#3b9ccf;
 font-size: 24px;
 margin-top: 3px;
 cursor: pointer;

 &:hover { 
 background-color: white;
 padding: 1px;
 transition: 0.4s ease-in-out; 
 border-radius: 5px;
 box-shadow: 0 1px 2.8px #0003;
}

        
`

export const ChartIcon = styled(BsPieChartFill)`

 color:#9fc4d9;
 font-size: 21px;
 cursor: pointer;

 &:hover { 
 background-color: white;
 padding: 1.5px;
 transition: 0.4s ease-in-out; 
 border-radius: 5px;
 box-shadow: 0 1px 2.8px #0003;
}

        
`

export const PDFIcon = styled(VscFilePdf)`

 color:#dfa24c;
 font-size: 22.5px;
 cursor: pointer;

 &:hover { 
 background-color: white;
 padding: 1.5px;
 transition: 0.4s ease-in-out; 
 border-radius: 5px;
 box-shadow: 0 1px 2.8px #0003;
}

`

export const DSIcon = styled(BsFileText)`

 color:#103f68;
 font-size: 22px;
 cursor: pointer;

 &:hover { 
 background-color: white;
 padding: 1.5px;
 transition: 0.4s ease-in-out; 
 border-radius: 5px;
 box-shadow: 0 1px 2.8px #0003;
}

        
`

export const GeneralStateLabel = styled.div`
	background-color: ${props => {
        switch (props.status) {
            case 'PASSOU':
                return '#16b444';
            case 'FALHA':
                return '#f30500';
            case 'NÃO EXECUTADO':
                return '#979797';
            case 'NÃO CONCLUÍDO':
                return '#dfa24c'
            default:
                return '#cecece';
        }
    }};
	color: #fff;
	width: 140px;
	height: 28px;
	border-radius: 20px;
	display: flex;
	justify-content: center;
    align-items: center;
 	font-size: 12px;
`;

export const GeneralStatePopover = styled.div`
	--height: 160px;

	height: var(--height);
	width: 200px;
	background-color: #fff;
	border-radius: 4px;
	padding: 0px 10px;
	border: 1px solid rgba(0,0,0,.2);

	ul {
		list-style-type: none;
	}

	li {
		height: calc(var(--height) / 4);
		display: flex;
		align-items: center;
		border-bottom: 2px solid rgba(0,0,0,.2);
	}

	li:last-child {
		border-bottom: none;
	}

	li span {
		width: 140px;
		text-align: right;
		margin-right: 10px;
	}
`;
