import styled from 'styled-components';

export const Container = styled.form`
    width: 800px;
    height: 300px;
    background-color: #fff;
    
 
    border-radius: 2px;
`

export const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    margin: 40px 20px 0;
    gap: 20px;

`

export const HeaderModal = styled.div`

 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: space-between;
 padding: 15px 20px;
 box-shadow: 1px 1px 1px #00000029;

`

export const Title = styled.p`


`

export const NextOrder = styled.div`

 display: flex;
 height: 150px;
 flex-direction: column;
 align-items: center;
 justify-content: center;

`

export const SubTitle = styled.p``

export const InfoContainer = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  line-height: normal;
  font-size: 14px;
  position: relative;
  padding: 5px 0 0 15px;


`